class Data{
    constructor(){
        this.unique_reach_array = [];
        this.frequency_distrubtions_dict = {};
        this.target_pop = 0;
        this.cpm = 0;
        this.cpm_dict = {};
        this.num_products_dict = {};
        this.visible_budget = 0;
        this.average_freq_dict = {};
    }
    load_data_from_api_response(api_response){
        var tempUniqueReach = [];
        var tempFreqDistDict = {};
        var tempCPMDict = {};
        var tempProductNumDict = {};
        let tempAcgFrequencyDict = {};
        for(var budgetPoint in api_response){
            var uniqueReach = api_response[budgetPoint]['unique_reach'];
            var std = api_response[budgetPoint]['std'];
            let avgFreq = api_response[budgetPoint]['avg_frequency'];
            tempAcgFrequencyDict[budgetPoint] = avgFreq;
            tempUniqueReach.push([budgetPoint, uniqueReach, std]);
            var frequencyDistruction = [];
            for(var freqLoc in api_response[budgetPoint]['frequency']){
                var freq = api_response[budgetPoint]['frequency'][freqLoc];
                var num_occ = api_response[budgetPoint]['num_occurences'][freqLoc];
                frequencyDistruction.push([freq, num_occ]);
            }
            if(frequencyDistruction.length == 0){
                frequencyDistruction.push([0,0])
            }
            tempFreqDistDict[budgetPoint] = frequencyDistruction;
            tempCPMDict[budgetPoint] = api_response[budgetPoint]['cpm'];
            tempProductNumDict[budgetPoint] = api_response[budgetPoint]['num_prods'];
        }
        this.average_freq_dict = tempAcgFrequencyDict;
        this.unique_reach_array = tempUniqueReach;
        this.frequency_distrubtions_dict = tempFreqDistDict;
        this.cpm_dict = tempCPMDict;
        this.num_products_dict = tempProductNumDict;
        this.visible_budget = Object.keys(api_response)[1];
    }
    get_num_products_dict(){
        return this.num_products_dict;
    }
    set_cpm(cpm){
        this.cpm = cpm;
    }
    get_cpm(){
        return this.cpm;
    }
    get_cpm_at_budget(budget){
        return this.cpm_dict[budget];
    }
    set_visible_budget(budget){
        this.visible_budget = budget;
    }
    get_visible_budget(){
        return this.visible_budget;
    }
    set_target_pop(targetPop){
        this.target_pop = targetPop;
    }
    get_target_pop(){
        return this.target_pop;
    }
    get_unique_reach_array(){
        return this.unique_reach_array;
    }
    get_frequency_distrubution_at_budget(budget){
        var frequency_distrubution = this.frequency_distrubtions_dict[budget];
        if(frequency_distrubution == null){
            return [[0,0]];
        }
        else{
            return frequency_distrubution;
        }
    }
    get_frequency_distrubution_at_budget_below_forty(budget){
        var frequency_distrubution = this.frequency_distrubtions_dict[budget];
        if(frequency_distrubution == null){
            return [[0,0]];
        }
        else{
            let tmp_freq_dist = [];
            for(let row of frequency_distrubution){
                if(row[0] < 41){
                    tmp_freq_dist.push(row);
                }
            }
            return tmp_freq_dist;
        }
    }
    get_budget_points(){
        if(Object.keys(this.frequency_distrubtions_dict).length > 0) {
            return Object.keys(this.frequency_distrubtions_dict);
        }
        else{
            return [];
        }
    }
    get_max_unique_reach(){
        var max = 0;
        for(var row of this.unique_reach_array) {
            if (row[1] > max) {
                max = row[1];
            }
        }
        return max;
    }
    get_max_ur_percentage(){
        var max = this.get_max_unique_reach();
        max = max / this.get_target_pop();
        return max;
    }
    get_max_num_occurences(){
        var max = 0;
        for(var budget in this.frequency_distrubtions_dict){
            for(var row of this.frequency_distrubtions_dict[budget]) {
                if (row[1] > max) {
                    max = row[1];
                }
            }
        }
        return max;
    }
    getMaxBudget(){
        let budg_points = this.get_budget_points();
        return budg_points[budg_points.length - 2];
    }
    get_avg_frequency_at_budget(budget){
        let avgFreq = this.average_freq_dict[budget];
        // var totalFrequency = 0;
        // var ontargetReach = this.get_on_target_reach(budget);
        // for(var row of this.frequency_distrubtions_dict[budget]){
        //     totalFrequency += row[1] * row[0];
        // }
        // var avgFreq = (totalFrequency / ontargetReach).toFixed(2);
        return avgFreq;
    }
    get_on_target_reach(budget){
        for(var row of this.unique_reach_array){
            if(row[0] == budget){
                return row[1];
            }
        }
        return 0;
    }
    get_grps(budget){
        var avgFreq = this.get_avg_frequency_at_budget(budget);
        var reach = this.get_on_target_reach(budget);
        var targetPop = this.get_target_pop();
        reach = reach/targetPop * 100;
        if(reach == 0){
            return 0;
        }
        return (reach * avgFreq).toFixed(2);
    }
    get_budget_grps(){
        let budg_grp_list = [];
        let budget_points = this.get_budget_points();
        for(let budg_point of budget_points){
            let grp = this.get_grps(budg_point);
            budg_grp_list.push([budg_point,grp]);
        }
        return budg_grp_list;
    }
    get_budget_reach(){
        let budg_reach_list = [];
        let budget_points = this.get_budget_points();
        for(let budg_point of budget_points){
            let reach = this.get_on_target_reach(budg_point);
            if(budg_point == 0){
                budg_reach_list.push([0,0]);
                continue;
            }
            budg_reach_list.push([budg_point,reach]);
        }
        return budg_reach_list;
    }
    get_impressions(budget){
        return Math.floor((budget / this.get_cpm_at_budget(budget)) * 1000);
    }
}

export default Data