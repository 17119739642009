import jsPDF from 'jspdf'
import 'jspdf-autotable'
import pattisonLogo from '../assets/PattisonLogo.png'
import * as export_helper from "./exportHelper";
import futuraL from '../fonts/futura-t-light.ttf';
import futuraB from '../fonts/futura-t-bold.ttf';
class PDFBuilder {
    constructor() {
        this.pdf_elements = {
            'logo': pattisonLogo
        };
        this.pdf = new jsPDF("w", "mm", "a4");
        this.pdf_width = this.pdf.internal.pageSize.getWidth();
        this.pdf_height = this.pdf.internal.pageSize.getHeight();
        this.pdf_x_break = this.pdf_width / 20;
        this.pdf_y_break = this.pdf_height / 20;
        this.pdf_content_height = this.pdf_height - 24 - ((3 * this.pdf_x_break) / 4);
        this.pdf_cur_y = 0;
        this.pdf_name = 'PattisonSweetSpot.pdf';
        this.color_primary = '#00285e';
        this.color_secondary = '#0079C1';
        this.total_elements = 4;
        this.num_elements_on_page = 0;
    }
    get_pdf_name() {
        return this.pdf_name;
    }
    set_pdf_name(pdf_name) {
        if (pdf_name != '' && pdf_name != null) {
            this.pdf_name = pdf_name;
        }
    }
    add_pdf_element(elementName, element) {
        this.pdf_elements[elementName] = element;
    }
    build(){
        this.pdf.addFont(futuraL, "Futura", "normal");
        this.pdf.addFont(futuraB, "Futura", "bold");
        this.add_main_logo();
        this.add_footer();
        this.add_unique_reach_curve();
        this.add_frequency_distribution();
        this.add_highlights_table();
        this.add_configs();
    }
    add_main_logo(){
        let imgWidth = this.pdf_x_break * 3;
        let imgHeight = imgWidth / 4;
        let imgxLoc = this.pdf_x_break * 8.5;
        this.updateCurY(10);
        this.pdf.addImage(pattisonLogo,imgxLoc,this.pdf_cur_y,imgWidth,imgHeight);
        this.updateCurY(imgHeight);
    }
    add_unique_reach_curve(){
        this.num_elements_on_page += 1;
        let porportion = this.pdf_elements['unique_reach_curve']['height']/this.pdf_elements['unique_reach_curve']['width'];
        let img_max_height = (this.pdf.getPageWidth() - (this.pdf_x_break * 2)) * porportion;
        let page_max_height = this.pdf_height - ((this.pdf_x_break * 3)/4) - 34 - (this.pdf_y_break * 2);
        let imgHeight = 0;
        let marg_top = 0;
        let marg_bot = 0;
        if(img_max_height > page_max_height){
            imgHeight = page_max_height;
            marg_top = this.pdf_y_break;
            marg_bot = this.pdf_y_break;
        }
        else{
            imgHeight = img_max_height;
            let marg = (page_max_height - img_max_height) / 2;
            marg_top = marg;
            marg_bot = marg;
        }
        let imgWidth = imgHeight / porportion;
        let imgxLoc = ((this.pdf.getPageWidth() - imgWidth) / 2);
        this.updateCurY(marg_top);
        this.pdf.addImage(this.pdf_elements['unique_reach_curve']['image'], imgxLoc, this.pdf_cur_y, imgWidth, imgHeight);
        this.updateCurY(imgHeight);
        this.updateCurY(marg_bot);
    }
    add_frequency_distribution(){
        this.num_elements_on_page += 1;
        let porportion = this.pdf_elements['frequency_distrubution']['height']/this.pdf_elements['frequency_distrubution']['width'];
        let img_max_height = (this.pdf.getPageWidth() - (this.pdf_x_break * 2)) * porportion;
        let page_max_height = ((this.pdf_height - ((this.pdf_x_break * 3)/4) - 34) / 2) - 10;
        let imgHeight = 0;
        let marg_top = 0;
        let marg_bot = 0;
        if(img_max_height > page_max_height){
            imgHeight = page_max_height;
            marg_top = 5;
            marg_bot = 5;
        }
        else{
            imgHeight = img_max_height;
            let marg = (page_max_height - img_max_height) / 2;
            marg_top = marg;
            marg_bot = marg;
        }
        let imgWidth = imgHeight / porportion;
        let imgxLoc = ((this.pdf.getPageWidth() - imgWidth) / 2);
        this.updateCurY(marg_top);
        this.pdf.addImage(this.pdf_elements['frequency_distrubution']['image'],imgxLoc,this.pdf_cur_y,imgWidth,imgHeight);
        this.updateCurY(imgHeight);
        this.updateCurY(marg_bot);
    }
    add_highlights_table(){
        this.num_elements_on_page += 1;
        let imgWidth = this.pdf_x_break * 18;
        let data_head = [['Curve Name','Target Population','Product Quantity','Unique Reach','Unique Reach %','Avg Frequency','Total Impressions','Total Budget','GRPs','CPM']];
        let data_body = export_helper.build_stats_list(this.pdf_elements['Statistics'],this.pdf_elements['configurations']);
        this.updateCurY(this.pdf_y_break);
        this.pdf.autoTable({startY: this.pdf_cur_y, tableWidth: imgWidth, head: data_head, body: data_body, headStyles: {fillColor: this.color_primary, font: "Futura", fontStyle: "bold"}, styles: {font: "Futura", fontStyle: "normal"}});
        this.updateCurY((this.pdf.previousAutoTable.finalY - this.pdf_cur_y));
    }
    add_configs(){
        if(this.pdf_cur_y >= (this.pdf_height - (this.pdf_content_height / 4) - 24)){
            this.updateCurY(4 * this.pdf_y_break);
        }
        this.num_elements_on_page += 1;
        let imgWidth = this.pdf_x_break * 18;
        let data_head = [['Curve Name', 'Product', 'Date', 'Behavior', 'Gender', 'AgeRange']];
        let data_body = export_helper.build_config_list(this.pdf_elements['configurations'], this.pdf_elements['names'], this.pdf_elements['Statistics']);
        this.updateCurY(10);
        this.pdf.autoTable({startY: this.pdf_cur_y, tableWidth: imgWidth, head: data_head, body: data_body, headStyles: {fillColor: this.color_primary, font: "Futura", fontStyle: "bold"}, styles: {font: "Futura", fontStyle: "normal"}});
    }
    updateCurY(increaseBy){
        if(((this.pdf_cur_y + increaseBy) > (this.pdf_height - 2*this.pdf_y_break - ((this.pdf_x_break * 3)/4) - 34 )) && this.num_elements_on_page != this.total_elements){
            this.pdf.addPage();
            this.pdf.setPage(this.pdf.getNumberOfPages());
            this.pdf_cur_y = 0;
            this.add_main_logo();
            this.add_footer();
        }
        else{
            this.pdf_cur_y += increaseBy;
        }
    }
    add_footer(){
        let yLoc = this.pdf_height - 24;
        this.pdf.setFontSize(8);
        this.pdf.text(['*Applied Post, DeviceIDs in Specified Geofences, Jan 1, 2020 - March 30, 2021.','**Environics was a collection of different reports including: FoodSpend_2019_CSV, CannabisInsights_2018_PSV, MoneyMatters_2020_CSV, Opticks_Vividata_2020_CSV, PRIZM_2020_v1_CSV_GeoProfiles,','and HouseholdSpend_2020_CSV', '***Statcan, Annual demographic estimates, economic regions, \'Toronto\', 2018/2019; https://www150.statcan.gc.ca/n1/pub/71-607-x/71-607-x2020020-eng.html'],10,yLoc,{lineHeightFactor: 1.1, maxWidth: this.pdf_width - 20});
    }
    save(){
        this.pdf.save(this.pdf_name);
    }
}export default PDFBuilder