import axios from 'axios'

const api_dev = 'https://save.api.docmasweetspot.ca/save'
const api_base = api_dev

function get_directory_structure(token){
    let api_call = api_base + '/get_directory_structure';
    let directory_structure = axios_get_call(api_call,token);
    return directory_structure
}
function get_project_configs(id,token){
    let api_call = api_base + '/get_project_configs';
    let body = {"project_id": id}
    let file_configs = axios_get_call_with_body(api_call,body,token);
    return file_configs;
}   
function get_directories(token){
    let api_call = api_base + '/get_directories'
    let directories = axios_get_call(api_call,token)
    return directories
}
function add_new_directory(data,token){
    let api_call = api_base + '/add_directory'
    return axios_post_call(api_call,data,token);
}
function add_new_project(data,token){
    let api_call = api_base + '/add_project'
    return axios_post_call(api_call,data,token);
}
function save_new_files(data,token){
    let api_call = api_base + '/add_file'
    return axios_post_call(api_call,data,token);
}
function save_edited_files(data,token){
    let api_call = api_base + '/edit_file'
    return axios_post_call(api_call,data,token);
}
function save_deleted_files(data,token){
    let api_call = api_base + '/delete_files';
    return axios_post_call(api_call,data,token);
}
function delete_directory(data,token){
    let api_call = api_base + '/delete_directory';
    return axios_post_call(api_call,data,token);
}
function delete_project(data,token){
    let api_call = api_base + '/delete_project';
    return axios_post_call(api_call,data,token);
}
function delete_file(data,token){
    let api_call = api_base + '/delete_file';
    return axios_post_call(api_call,data,token);
}
function edit_project_name(data,token){
    let api_call = api_base + '/edit_project_name';
    return axios_post_call(api_call,data,token);
}
function edit_directory_name(data,token){
    let api_call = api_base + '/edit_directory_name';
    return axios_post_call(api_call,data,token);
}
function get_save_users(token){
    let api_call = api_base + '/get_valid_share_users';
    return axios_get_call(api_call,token)
}
function share_project(data,token){
    let api_call = api_base + '/share_project';
    return axios_post_call(api_call,data,token);
}
/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((err)=>{
            if(err.response == null){
                err.response = {'err': err, 'status': 500}
            }
            reject(err.response);
        })
    })
}
function axios_get_call(api_url,token){
    return new Promise(function(resolve,reject){
        axios.get(api_url, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}
function axios_get_call_with_body(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios({url: api_url, method: 'get', params: data, headers: {'x-access-tokens': token, 'Content-Type': 'application/json'}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default{get_save_users,get_directories,get_directory_structure,get_project_configs,add_new_directory,add_new_project,save_new_files,save_edited_files,save_deleted_files,delete_directory,delete_project,delete_file,edit_project_name,edit_directory_name,share_project}