function getXFrom2DArray(array2d){
    var xarray = [];
    for(var i = 0; i < array2d.length; i ++){
        xarray = xarray.concat(array2d[i][0]);
    }
    return xarray;
}
function getYFrom2DArray(array2d){
    var yarray = [];
    for(var i = 0; i < array2d.length; i ++){
        yarray = yarray.concat(array2d[i][1]);
    }
    return yarray;
}
function getMaxFromArray(array){
    var maxValue = Math.max(...array);
    return maxValue
}
function getMinFromArray(array){
    var minValue = Math.min(...array);
    return (minValue != Infinity) ? minValue : 0;
}
export {getXFrom2DArray, getYFrom2DArray, getMaxFromArray, getMinFromArray}