<template>
    <v-container color="black">
        <v-row align="center" justify="center">
            <v-col cols="auto">
                <v-icon x-large color="primary">mdi-alert</v-icon>
            </v-col> 
            <v-col cols="12">
                <v-row align="center" justify="center">
                    <v-col cols="auto">
                        <span class="server_maintenance_text">SweetSpot is currently unavailable due to maintenance and will be back online Monday, August 22</span>
                    </v-col>
                    <v-col cols="auto">
                        <span class="server_maintenance_text">We apologize for the inconvience and thank you for your patience.</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ServerMaintenance',
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:400');
    .server_maintenance_text{
        font-family: 'FuturaTMedium' !important;
        font-size: 1.5em;
    }
</style>