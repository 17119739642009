<template>
    <v-container style="width:356px; height: 100%" class="py-1">
        <v-layout column style="width:100%; height: 100%;">
            <v-card style="width:100%; height:100%">
                <v-row class="ma-0 pa-1" style="height: auto; max-height:10%; width: 100%">
                    <v-col sm="12">
                        Help Desk
                    </v-col>
                </v-row>
               <v-divider class="my-2" style="margin-left: 10%; margin-right: 10%;" color="secondary"></v-divider>
               <v-expansion-panels  class="py-1" :popout=true :flat=true :hover=true style="height:auto; max-height:80%; overflow-y: scroll; width: 90%; margin-left: 5%;">
                    <v-expansion-panel>
                        <v-expansion-panel-header class="px-1 selectorTitle"><v-icon color=primary class="mx-1" style="max-width:10%">mdi-clipboard-text-play-outline</v-icon>Master Scenorio Clipboard</v-expansion-panel-header>
                        <v-expansion-panel-content class="selectors">
                            In this section, users can create new configurations as well as select configurations they would like to run. Users have the ability to run up to 5 configurations simultaneously. Configurations can be run by clicking the button labeled “Play”, which will update the components on the dashboard with data reflecting the selected configurations. In addition to the ability to create new configurations, the user can also delete existing configurations.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="px-1 selectorTitle">
                            <v-icon color=primary class="mx-1" style="max-width:10%">mdi-pencil-outline</v-icon>Scenario Editor
                        </v-expansion-panel-header>
                       <v-expansion-panel-content class="selectors">
                            The first thing, you need to do is select which scenario you want to edit. From there you can scroll down through the rows and edit as you wish. It is recommended to change the name of your scenario to reflect the target you want to set up, that way it is easy to identify later. When naming your scenario, don’t forget to press ‘Enter’ after you type it out, otherwise it won't save and you’ll need to rename it again. Same goes for the CPM. If you edit the CPM, be sure to press ‘Enter’ before you move on otherwise the CPM will default back to its original setting. Once you have set up your target parameters, go back to the Master Scenario Clipboard, ensure all the scenarios you want to run are selected and then press ‘Play’ to RUN your scenarios.
                       </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </v-layout>
    </v-container>

</template>

<script>
  export default {
    data() {
      return {
        filter: `In the top left part of the dashboard there is a filter button that you can click to open a drawer full of scenario planning options.`,
        nav: `Once you have clicked on the filter button to open the nav drawer, you will note that there are two tabs to choose from. The one on the top is the ‘Master Scenario Clipboard. The one on the bottom is ‘Target Editor’.`,
        clipboard: `Think of this tab as the spot where you can RUN your scenarios or add new scenarios to the dashboard. You can click the ‘+’ button to add a new scenario (the new scenario will generate a ‘DEFAULT’). To edit existing or new scenario’s, you must go to the Target Editor tab. Once you have edited your scenario’s you need to revisit the Master Scenario Clipboard and click the ‘Play’ button to update the dashboard.`,
        target: `The first this you need to do is select which scenario you want to edit. From there you can scroll down through the rows and edit as you wish. It is recommended to change the name of your scenario to reflect the target you want to set up, that way it is easy to identify later. When naming your scenario, don’t forget to press ‘Enter’ after you type it out, otherwise it won't save and you’ll need to rename it again. Same goes for the CPM. If you edit the CPM, be sure to press ‘Enter’ before you move on otherwise the CPM will default back to its original setting. Once you have set up your target parameters, go back to the Master Scenario Clipboard, ensure all the scenarios you want to run are selected and then press ‘Play’ to RUN your scenarios..`,
      }
    }
  }
</script>