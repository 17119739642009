const config = {
    'save': false,
    'font_path': 'docma',
    'ad_recall': false,
    'graph_title_font': 'Poppins',
    'graph_axis_title_font': 'Poppins',
    'graph_text_font': 'Amiko',
    'primary': '#EE8500',
    'secondary': '#F3A815',
    'third': '#000000',
    'main_logo_path': './assets/docma-logo-reversed.svg',
    'config_end_point': '/sweetspot/broadsign/configurations',
    'singleOperator': true,
    'ad_recall': true,
    'save': false,
    'can_switch': false,
    'singleOperator': true
}
export default {config};