<template>
    <v-container color="black">
        <v-layout text-center>
            <v-flex lg6 class="mx-auto">
                <v-card outlined class="pa-4 my-10">
                    <v-form v-if="!token_loading">
                        <v-text-field outlined type="text" placeholder="Email" v-model="email" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-text-field outlined type="password" placeholder="Password" v-model="password" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="primary" @click="login">Log In</v-btn>
                    </v-form>
                    <v-progress-circular indeterminate size="50" v-else></v-progress-circular>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import firebase from 'firebase';
import request from 'request';
// import axios from 'axios';
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            token_loading: false,
        }
    },
    methods: {
        login: async function(){
            this.token_loading = true;
            await this.getApiToken(this.email,this.password).then((val) => {
                this.$token.set_val(val);
                this.$emit('newApiToken', this.$token.get_val());
                this.$router.replace('home');
            }).catch((error) => {
                alert('Oops... Could Not Verify');
                console.error(error);
            })
            this.token_loading = false;
        },
        firebase_login_auth: function(email, password) {
            return new Promise((resolve, reject) => {
                firebase.auth().signInWithEmailAndPassword(email, password).then(
                    (user) => {
                        firebase.auth.currentUser = user;
                        resolve(true);
                    },
                    (err) => {
                        alert('Oops. ' + err.message);
                        reject()
                    }
                );
            });
        },
        getApiToken: function(email,password){
            const url = 'https://www.api.docmasweetspot.ca:443/authorization/login';
            var authCredentials = {
                'username': email,
                'password': password
            };
            return new Promise((resolve, reject) => {
                request.get(url,{'auth': authCredentials},function(error,res,body){
                    if(res.statusCode != 200){
                        reject(body)
                    }
                    var token = JSON.parse(body);
                    token = token["token"];
                    resolve(token)
                });
            })
        }
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:400');
    /*.loginFormTextStyle{*/
        /*font-family: 'Poppins' !important;*/
    /*}*/
</style>