class TreeNode {
    constructor(value, parent = null) {
        this.value = value;
        this.parent = parent;
        this.descendents = [];
    }
    getValue(){
        return this.value;
    }
    addDescendent(node) {
        this.descendents.push(node);
    }
    checkIfChildExists(value){
        for(var i = 0; i < this.descendents.length; i++){
            var treeNodeChild = this.descendents[i];
            var treeNodeChildValue = treeNodeChild.getValue();
            if(treeNodeChildValue == value){
                return true;
            }
        }
        return false;
    }
    getChild(value){
        for(var i = 0; i < this.descendents.length; i++){
            const treenNodeChild = this.descendents[i];
            var treeNodeChildValue = treenNodeChild.getValue();
            if(treeNodeChildValue == value){
                return treenNodeChild;
            }
        }
        return false;
    }
    getDescendents(){
        return this.descendents;
    }
    getDescendentValues(){
        var values = [];
        for(var desc of this.descendents){
            values.push(desc.getValue());
        }
        return values;
    }
    display(){
        this.console.log('|----' + this.value);
        for(var i = 0; i <this.descendents.length; i++){
            this.console.log("\t" + this.descendents[i].display());
        }
    }
}
class Tree{
    constructor(){
        this.root = new TreeNode('root');
        this.treeOrder = ['Market','Audience','Gender','AgeRange','StartDate','EndDate'];
    }
    getTreeOrder(){
        return this.treeOrder;
    }
    checkPath(location,gender,ageRange){
        var locNode = this.root.checkIfChildExists(location);
        var inTree = false;
        if(locNode && gender.length > 0 && ageRange.length > 0){
            var availGenders = this.getAvailableGenders(location);
            for(var g of gender){
                if(!availGenders.includes(g)){
                    inTree = false;
                }
                else{
                    inTree = true;
                }
            }
            if(inTree){
                var availAgeRanges = this.getAvailableAgeRanges(location,gender);
                for(var ar of ageRange){
                    if(!availAgeRanges.includes(ar)){
                        inTree = false;
                    }
                    else{
                        inTree = true;
                    }
                }
            }
        }
        return inTree;
    }
    checkPathFromConfig(config){
        var validPath = true;
        var failed = 'null';
        var curNodes = [this.root];
        var upcomingNodes = [];
        for(var field of this.treeOrder){
            var configValues = config[field];
            configValues = (typeof configValues == 'string') ? [configValues] : configValues;
            var valid = (configValues.length > 0) ? true : false;
            for(var value of configValues){
                for(var curNode of curNodes) {
                    if(field == 'StartDate' || field == 'EndDate'){
                        var validDate = this.checkDateRange(value, config);
                        if(validDate){
                            upcomingNodes.push(curNode.getDescendents()[0]);
                            break;
                        }
                        else{
                            valid=false;
                            break;
                        }
                    }
                    var valueInTree = curNode.checkIfChildExists(value);
                    if (!valueInTree) {
                        valid = false;
                        break;
                    }
                    else{
                        upcomingNodes.push(curNode.getChild(value));
                    }
                }
            }
            if(!valid){
                validPath = false;
                failed = field;
                break;
            }
            else{
                curNodes = upcomingNodes;
                upcomingNodes = [];
            }
        }
        return [validPath, failed];
    }
    verifyValuesAtTreeLevel(field,values,config){
        if(field == 'StartDate' || field == 'EndDate'){
            return this.checkDateRange(values,config);
        }
        var curNodesToExplore = [this.root];
        var upcomingNodesToExplore = [];
        for(var order of this.treeOrder){
            if(field != order){
                for(var node of curNodesToExplore){
                    if(typeof config[order] == 'string') {
                        if (node.checkIfChildExists(config[order])) {
                            upcomingNodesToExplore.push(node.getChild(config[order]));
                        }
                    }
                    else if(config[order] == null){
                        return false;
                    }
                    else if(typeof config[order] == 'object'){
                        for (var value of config[order]){
                            if(node.checkIfChildExists(value)){
                                upcomingNodesToExplore.push(node.getChild(value));
                            }
                        }
                    }
                    else{
                        return false;
                    }
                }
            }
            else{
                var inTree = false;
                if(typeof values == 'string') {
                    for(node of curNodesToExplore){
                        if(node.checkIfChildExists(values)){
                            return true;
                        }
                    }
                }
                else if(values == null){
                    return false;
                }
                else if(typeof values == 'object'){
                    for(value of values){
                        for(node of curNodesToExplore){
                            if(node.checkIfChildExists(value)){
                                inTree = true;
                                break;
                            }
                            else{
                                inTree = false;
                            }
                        }
                    }
                    if(inTree){
                        return true;
                    }
                }
                else{
                    return false;
                }
            }
            curNodesToExplore = upcomingNodesToExplore;
            upcomingNodesToExplore = [];
        }
        return false;
    }
    getValuesAtLevel_v1(level,config){
        var curNodesToExplore = [this.root];
        var upcomingNodesToExplore = [];
        for(var order of this.treeOrder){
            if(level != order){
                for(var node of curNodesToExplore){
                    if(typeof config[order] == 'string') {
                        var val = config[order];
                        if(order == 'StartDate' || order == 'EndDate'){
                            var dateRange = this.getDateRangeFromConfig(config);
                            var min = new Date(dateRange[0]);
                            var max = new Date(dateRange[1]);
                            val = new Date(config[order]);
                            if(val >= min && val <= max){
                                upcomingNodesToExplore.push(node.getDescendents()[0]);
                            }
                        }
                        else if (node.checkIfChildExists(val)){
                            upcomingNodesToExplore.push(node.getChild(val));
                        }
                    }
                    else if(config[order] == null){
                        return [];
                    }
                    else if(typeof config[order] == 'object'){
                        for (var value of config[order]){
                            if(node.checkIfChildExists(value)){
                                upcomingNodesToExplore.push(node.getChild(value));
                            }
                        }
                    }
                    else{
                        return [];
                    }
                }
            }
            else{
                var values = [];
                for(node of curNodesToExplore){
                    value = node.getDescendentValues();
                    values = values.concat(value);
                }
                if(values.length > 0){
                    values = values.filter((item, index) => values.indexOf(item) === index);
                }
                return values;
            }
            curNodesToExplore = upcomingNodesToExplore;
            upcomingNodesToExplore = [];
        }
        return [];
    }
    get_num_occurences(array,value){
        return array.filter((v) => (v === value)).length;
    }
    getValuesAtLevel(level,config){
        let curNodesToExplore = [this.root];
        let upcomingNodesToExplore = [];
        let nextValues = {};
        if(level == 'StartDate'){
            let dateRange = this.getDateRangeFromConfig(config);
            return [dateRange[0]]
        }
        else if(level == 'EndDate'){
            let dateRange = this.getDateRangeFromConfig(config);
            return [dateRange[1]]
        }
        for(var order of this.treeOrder){
            if(level == order){
                let values = [];
                let tmp = [];
                for(let node of curNodesToExplore){
                    let value = node.getDescendentValues();
                    tmp = tmp.concat(value);
                }
                let distinct = tmp.filter((x, i, a) => a.indexOf(x) === i);
                for(let value of distinct){
                    if(this.get_num_occurences(tmp,value) == curNodesToExplore.length){
                        values.push(value);
                    }

                }
                return values;
            }
            else {
                for(let node of curNodesToExplore){
                    let nodeValue = node.getValue();
                    let configValue = config[order];
                    if(typeof configValue == 'object' && configValue.length >= 1){
                        for(let val of configValue){
                            if(node.checkIfChildExists(val)) {
                                if (Object.keys(nextValues).includes(nodeValue)) {
                                    let tmpVars = nextValues[nodeValue];
                                    tmpVars.push(node.getChild(val));
                                    nextValues[nodeValue] = tmpVars;
                                }
                                else{
                                    nextValues[nodeValue] = [node.getChild(val)];
                                }
                            }
                        }
                    }
                    else if(typeof configValue == 'string'){
                        if(order == 'StartDate' || order == 'EndDate'){
                            var dateRange = this.getDateRangeFromConfig(config);
                            var min = new Date(dateRange[0]);
                            var max = new Date(dateRange[1]);
                            let val = new Date(config[order]);
                            if(val >= min && val <= max){
                                upcomingNodesToExplore.push(node.getDescendents()[0]);
                            }
                        }
                        else if (node.checkIfChildExists(configValue)){
                            nextValues[nodeValue] = [node.getChild(configValue)];
                        }
                    }
                }
            }
            if(Object.keys(nextValues).length == 0){
                curNodesToExplore = upcomingNodesToExplore;
                upcomingNodesToExplore = [];
                nextValues = {};
            }
            else{
                let k1 = Object.keys(nextValues)[0];
                let baseNodes = nextValues[k1];
                for(let node of baseNodes){
                    let inall = true;
                    let nodeVal = node.getValue();
                    for(let k2 of Object.keys(nextValues)){
                        // if(!nextValues[k2].includes(nodeVal)){
                        //     inall = false;
                        // }
                        let inpath = false;
                        for(let val2 of nextValues[k2]){
                            if(val2.getValue() == nodeVal){
                                inpath = true;
                            }
                        }
                        inall = inpath;
                    }
                    if(inall){
                        upcomingNodesToExplore.push(node);
                    }
                }
                curNodesToExplore = upcomingNodesToExplore;
                upcomingNodesToExplore = [];
                nextValues = {};
            }
        }
    }
    getNodesAtLevel(level,config){
        var curNodesToExplore = [this.root];
        var upcomingNodesToExplore = [];
        for(var order of this.treeOrder){
            if(level != order){
                for(var node of curNodesToExplore){
                    if(typeof config[order] == 'string') {
                        var val = config[order];
                        if(order == 'StartDate' || order == 'EndDate'){
                            val = new Date(config[order]).toUTCString();
                        }
                        if (node.checkIfChildExists(val)) {
                            upcomingNodesToExplore.push(node.getChild(val));
                        }
                    }
                    else if(config[order] == null){
                        return [];
                    }
                    else if(typeof config[order] == 'object'){
                        for (var value of config[order]){
                            if(node.checkIfChildExists(value)){
                                upcomingNodesToExplore.push(node.getChild(value));
                            }
                        }
                    }
                    else{
                        return [];
                    }
                }
            }
            else{
                var nodes = [];
                for(node of curNodesToExplore){
                    nodes = nodes.concat(node.getDescendents());
                }
                return nodes;
            }
            curNodesToExplore = upcomingNodesToExplore;
            upcomingNodesToExplore = [];
        }
        return [];
    }
    checkDateRange(date,config){
        var dateRange = this.getDateRangeFromConfig(config);
        var minDate = new Date(dateRange[0]);
        var maxDate = new Date(dateRange[1]);
        var curDate = new Date(date);
        if(curDate >= minDate && curDate <= maxDate){
            return true;
        }
        return false;
    }
    getDateRangeFromConfig(config){
        var startDateNodes = this.getNodesAtLevel('StartDate', config);
        var startDates = [];
        var endDates = [];
        for(var node of startDateNodes){
            startDates.push(node.getValue());
            endDates = endDates.concat(node.getDescendentValues())
        }
        var startDate = (startDates.length > 0) ? startDates.reduce((a,b) => {return new Date(a) < new Date(b) ? a:b}) : null;
        var endDate = (endDates.length > 0) ? endDates.reduce((a,b) => {return new Date(a) > new Date(b) ? a:b}) : null;
        return [startDate,endDate];
    }
    getNodeValues(filterMap){
        var nodeMap = [this.root];
        var upcomingNodeMap = [];
        var values = [];
        for(var order of this.treeOrder){
            if(Object.keys(filterMap).includes(order)){
                var filters = filterMap[order];
                if(filters == null || filters.length == 0){
                    return [];
                }
                filters = filters.filter((item,index) => filters.indexOf(item) === index);
                upcomingNodeMap = [];
                for(var filter of filters){
                    for(var node of nodeMap){
                        var snode = node.getChild(filter);
                        upcomingNodeMap.push(snode);
                    }
                }
                nodeMap = upcomingNodeMap;
            }
            else if(Object.keys(filterMap).length > 2){
                upcomingNodeMap = [];
                for(node of nodeMap){
                    upcomingNodeMap = upcomingNodeMap.concat(node.getDescendents());
                }
                nodeMap = upcomingNodeMap;
                for(node of nodeMap){
                    values.push(node.getValue());
                }
                break;
            }
            else {
                return [];
            }
        }
        return values;
    }
    getAllLocations(){
        var locations = [];
        var locationNodes = this.root.getDescendents();
        for(var node of locationNodes){
            locations.push(node.getValue());
        }
        return locations;
    }
    getAvailableGenders(location){
        var genders = [];
        var genderNodes = [];
        if(this.isLocationinTree(location)){
            var locationNodes = this.root.getChild(location);
            genderNodes = locationNodes.getDescendents();
        }
        for (var node of genderNodes) {
            genders.push(node.getValue());
        }
        return genders;
    }
    getAvailableAgeRanges(location,gender){
        var ageRanges = [];
        var genderNodes = [];
        var locationNode = null;
        if(this.isLocationinTree(location)){
            locationNode = this.root.getChild(location);
        }
        for(var g of gender){
            if(this.isGenderinTree(location,[g])){
                genderNodes.push(locationNode.getChild(g));
            }
        }
        for(var node of genderNodes) {
            var ageRangeNodes = node.getDescendents();
            for (var anode of ageRangeNodes) {
                ageRanges.push(anode.getValue());
            }
        }
        if(ageRanges.length > 0) {
            ageRanges = ageRanges.filter((item, index) => ageRanges.indexOf(item) === index);
        }
        return ageRanges
    }
    isLocationinTree(location){
        var inTree = this.root.checkIfChildExists(location);
        return inTree;
    }
    isGenderinTree(location,gender){
        var locationNode = this.root.getChild(location);
        var valid = false;
        for (var g of gender){
            if(locationNode.checkIfChildExists(g)){
                valid = true;
            }
            else{
                valid = false;
            }
        }
        return valid;
    }
    isAgeRangeinTree(location,gender,ageRange){
        var filterMap = {
            'Location': [location],
            'Gender': gender};
        var treeAgeRange = this.getNodeValues(filterMap);
        var valid = false;
        for(var ar of ageRange){
            if(treeAgeRange.includes(ar)){
                valid = true;
            }
            else{
                valid = false;
            }
        }
        return valid;
    }
    addConfigurationPath(variableMap, configurationArray){
        var curNode = this.root;
        for(var order of this.treeOrder){
            var configLoc = variableMap[order];
            var inTree = curNode.checkIfChildExists(configurationArray[configLoc]);
            if(inTree){
                curNode = curNode.getChild(configurationArray[configLoc]);
            }
            else{
                var newNode = new TreeNode(configurationArray[configLoc]);
                curNode.addDescendent(newNode);
                curNode = newNode;
            }
        }
    }
    addConfigurationMap(configurationMap){
        var variableMap = this.getVariableMap(configurationMap[0]);
        for(var i in configurationMap){
            if(i == 0){
                continue;
            }
            else{
                this.addConfigurationPath(variableMap, configurationMap[i]);
            }
        }
    }
    getVariableMap(variableArray){
        var variableMap = {};
        for(var v in variableArray){
            variableMap[variableArray[v]] = v
        }
        return variableMap
    }
}

export {Tree}
