<template>
    <v-alert :value="error_active" color="error" outlined>
        <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col class="ma-0 pa-0" cols="2">
                <v-icon color="red">mdi-alert-octagram-outline</v-icon>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8">
                <span class="system_error_text">{{errorMsg}}</span>
            </v-col>
            <v-col class="ma-0 pa-0" cols="2">
                <v-icon color="red" @click="close_error">mdi-close</v-icon>
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
    export default {
        name: "SweetSpotError",
        props: [
            'errorTrigger',
            'errorMsg'
        ],
        watch: {
            errorTrigger: {
                handler: function(){
                    if(this.errorTrigger == false){
                        this.error_active = false;
                    }
                    else{
                        this.error_active = true;
                    }
                },
                immediate: true
            },
        },
        data() {
            return {
                error_active: false
            }
        },
        methods: {
            close_error: function() {
                this.$emit('close');
            }
        }
    }
</script>
<style>
</style>