import jsPDF from 'jspdf'
import 'jspdf-autotable'
import pattisonLogo from '../assets/PattisonLogo.png'
import * as export_helper from './exportHelper'
class PDFBuilder{
    constructor(){
        this.pdf_elements = {
            'logo': pattisonLogo
        };
        this.pdf = new jsPDF("l","mm","a4");
        this.pdf_width = this.pdf.internal.pageSize.getWidth();
        this.pdf_height = this.pdf.internal.pageSize.getHeight();
        this.pdf_x_break = this.pdf_width / 20;
        this.pdf_y_break = this.pdf_height / 20;
        this.pdf_name = 'PattisonSweetSpot.pdf';
    }
    get_pdf_name(){
        return this.pdf_name;
    }
    set_pdf_name(pdf_name){
        if(pdf_name != '' && pdf_name != null) {
            this.pdf_name = pdf_name;
        }
    }
    add_pdf_element(elementName, element){
        this.pdf_elements[elementName] = element;
    }
    build(){
        this.add_main_logo();
        this.add_unique_reach_curve();
        this.add_frequency_distrubution();
        this.add_footer();
        this.add_highlights_table();
        this.add_footer();
        this.add_configs();
        this.add_footer();
    }
    add_main_logo(){
        let imgWidth = this.pdf_x_break * 3;
        let imgHeight = imgWidth / 4;
        let imgxLoc = this.pdf_x_break * 8.5;
        let imgyLoc = this.pdf_y_break;
        this.pdf.addImage(pattisonLogo,imgxLoc,imgyLoc,imgWidth,imgHeight);
    }
    add_unique_reach_curve(){
        let porportion = this.pdf_elements['unique_reach_curve']['height']/this.pdf_elements['unique_reach_curve']['width'];
        let imgWidth = this.pdf_x_break * 8;
        let imgHeight = imgWidth * porportion;
        let imgyLoc = (this.pdf_y_break * 2) + (this.pdf_x_break * 3 / 4);
        let imgxLoc = this.pdf_x_break;
        this.pdf.addImage(this.pdf_elements['unique_reach_curve']['image'], imgxLoc, imgyLoc, imgWidth, imgHeight);
    }
    add_frequency_distrubution(){
        let porportion = this.pdf_elements['frequency_distrubution_0']['height']/this.pdf_elements['frequency_distrubution_0']['width'];
        let imgWidth = this.pdf_x_break * 8;
        let imgHeight = imgWidth * porportion;
        let imgxLoc = this.pdf_x_break * 11;
        let imgyLoc = (this.pdf_y_break * 2) + (this.pdf_x_break * 3 / 4);
        this.pdf.addImage(this.pdf_elements['frequency_distrubution_0']['image'],imgxLoc,imgyLoc,imgWidth,imgHeight);
    }
    add_highlights_table(){
        let imgyLoc = this.pdf_height + 10;
        let imgWidth = this.pdf_x_break * 18;
        let data_head = [['Curve Name', 'CPM', 'Unique Reach', 'Target Pop', 'GRPs', 'Avg Frequency']];
        let data_body = export_helper.build_stats_list(this.pdf_elements['Statistics']);
        this.pdf.autoTable({startY: imgyLoc, tableWidth: imgWidth, head: data_head, body: data_body});
    }
    add_configs(){
        let imgWidth = this.pdf_x_break * 18;
        let imgHeight = this.pdf_height * 2 + 10;
        let data_head = [['Curve Name', 'Product', 'Date', 'Behavior', 'Gender', 'AgeRange']];
        let data_body = export_helper.build_config_list(this.pdf_elements['configurations'], this.pdf_elements['names']);
        this.pdf.autoTable({startY: imgHeight, tableWidth: imgWidth, head: data_head, body: data_body});
    }
    add_footer(){
        let yLoc = this.pdf_height - 24;
        this.pdf.setFontSize(8);
        this.pdf.text(['*Connected Interactive, DeviceIDs in Specified Geofences, Aug 1, 2019 - feb 28, 2021.','**Environics was a collection of different reports including: FoodSpend_2019_CSV, CannabisInsights_2018_PSV, MoneyMatters_2020_CSV, Opticks_Vividata_2020_CSV, PRIZM_2020_v1_CSV_GeoProfiles,','and HouseholdSpend_2020_CSV', '***Statcan, Annual demographic estimates, economic regions, \'Toronto\', 2018/2019; https://www150.statcan.gc.ca/n1/pub/71-607-x/71-607-x2020020-eng.html'],10,yLoc,{lineHeightFactor: 1.1});
    }
    save(){
        this.pdf.save(this.pdf_name);
    }
}
export default PDFBuilder