const config = {
    'font_path': 'broadsign',
    'graph_title_font': 'RobotoBold',
    'graph_axis_title_font': 'RobotoMedium',
    'graph_text_font': 'RobotoLight',
    'primary': '#001645',
    'secondary': '#00c8ff',
    'third': '#FFFFFF',
    'main_logo_path': './assets/Broadsign.png',
    'config_end_point': '/sweetspot/broadsign/configurations',
    'singleOperator': true,
    'ad_recall': false,
    'save': false,
    'can_switch': false,
    'optimizer': false,
    'export':false,
    'singleOperator': true
}
export default {config};