import Data from './data'
class sweetSpotDataManager{
    constructor(){
        this.sweetSpotData = {};
        this.visibleIds = [];
        this.visibleConfigs = {};
        this.visibleBudgets = [];
        this.recentlyDeleted = {};
    }
    get_visible_ids(){
        return this.visibleIds;
    }
    get_visible_configs(){
        return this.visibleConfigs;
    }
    addNewDataObject(configurationId,apiResponse){
        let data = new Data();
        data.load_data_from_api_response(apiResponse);
        this.sweetSpotData[configurationId] = data;
    }
    updateDataObject(configurationId,apiResponse){
        let data = this.sweetSpotData[configurationId];
        data.load_data_from_api_response(apiResponse);
        return data;
    }
    hasConfigBeenRun(configurationId){
        let configIds = Object.keys(this.sweetSpotData);
        if(configIds.includes(configurationId)){
            return true;
        }
        else{
            return false;
        }
    }
    getConfigBudgetPoints(configurationId){
        let data = this.sweetSpotData[configurationId];
        return data.get_budget_points();
    }
    deleteDataObject(id){
        let exists = this.hasConfigBeenRun(id);
        if(exists){
            delete this.sweetSpotData[id];
        }
        this.removeFromVisibleConfigurations(id);
    }
    deleteDataObjectList(ids){
        this.recentlyDeleted = {};
        for(let id of ids){
            if(this.hasConfigBeenRun(id)) {
                this.recentlyDeleted[id] = this.sweetSpotData[id];
            }
            this.deleteDataObject(id);
        }
    }
    undoRecentlyDeleted(){
        for(let id of Object.keys(this.recentlyDeleted)){
            this.sweetSpotData[id] = this.recentlyDeleted[id];
        }
        this.recentlyDeleted = {};
    }
    undoRecentlyDeletedFromIdList(idList){
        for(let id of idList){
            if(Object.keys(this.recentlyDeleted).includes(id)){
                this.sweetSpotData[id] = this.recentlyDeleted[id];
            }
        }
        this.recentlyDeleted = {};
    }

    setTargetPop(configurationId,targetPop){
        let data = this.sweetSpotData[configurationId];
        data.set_target_pop(targetPop);
    }
    getTargetPopGreatest(){
        let greatestTargetPop = 0;
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let dataTargetPop = data.get_target_pop();
            if(dataTargetPop > greatestTargetPop){
                greatestTargetPop = dataTargetPop;
            }
        }
        return greatestTargetPop;
    }
    setVisibleBudget(configurationId,budget){
        let data = this.sweetSpotData[configurationId];
        data.set_visible_budget(budget);
    }
    getVisibleBudgets(){
        let budgets = [];
        for(let dataId of this.visibleIds) {
            if(dataId == 'incr'){
                continue
            }
            let data = this.sweetSpotData[dataId];
            let vis_budg = data.get_visible_budget();
            budgets.push(vis_budg);
        }
        return budgets;
    }
    setCPM(configurationId,cpm){
        let data = this.sweetSpotData[configurationId];
        data.set_cpm(cpm);
    }
    getDataObjects(){
        return this.sweetSpotData;
    }
    getDataIds(){
        let dataIds = this.visibleIds;
        return dataIds;
    }
    getDataNames(include_incr=false){
        let dataNames = [];
        if(this.visibleIds.length == 0){
            return [{'id':'nan','name':'nan'}];
        }
        for(let visData of this.visibleConfigs){
            if(!include_incr && visData['Id'] == 'incr'){
                continue
            }
            dataNames.push({'id': visData['Id'], 'name': visData['Name']});
        }
        return dataNames;
    }
    getMaxNumOccurences(){
        let highestNumOcc = 0;
        let curNumOcc = 0;
        if(this.visibleIds.length == 0){
            return 1;
        }
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            curNumOcc = data.get_max_num_occurences();
            if(curNumOcc > highestNumOcc){
                highestNumOcc = curNumOcc;
            }
        }
        return highestNumOcc;
    }
    getBudgetPoints(){
        let budgetPoints = [];
        for(let dataId of this.visibleIds){
            let budgPoints = this.sweetSpotData[dataId].get_budget_points();
            for(let budgPoint of budgPoints){
                if(!budgetPoints.includes(budgPoint)){
                    budgetPoints.push(budgPoint)
                }
            }
        }
        return budgetPoints;
    }
    getUniqueReachDictArray(){
        let dict = {};
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let uniqueReachArray = data.get_unique_reach_array();
            dict[dataId] = uniqueReachArray;
        }
        return dict;
    }
    getUniqueReachDataArray(){
        let i = 0;
        // let numRows = (this.visibleIds.length * 3) + 1;
        let numRows = this.visibleIds.length + 1;
        let arr = [];
        if(this.visibleIds.length == 0){
            // arr.push([0,0,0,0]);
            // arr.push([1,0,0,0]);
            arr.push([0,0]);
            arr.push([1,0]);
            return arr;
        }
        for(let dataId of this.visibleIds){
            // if(dataId == 'incr'){
            //     continue
            // }
            let data = this.sweetSpotData[dataId];
            for(let uniqueReach of data.get_unique_reach_array()){
                let budg = uniqueReach[0];
                // let stdev = uniqueReach[2];
                let reach = uniqueReach[1];
                let row = [];
                row.push(budg);
                // for(let j = 0; j < i*3; j++){
                for(let j = 0; j < i; j++){
                    row.push(null);
                }
                row.push(reach);
                // row.push(reach-stdev);
                // row.push(reach+stdev);
                // for(let j = ((i*3)+4); j < numRows; j ++){
                for(let j = (i+2); j < numRows; j ++){
                    row.push(null);
                }
                arr.push(row);
            }
            i += 1;
        }
        return arr;
    }
    get_sorted_visible_id_list(){
        let id_max_list = [];
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let max = data.get_max_unique_reach() / data.get_target_pop();
            id_max_list.push({'id': dataId, 'max': max});
        }
        let sorted = id_max_list.sort((a, b) => (a.max < b.max) ? 1 : -1);
        let sorted_ids = [];
        for(let obj of sorted){
            sorted_ids.push(obj.id);
        }
        return sorted_ids;
    }
    getUniqueReachPercentArray(){
        let i = 0;
        // let numRows = (this.visibleIds.length * 3) + 1;
        let numRows = this.visibleIds.length + 1;
        let arr = [];
        if(this.visibleIds.length == 0){
            // arr.push([0,0,0,0]);
            // arr.push([1,0,0,0]);
            arr.push([0,0]);
            arr.push([1,0]);
            return arr;
        }
        // let sorted_ids = this.get_sorted_visible_id_list();
        for(let dataId of this.visibleIds){
            // if(dataId == 'incr'){
            //     continue
            // }
            let data = this.sweetSpotData[dataId];
            let targPop = data.get_target_pop();
            for(let uniqueReach of data.get_unique_reach_array()){
                let budg = uniqueReach[0];
                // let stdev = uniqueReach[2] / targPop;
                let reach = uniqueReach[1] / targPop;
                let row = [];
                row.push(budg);
                // for(let j = 0; j < i*3; j++){
                for(let j = 0; j < i; j++){
                    row.push(null);
                }
                row.push(reach);
                // row.push(reach-stdev);
                // row.push(reach+stdev);
                // for(let j = ((i*3)+4); j < numRows; j ++){
                for(let j = (i+2); j < numRows; j ++){
                    row.push(null);
                }
                arr.push(row);
            }
            i += 1;
        }
        return arr;
    }
    getUniqueReachLimitDataArray(){
        let i = 1;
        let numRows = this.visibleIds.length;
        let arr = [];
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let max_budget = data.getMaxBudget();
            let scenario_unique = data.get_on_target_reach(max_budget);
            let row = [];
            let row2 = [];
            row.push(max_budget);
            row2.push(max_budget);
            for(let j = 1; j < i; j++){
                row.push(null);
                row2.push(null);
            }
            row.push(scenario_unique);
            row2.push(0);
            for(let j = i; j < numRows; j ++){
                row.push(null);
                row2.push(null);
            }
            arr.push(row);
            arr.push(row2);

            i += 1;
        }
        return arr;
    }
    getUniqueReachLimitPercentArray(){
        let i = 1;
        let numRows = this.visibleIds.length;
        let arr = [];
        // let sorted_ids = this.get_sorted_visible_id_list();
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let targPop = data.get_target_pop();
            let max_budget = data.getMaxBudget();
            let scenario_unique = data.get_on_target_reach(max_budget);
            let row = [];
            let row2 = [];
            row.push(max_budget);
            row2.push(max_budget);
            for(let j = 1; j < i; j++){
                row.push(null);
                row2.push(null);
            }
            row.push((scenario_unique / targPop));
            row2.push(0);
            for(let j = i; j < numRows; j ++){
                row.push(null);
                row2.push(null);
            }
            arr.push(row);
            arr.push(row2);

            i += 1;
        }
        return arr;
    }
    getUniqueReachVisibleBudgPercentArray(){
        let i = 1;
        let numRows = this.visibleIds.length;
        let arr = [];
        // let sorted_ids = this.get_sorted_visible_id_list();
        for(let dataId of this.visibleIds){
            // if(dataId == 'incr'){
            //     continue
            // }
            let data = this.sweetSpotData[dataId];
            let targPop = data.get_target_pop();
            let visible_budget = data.get_visible_budget();
            let scenario_unique = data.get_on_target_reach(visible_budget);
            let row = [];
            let row2 = [];
            row.push(visible_budget);
            row2.push(visible_budget);
            for(let j = 1; j < i; j++){
                row.push(null);
                row2.push(null);
            }
            row.push((scenario_unique / targPop));
            row2.push(0);
            for(let j = i; j < numRows; j ++){
                row.push(null);
                row2.push(null);
            }
            arr.push(row);
            arr.push(row2);

            i += 1;
        }
        return arr;
    }
    getUniqueReachVisibleBudgDataArray(){
        let i = 1;
        let numRows = this.visibleIds.length;
        let arr = [];
        // let sorted_ids = this.get_sorted_visible_id_list();
        for(let dataId of this.visibleIds){
            // if(dataId == 'incr'){
            //     continue
            // }
            let data = this.sweetSpotData[dataId];
            let visible_budget = data.get_visible_budget();
            let scenario_unique = data.get_on_target_reach(visible_budget);
            let row = [];
            let row2 = [];
            row.push(visible_budget);
            row2.push(visible_budget);
            for(let j = 1; j < i; j++){
                row.push(null);
                row2.push(null);
            }
            row.push(scenario_unique);
            row2.push(0);
            for(let j = i; j < numRows; j ++){
                row.push(null);
                row2.push(null);
            }
            arr.push(row);
            arr.push(row2);

            i += 1;
        }
        return arr;
    }
    getFreqDistDataArray(budget){
        let i = 0;
        // let numRows = Object.keys(this.sweetSpotData).length + 1;
        let numRows = this.visibleIds.length + 1;
        let arr = [];
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            for(let freqDist of data.get_frequency_distrubution_at_budget(budget)){
                let freq = freqDist[0];
                let row = [];
                row.push(freq);
                for(let j = 0; j < i; j++) {
                    row.push(null);
                }
                row.push(freqDist[1]);
                for(let k = i + 2; k < numRows; k++){
                    row.push(null);
                }
                arr.push(row)
            }
            i += 1;
        }
        return arr;
    }
    getFreqDistDataArrayAtMaxBudget(){
        let i = 0;
        let numRows = Object.keys(this.sweetSpotData).length + 1;
        // let numRows = !this.visibleIds.includes('incr') ? this.visibleIds.length + 1 : this.visibleIds.length;
        let arr = [];
        if(this.visibleIds.length == 0){
            arr.push([0,0]);
            arr.push([1,0]);
            return arr;
        }
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let budg = data.get_visible_budget();
            for(let freqDist of data.get_frequency_distrubution_at_budget(budg)){
                let freq = freqDist[0];
                let row = [];
                row.push(freq);
                for(let j = 0; j < i; j++) {
                    row.push(null);
                }
                row.push(freqDist[1]);
                for(let k = i + 2; k < numRows; k++){
                    row.push(null);
                }
                arr.push(row)
            }
            i += 1;
        }
        return arr;
    }
    getFreqDistDictArrayMax(){
        let dict = {};
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let budg = data.getMaxBudget();
            let freq_dist = data.get_frequency_distrubution_at_budget_below_forty(budg);
            dict[dataId] = freq_dist;
        }
        return dict;
    }
    getFreqDistDictArray(budget){
        let dict = {};
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let freq_dist = data.get_frequency_distrubution_at_budget_below_forty(budget);
            dict[dataId] = freq_dist;
        }
        return dict;
    }
    getUniqueReachDictPercentage(){
        let dict = {};
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let targ = data.get_target_pop();
            let new_arr = [];
            for(let row of data.get_unique_reach_array()){
                new_arr.push([row[0],(row[1]/targ)]);
            }
            dict[dataId] = new_arr;
        }
        return dict;
    }
    getPercentageLimit(){
        let greatestTargetPop = 0;
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let dataMax = data.get_max_ur_percentage();
            if(dataMax > greatestTargetPop){
                greatestTargetPop = dataMax;
            }
        }
        return greatestTargetPop;
    }
    getDataLimit(){
        let greatestTargetPop = 0;
        for(let dataId of this.visibleIds){
            let data = this.sweetSpotData[dataId];
            let dataMax = data.get_max_unique_reach();
            if(dataMax > greatestTargetPop){
                greatestTargetPop = dataMax;
            }
        }
        return greatestTargetPop;
    }
    getAllDataStatistics(budget){
        let statisticsMap = {};
        for(let dataInfo of this.visibleConfigs){
            let dataId = dataInfo['Id'];
            let dataName = dataInfo['Name'];
            let data = this.sweetSpotData[dataId];
            let reach = data.get_on_target_reach(budget);
            let avg_freq = data.get_avg_frequency_at_budget(budget);
            let target_pop = data.get_target_pop();
            let grps = data.get_grps(budget,target_pop);
            let cpm = data.get_cpm();
            let impressions = data.get_impressions(budget);
            statisticsMap[dataId] = {'OnTargetReach':reach,'AvgFrequency':avg_freq,'TargetPop':target_pop,'GRPS':grps,'CPM':cpm, 'Impressions': impressions, 'Name': dataName}
         }
         return statisticsMap;
    }
    getAllDataStatisticsAtMaxBudget(){
        let statisticsMap = {};
        if(this.visibleIds.length == 0){
            statisticsMap[0] = {'OnTargetReach': 0,'AvgFrequency': 0,'TargetPop': 0,'GRPS': 0,'CPM': 0, 'Impressions': 0, 'Name': 'None'};
            return statisticsMap;
        }
        for(let dataInfo of this.visibleConfigs){
            let dataId = dataInfo['Id'];
            let dataName = dataInfo['Name'];
            let data = this.sweetSpotData[dataId];
            let budg = data.getMaxBudget();
            let reach = data.get_on_target_reach(budg);
            let avg_freq = data.get_avg_frequency_at_budget(budg);
            let target_pop = data.get_target_pop();
            let grps = data.get_grps(budg,target_pop);
            let cpm = data.get_cpm();
            let impressions = data.get_impressions(budg);
            statisticsMap[dataId] = {'OnTargetReach':reach,'AvgFrequency':avg_freq,'TargetPop':target_pop,'GRPS':grps,'CPM':cpm, 'Impressions': impressions, 'Name': dataName}
        }
        return statisticsMap;
    }
    getAllDataStatisticsAtVisibleBudget(){
        let statisticsMap = {};
        if(this.visibleIds.length == 0){
            return statisticsMap;
        }
        for(let dataInfo of this.visibleConfigs){
            let dataId = dataInfo['Id'];
            let dataName = dataInfo['Name'];
            let data = this.sweetSpotData[dataId];
            let initial_budg_point = data.get_budget_points()[1];
            let budg = data.get_visible_budget();
            let reach = data.get_on_target_reach(budg);
            let avg_freq = data.get_avg_frequency_at_budget(budg);
            let target_pop = data.get_target_pop();
            let grps = data.get_grps(budg);
            let cpm = data.get_cpm_at_budget(budg);
            let impressions = data.get_impressions(budg);
            statisticsMap[dataId] = {'OnTargetReach':reach,'AvgFrequency':avg_freq,'TargetPop':target_pop,'GRPS':grps,'CPM':cpm, 'Impressions': impressions, 'Name': dataName, 'Budget': budg, 'NumberOf': (budg/initial_budg_point)}
        }
        return statisticsMap;
    }
    setVisibleConfigurations(visibleConfigurations){
        let visids = [];
        for(let visibleConfig of visibleConfigurations) {
            visids.push(visibleConfig['Id']);
        }
        this.visibleIds = visids;
        this.visibleConfigs = visibleConfigurations;
    }
    addVisibleConfiguration(visibleConfig){
        if(!this.visibleIds.includes(visibleConfig['Id'])){
            this.visibleIds.push(visibleConfig['Id']);
            this.visibleConfigs.push(visibleConfig);
        }
    }
    removeFromVisibleConfigurations(id){
        const visible_id_filter = (element) => element == id;
        const visible_config_filter = (element) => element['Id'] == id;
        if(this.visibleIds.includes(id)){
            let visible_id_idx = this.visibleIds.findIndex(visible_id_filter);
            let visible_config_idx = this.visibleConfigs.findIndex(visible_config_filter);
            this.visibleIds.splice(visible_id_idx,1);
            this.visibleConfigs.splice(visible_config_idx,1);
        }
    }
    getVisibleConfigurationNames(){
        let visible_names = [];
        for(let dataInfo of this.visibleConfigs) {
            let dataName = dataInfo['Name'];
            visible_names.push(dataName);
        }
        return visible_names;
    }
    getNumProducts(include_incr=true){
        let num_prods = {};
        if(Object.keys(this.visibleConfigs).length == 0){
            return {'nan': ['n/a']}
        }
        for(let dataInfo of this.visibleConfigs) {
            let dataId = dataInfo['Id'];
            if(!include_incr && dataId == 'incr'){
                continue
            }
            // let dataName = dataInfo['Name'];
            let data = this.sweetSpotData[dataId];
            num_prods[dataId] = data.get_num_products_dict();
        }
        return num_prods;
    }
    get_budget_grps(){
        let budg_grps_list = [];
        for(let dataInfo of this.visibleConfigs){
            let dataId = dataInfo['Id'];
            let data = this.sweetSpotData[dataId];
            budg_grps_list.push(data.get_budget_grps());
        }
        return budg_grps_list;
    }
    get_budget_reach(){
        let budg_reach_list = [];
        for(let dataInfo of this.visibleConfigs){
            let dataId = dataInfo['Id'];
            if(dataId == 'incr'){
                continue
            }
            let data = this.sweetSpotData[dataId];
            budg_reach_list.push(data.get_budget_reach());
        }
        return budg_reach_list;
    }
}
export default sweetSpotDataManager