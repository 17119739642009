<template>
    <v-container style="min-width: calc(50vw - 86px); max-width: calc(100vw - 86px); width: auto; height: 100%;" class="py-1">
        <v-layout column style="width:100%; height: 100%;">
            <v-row>
                <v-col>
                    <v-card style="width: calc(50vw - 110px); max-height:100%" class="d-flex flex-column">
                        <SweetSpotError errorMsg="There was an error retrieving your saved files. Please Reload." :errorTrigger="get_directory_error" v-on:close="get_directory_error=false"></SweetSpotError>
                        <SweetSpotError errorMsg="There was an error opening your project. Please Try Again." :errorTrigger="retrieve_project_error" v-on:close="retrieve_project_error=false"></SweetSpotError>
                        <SweetSpotError errorMsg="There was an Error Deleting Your Directory. Please Try Again." :errorTrigger="delete_directory_error" v-on:close="delete_directory_error=false"></SweetSpotError>
                        <SweetSpotError errorMsg="There was an Error Deleting Your Project. Please Try Again." :errorTrigger="delete_project_error" v-on:close="delete_project_error=false"></SweetSpotError> 
                        <SweetSpotError errorMsg="There was an error adding your new directory. Please try again. " :errorTrigger="add_directory_error" v-on:close="add_directory_error=false"></SweetSpotError> 
                        <SweetSpotError errorMsg="There was an error adding your new project. Please try again." :errorTrigger="add_project_error" v-on:close="add_project_error=false"></SweetSpotError>           
                        <SweetSpotError errorMsg="There was an error renaming your project. Please try again." :errorTrigger="rename_project_error" v-on:close="rename_projdirectory_error=false"></SweetSpotError>
                        <SweetSpotWarning warningMsg="Directory Exists. Please add a directory with a new name." :warningTrigger="add_directory_bad_name" v-on:close="add_directory_bad_name=false"></SweetSpotWarning>
                        <SweetSpotWarning warningMsg="Project Exists. Please add a project with a new name." :warningTrigger="add_project_bad_name" v-on:close="add_project_bad_name=false"></SweetSpotWarning>
                        <v-row class="ma-0 pa-0" style="max-height: 5%">
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="ma-1 pa-0">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" color="primary" @click="get_directory_structure">mdi-reload</v-icon>
                                    </template>
                                    <span class="help-tooltip">Reload Saved Directories</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-treeview class="save_directory_name" style="max-height: 80%; overflow-y: scroll" :items="directory_structure" rounded hoverable activatable open-on-click :active.sync="active">
                            <template v-slot:prepend="{item,open}">
                                <v-icon color="primary" v-if="!item.file && !item.project">
                                    {{open ? 'mdi-folder-open': 'mdi-folder'}}
                                </v-icon>
                                <v-icon color="primary" v-if="!item.file && item.project">
                                    mdi-notebook-outline
                                </v-icon>
                                <v-icon color="primary" v-if="item.file && !item.project">
                                    mdi-file-document-outline
                                </v-icon>
                            </template>
                            <template v-slot:append="{item,open}">
                                <v-menu v-if="item.id != 0 && !(item.project && shared_project_ids.includes(item.id))" offset-x>
                                    <template v-slot:activator="{on,attrs}">
                                        <v-icon color="primary" class="mx-2" v-bind="attrs" v-on="on">
                                            mdi-dots-vertical
                                        </v-icon>
                                    </template>
                                    <v-list dense v-if="!item.file && !item.project">
                                        <v-list-item-group>
                                            <v-list-item @click="rename_directory=true; rename_id=item.id;">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">mdi-pencil</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Rename</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item @click="new_directory_id=item.id; add_new_project = true">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">$notebook_plus_outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Add New Project</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item @click="confirm_delete = item.id; confirm_delete_name = item.name; confirm_delete_diretory = true;">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">mdi-delete</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Delete</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                    <v-list dense v-if="item.project">
                                        <v-list-item-group>
                                            <v-list-item @click="rename_project=true; rename_id=item.id;">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">mdi-pencil</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Rename</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item @click="share_project_id=item.id;reset_save_users_open_share();">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">mdi-share</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Share</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item @click="confirm_delete = item.id; confirm_delete_name = item.name; confirm_delete_project = true;">
                                                <v-list-item-icon> 
                                                    <v-icon color="primary">mdi-delete</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="save_action_text">Delete</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-treeview>
                        <v-spacer></v-spacer>
                        <v-card-actions style="max-height: 15%;">
                            <v-row style="width: 100%;" class="ma-0 pa-0">
                                <v-spacer></v-spacer>
                                <v-col cols="6" align-self="center">
                                    <v-tooltip bottom open-delay="500">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color=primary v-bind="attrs" v-on="on" @click="add_new_directory = true"><v-icon color="white">$folder_multiple_plus_outline</v-icon></v-btn>
                                        </template>
                                        <span class="help-tooltip">Create Directory</span>
                                    </v-tooltip>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
        <v-overlay :value="add_new_directory" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="add_new_directory = false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Add New Directory</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-text-field class="save_overlay_input" label="Directory Name" v-model="new_directory_name"></v-text-field>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action_text" @click="add_directory(new_directory_name)" :disabled="new_directory_name == '' || new_directory_name == null">Add</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Add</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="add_new_project" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="add_new_project = false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0" >
                        <v-card-title class="save_overlay_title">Add New Project</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <!-- <v-select class="save_overlay_input" v-model="new_directory_id" :items="directory_structure" item-text="name" item-value="id" label="directory"></v-select> -->
                <v-text-field class="save_overlay_input" v-model="new_project_name" label="Project Name"></v-text-field>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action_text" @click="add_project(new_directory_id,new_project_name)" :disabled="new_project_name == '' || new_project_name == null || new_directory_id == null">Add</v-btn>
                            </template>
                            <span class="help-toolip">Add Project</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="confirm_delete_project || confirm_delete_diretory" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="confirm_delete_diretory = false; confirm_delete_project = false;">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Are You Sure?</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="save_overlay_title">{{confirm_delete_name}} will be permenantely deleted.</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action" rounded v-if="confirm_delete_diretory" @click="delete_directory(confirm_delete)">Delete</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action" rounded v-if="confirm_delete_project" @click="delete_project(confirm_delete)">Delete</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Delete</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="save_opened_project" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="save_opened_project=false; if(open_project_id != null){active.push(open_project_id)}else{active = []}">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Unsaved Changes</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="save_overlay_title">{{open_project_name}} has unsaved changes.</span><br>
                        <span class="save_overlay_title">Would you like to save the changes?</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="ma-0 mt-3" style="width: 100%" justify="space-around">
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" rounded @click="save_close_project">
                                    <v-icon>
                                        mdi-content-save
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="help-toolip">Save Changes</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" rounded @click="close_project">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="help-toolip">Delete Unsaved Changes</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="rename_directory" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="rename_directory=false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Rename Directory</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-text-field class="save_overlay_input" label="Directory Name" v-model="rename_directory_name"></v-text-field>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action_text" @click="edit_directory_name(rename_directory_name)" :disabled="rename_directory_name == '' || rename_directory_name == null">Rename</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Rename</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="rename_project" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="rename_project=false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Rename Project</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-text-field class="save_overlay_input" label="Project Name" v-model="rename_project_name"></v-text-field>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action_text" @click="edit_project_name(rename_project_name)" :disabled="rename_project_name == '' || rename_project_name == null">Rename</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Rename</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-overlay>
        <v-overlay :value="share_project" absolute>
            <v-card class="pa-3">
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-icon @click="share_project=false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-card-title class="save_overlay_title">Share Project</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-autocomplete class="save_overlay_input" label="Share With" :items="save_users" :search-input.sync="search" v-model="share_project_users" chips clearable hide-details solo multiple></v-autocomplete>
                <v-row class="ma-0 mt-3" style="width: 100%">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" class="save_overlay_action_text" @click="share_project_with" :disabled="share_project_users == null || share_project_users.length == 0">Share</v-btn>
                            </template>
                            <span class="help-toolip">Confirm Share</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>        
        </v-overlay>
    </v-container>
</template>

<script>
    import api_caller from '../javascript/save_api_caller'
    import SweetSpotError from './alerts/SweetSpotError'
    import SweetSpotWarning from './alerts/SweetSpotWarning'
    export default {
        name: "configurationSave",
        components: {
           SweetSpotError,
           SweetSpotWarning
        },
        props: [
            'configurationManager',
            'unsavedChanges'
        ],
        data(){
            return {
                projects: [
                    {'id': 1, 'name': 'Project 1', 'file': false, 'children': [{'id': 3, 'name': 'File 1', 'file': true}, {'id': 4, 'name': 'File 2', 'file': true}]},
                    {'id': 2, 'name': 'Project 2', 'file': false}
                ],
                directory_structure: [],
                shared_project_ids: [],
                search: null,
                open_project_id: null,
                open_project_name: null,
                pending_saves: false,
                changed_ids: [],
                change_log: [],
                add_new_directory: false,
                new_directory_name: '',
                add_new_project: false,
                new_project_name: '',
                new_directory_id: null,
                confirm_delete_project: false,
                confirm_delete_diretory: false,
                confirm_delete: -1,
                confirm_delete_name: null,
                save_opened_project: false,
                rename_project: false,
                rename_project_name: null,
                rename_directory: false,
                rename_directory_name: null,
                rename_id: null,
                save_users: [],
                share_project: false,
                share_project_id: null,
                share_project_users: [],
                //errors
                get_directory_error: false,
                add_directory_bad_name: false,
                add_directory_error: false,
                add_project_bad_name: false,
                add_project_error: false,
                delete_directory_error: false,
                delete_project_error: false,
                retrieve_project_error: false,
                rename_project_error: false,
                rename_directory_error: false,
                open: [],
                active: []
            }
        },
        mounted() {
            this.get_directory_structure();
        },
        watch: {
            active: {
                handler(){
                    if(this.active.length == 0 && this.open_project_id != null){
                        if(this.unsavedChanges){
                            this.save_opened_project = true;
                        }
                        else{
                            this.close_project();
                        }
                        return;
                    }
                    for(let directory of this.directory_structure){
                        for(let project of directory['children']){
                            if(project.id == this.active[0]){
                                if(this.unsavedChanges && this.open_project_id != null){
                                    this.save_opened_project = true;
                                }
                                else{
                                    this.open_project({'id':project.id, 'name': project.name})
                                }
                            }
                        }
                    }
                },
                immediate: true
            }
        },
        methods: {
            get_directory_structure: async function(){
                this.reset_errors();
                this.shared_project_ids = [];
                let directory_structure = await api_caller.get_directory_structure(this.$token.get_val()).catch(()=>{
                    directory_structure = {};
                    this.get_directory_error = true;
                    return;
                });
                let ds = []
                for(let id in directory_structure){
                    let tmp = {"id": id, "name": directory_structure[id]['name'], "file": false, "project": false};
                    let children = [];
                    let is_shared = id == 0;
                    for(let pid in directory_structure[id]["projects"]){
                        let ptmp = {"id": pid, "name": directory_structure[id]['projects'][pid]['name'], "file": false, "project": true};
                        if(is_shared){
                            this.shared_project_ids.push(pid);
                        }
                        // let pchildren = []
                        // for(let fid in directory_structure[id]["projects"][pid]["files"]){
                        //     let ftmp = {"id": fid, "name": directory_structure[id]['projects'][pid]['files'][fid]['name'], "file": true, "project": false};
                        //     pchildren.push(ftmp);
                        // }
                        ptmp['children'] = [];
                        children.push(ptmp);
                    }
                    tmp['children'] = children;
                    ds.push(tmp);
                }
                this.directory_structure = ds;
            },
            open_project: async function(item){
                if(this.open_project_id == item.id){
                    return;
                }
                let id = item.id;
                let name = item.name;
                //get file configs associated with the project id
                let file_configs = await api_caller.get_project_configs(id,this.$token.get_val()).then((resp) => {
                    return resp;
                }).catch((err) =>{
                    console.error(err);
                    return false;
                });
                if(typeof(file_configs) == Boolean && file_configs == false){
                    this.retrieve_project_error = true;
                    return
                }
                this.open_project_id = id;
                this.open_project_name = name;
                this.pending_saves = false;
                //delete all configurations
                //reset manager id
                //reset recently deleted
                let config_ids = this.configurationManager.getConfigurationIds();
                this.configurationManager.deleteConfigurationList(config_ids);
                this.configurationManager.reset_recently_deleted();
                this.configurationManager.reset_config_id();
                //add configs
                let visible_budgets = [];
                for(let file_config of file_configs){
                    visible_budgets.push(file_config['VisibleBudg'])
                    this.configurationManager.addNewConfigurationFromConfiguration(file_config);
                }
                if(this.shared_project_ids.includes(id)){
                    id = -1;
                    name += " (Read Only)";
                }
                this.$emit('openProject', {"Name": name, "Id": id, "VisibleBudgets": visible_budgets});
            },
            close_project: function(){
                let config_ids = this.configurationManager.getConfigurationIds();
                this.configurationManager.deleteConfigurationList(config_ids);
                this.configurationManager.reset_recently_deleted();
                this.configurationManager.reset_config_id();
                this.open_project_id = null;
                this.open_project_name = null;
                this.pending_saves = false;
                this.save_opened_project = false;
                this.$emit('closeProject');
                if(this.active.length > 0){
                    for(let directory of this.directory_structure){
                        for(let project of directory['children']){
                            if(project.id == this.active[0]){
                                this.open_project({'id':project.id, 'name': project.name})
                            }
                        }
                    }
                }
            },
            save_close_project: async function(){
                this.$emit('saveProject');
                while(this.unsavedChanges){
                    await this.sleep(500);
                }
                this.close_project();
            },
            add_directory: async function(directory_name){
                this.reset_errors();
                let body = {
                    'directory_name': directory_name
                };
                let valid = await api_caller.add_new_directory(body,this.$token.get_val()).then(()=>{
                    return true;
                }).catch((error)=>{
                    if(error.status == 400){
                        this.add_directory_bad_name = true;
                    }
                    else{
                        this.add_directory_error = true;
                    }
                    return false;
                });
                this.add_new_directory = false;
                this.new_directory_name = '';
                if(!valid){
                    return;
                }
                this.get_directory_structure();
            }, 
            add_project: async function(directory_id, project_name){
                let body = {
                    'directory_id': parseInt(directory_id),
                    'project_name': project_name
                };
                let valid = await api_caller.add_new_project(body,this.$token.get_val()).then(()=>{
                    return true;
                }).catch((error)=>{
                    console.log(error)
                    if(error.status == 400){
                        this.add_project_bad_name = true;
                    }
                    else{
                        this.add_project_error = true;
                    }
                    return false;
                });
                this.add_new_project = false;
                this.new_directory_id = null;
                this.new_project_name = '';
                if(!valid){
                    return;
                }
                this.get_directory_structure();
            },
            reset_errors: function(){
                this.get_directory_error = false;
                this.add_directory_error = false;
                this.add_directory_bad_name = false;
                this.add_project_bad_name = false;
                this.add_project_erorr = false;
                this.delete_project_error = false;
                this.delete_directory_error = false;
                this.retrieve_project_error = false;
            },
            delete_directory: async function(id){
                let body = {
                    'directory_id': id
                }
                let valid = await api_caller.delete_directory(body,this.$token.get_val()).then(() => { 
                    return true;
                }).catch((err) => {
                    console.error(err);
                    this.delete_directory_error = true;
                    return false;
                })
                this.confirm_delete_diretory = false;
                this.confirm_delete = -1;
                this.confirm_delete_name = null;
                if(valid){
                    this.get_directory_structure();
                }
            },
            delete_project: async function(id){
                let body = {
                    'project_id': id
                }
                if(this.open_project_id == id){
                    this.close_project();
                }
                let valid = await api_caller.delete_project(body,this.$token.get_val()).then(() => {
                    return true;
                }).catch((err) => {
                    console.error(err);
                    this.delete_project_error = true;
                    return false;
                })
                this.confirm_delete_project = false;
                this.confirm_delete = -1;
                this.confirm_delete_name = null;
                if(valid){
                    this.get_directory_structure();
                }
            },
            edit_project_name: async function(name){
                let body = {
                    'project_id': this.rename_id,
                    'project_name': name
                }
                let valid = await api_caller.edit_project_name(body,this.$token.get_val()).then(()=>{
                    return true;
                }).catch((err)=>{
                    console.error(err);
                    this.rename_project_error = true;
                    return false;
                })
                if(valid){
                    this.rename_project_name = null;
                    this.rename_project = false;
                    this.get_directory_structure();
                }
            },
            edit_directory_name: async function(name){
                let body = {
                    'directory_id': this.rename_id,
                    'directory_name': name
                }
                let valid = await api_caller.edit_directory_name(body,this.$token.get_val()).then(()=>{
                    return true;
                }).catch((err)=>{
                    console.error(err);
                    this.rename_directory_error = true;
                    return false;
                })
                if(valid){
                    this.rename_directory_name = null;
                    this.rename_directory = false;
                    this.get_directory_structure();
                }
            },
            reset_save_users_open_share: async function(){
                let users = await api_caller.get_save_users(this.$token.get_val()).then((response)=>{
                    return response;
                }).catch((err)=>{
                    console.error(err);
                    return [];
                })
                this.save_users = users;
                this.share_project = true;
                this.share_project_users = [];
            },
            share_project_with: async function(){
                let emails = this.share_project_users;
                if(this.share_project_users.length == 1){
                    emails = this.share_project_users[0];
                }
                let body = {
                    'project_id': parseInt(this.share_project_id),
                    'user_email': emails
                }
                let valid = await api_caller.share_project(body,this.$token.get_val()).then(()=>{
                    return true;
                }).catch((err)=>{
                    console.error(err);
                    return false;
                })
                if(valid){
                    this.share_project_users = [];
                    this.share_project = false;
                }
            },
            sleep: function(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }
    }
</script>

<style>
</style>