import SweetSpotConfiguration from './sweetSpotConfiguration'
class sweetSpotConfigurationManager{
    constructor(){
        this.sweetSpotConfigurations = {};
        this.recentlyDeleted = {};
        this.curConfigId = 0;
    }
    getSweetSpotConfiguration(configurationID){
        var configuration = this.sweetSpotConfigurations[configurationID];
        return configuration;
    }
    getConfigurationList(){
        let map = [];
        for(var key of Object.keys(this.sweetSpotConfigurations)) {
            let configuration = this.sweetSpotConfigurations[key];
            let configuration_name = configuration.get_configuration_name();
            let tmp = {'id': key, 'config':configuration, 'name': configuration_name};
            map.push(tmp);
        }
        return map;
    }
    getConfigurationListFromIds(ids){
        let map = [];
        for(var key of Object.keys(this.sweetSpotConfigurations)) {
            if(!ids.includes(parseInt(key)) && !ids.includes(String(key))){
                continue
            }
            let configuration = this.sweetSpotConfigurations[key];
            let configuration_name = configuration.get_configuration_name();
            let tmp = {'id': key, 'config':configuration, 'name': configuration_name};
            map.push(tmp);
        }
        return map;
    }
    getConfigurationIds(){
        let ids = [];
        for(var key of Object.keys(this.sweetSpotConfigurations)){
            ids.push(key);
        }
        return ids;
    }
    updateConfiguration(configurationID,field,value){
        var configuration = this.sweetSpotConfigurations[configurationID];
        configuration.updateField(field,value);
    }
    replaceConfiguration(configurationID,configuration){
        let config = this.sweetSpotConfigurations[configurationID];
        for(let key of Object.keys(configuration)){
            config.updateField(key, configuration[key]);
        }
    }
    addNewBlankConfiguration(){
        var configuration = new SweetSpotConfiguration();
        var configurationID = this.getNewConfigurationId();
        this.sweetSpotConfigurations[configurationID] = configuration;
        return configurationID
    }
    addNewConfigurationFromConfiguration(configuration){
        var configurationID = this.getNewConfigurationId();
        let config = new SweetSpotConfiguration();
        for(let field of Object.keys(configuration)){
            config.updateField(field,configuration[field]);
        }
        this.sweetSpotConfigurations[configurationID] = config;
    }
    duplicateConfiguration(configuration, configuration_name){
        var configurationID = this.getNewConfigurationId();
        let config = new SweetSpotConfiguration();
        for(let field of Object.keys(configuration)){
            config.updateField(field,configuration[field]);
        }
        config.set_configuration_name(configuration_name + ' - COPY');
        this.sweetSpotConfigurations[configurationID] = config;
    }
    addNewConfigurationFromConfigurationAt(configuration, configurationID){
        // var configurationID = this.getNewConfigurationId();
        let config = new SweetSpotConfiguration();
        for(let field of Object.keys(configuration)){
            config.updateField(field,configuration[field]);
        }
        this.sweetSpotConfigurations[configurationID] = config;
    }
    addNewRandomConfiguration(configTree){
        var configuration = new SweetSpotConfiguration();
        configuration.setRandomConfigurationFromTree(configTree);
        var configurationID = this.getNewConfigurationId();
        this.sweetSpotConfigurations[configurationID] = configuration;
        return configurationID
    }
    deleteConfigurartion(configurationID){
        delete this.sweetSpotConfigurations[configurationID];
    }
    deleteConfigurationList(configurationIDList){
        this.recentlyDeleted = {};
        let file_ids = [];
        for(let id of configurationIDList){
            if(this.isConfigurationIdinManager(id)){
                let config = this.getSweetSpotConfiguration(id);
                let config_id = config.get_configuration_id();
                if(config_id != -1){
                    file_ids.push(config_id);
                }
                this.recentlyDeleted[id] = config;
                this.deleteConfigurartion(id);
            }
        }
        return file_ids
    }
    getRecentlyDeleted(){
        return this.recentlyDeleted;
    }
    getRecentlyDeletedNameIdList(){
        let id_name_map = [];
        for(let id of Object.keys(this.recentlyDeleted)){
            let config_name = this.recentlyDeleted[id].get_configuration_name();
            id_name_map.push({'name':config_name, 'id':id});
        }
        return id_name_map;
    }
    recentlyDeletedExists(){
        if(Object.keys(this.recentlyDeleted).length > 0){
            return true;
        }
        else{
            return false;
        }
    }
    undoAllRecentlyDeleted(){
        let file_ids = [];
        for(let id of Object.keys(this.recentlyDeleted)){
            this.sweetSpotConfigurations[id] = this.recentlyDeleted[id];
            let config_id = this.sweetSpotConfigurations[id].get_configuration_id()
            if(config_id != -1){
                file_ids.push(config_id);
            }
        }
        this.recentlyDeleted = {};
        return file_ids;
    }
    undoRecentlyDeletedFromIdList(idList){
        let file_ids = [];
        for(let id of idList){
            if(Object.keys(this.recentlyDeleted).includes(id)){
                this.sweetSpotConfigurations[id] = this.recentlyDeleted[id];
                let config_id = this.sweetSpotConfigurations[id].get_configuration_id()
                if(config_id != -1){
                    file_ids.push([config_id,id]);
                }
            }
        }
        this.recentlyDeleted = {};
        return file_ids;
    }
    getConfigurationName(configurationID){
        var i = 1;
        for(var configKey of Object.keys(this.sweetSpotConfigurations)){
            if(configKey == configurationID){
                return i;
            }
            else{
                i += 1;
            }
        }
        return 0
    }
    getNewConfigurationId(){
        this.curConfigId += 1;
        return this.curConfigId;
    }
    isConfigurationIdinManager(configurationId){
        let configurationIds = Object.keys(this.sweetSpotConfigurations);
        if(configurationIds.includes(configurationId)){
            return true;
        }
        return false;
    }
    isConfigurationEqual(configurationId,config){
        let managerConfig = this.sweetSpotConfigurations[configurationId];
        if(config.isConfigEqual(managerConfig.get_configuration())){
            return true
        }
        return false;
    }
    are_configurations_omni_channel_compatible(configurationIds){
        if(configurationIds.length < 2 || configurationIds.length > 3){
            return false;
        }
        let managerConfig = this.sweetSpotConfigurations[configurationIds[0]];
        for(let configId of configurationIds){
            if(!this.sweetSpotConfigurations[configId].omni_channel_compatible(managerConfig.get_configuration())){
                return false;
            }
        }
        return true;
    }
    build_omni_channel_config(configurationIds) {
        let products = [];
        let markets = [];
        let base = {...this.sweetSpotConfigurations[configurationIds[0]].get_configuration()};
        for (let configId of configurationIds) {
            if(configId == 'incr'){
                continue;
            }
            let product = this.sweetSpotConfigurations[configId].get_configuration()['Product'];
            let market = this.sweetSpotConfigurations[configId].get_configuration()['Market'];
            products.push(product);
            markets.push(market);
        }
        if(markets.every( (val, i, arr) => val === arr[0] )){
            markets = markets[0];
        }
        let config = new SweetSpotConfiguration(markets, base['Audience'], base['Gender'], base['AgeRange'], base['StartDate'], base['EndDate']);
        config.updateField('Product', products);
        return config.get_configuration();
    }
    reset_recently_deleted(){
        this.recentlyDeleted = {};
    }
    reset_config_id(){
        this.curConfigId = 0;
    }
}
export default sweetSpotConfigurationManager