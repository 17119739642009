<template>
    <v-container fluid style="width: calc(100vw - 86px); max-height: calc(100vh - 88px); min-height: calc(100vh - 88px);" class="pa-0 ma-0">
        <v-card class="mx-3 my-0" style="max-width: 100%; max-height: calc(100vh - 88px)">
            <v-row class="px-3" style="max-height: calc(0.1 * (100vh - 88px));">
                <v-col cols="6" class="ma-0 py-0">
                    <v-text-field v-model="configuration_name" @keyup="nameDeB(configuration_name)" class="selectors" color="primary"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="px-3" style="max-height: calc(0.9 * (100vh - 88px)); height: 90%; overflow-y: scroll">
                <v-col cols="4" class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col cols="12">
                            <v-card class="ma-0 pa-2">
                                <v-row style="position:absolute; z-index: 4; width: 100%;">
                                    <v-col sm="12" class="py-0">
                                        <transition name="fade">
                                            <div class="successAlertBox" v-if="product_success">
                                                <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                                                <span class="alertTitle">Updated!</span>
                                                <v-icon class="alertIcon" @click="product_success = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                        <transition name="fade">
                                            <div class="errorAlertBox" v-if="product_error">
                                                <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                                                <span class="alertTitle">Error!</span>
                                                <v-icon class="alertIcon" @click="invalidPathAtProduct = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                                <v-card-title class="ma-0 pa-0 selectorTitle">
                                    <v-icon color=primary class="mr-2" style="max-width:10%">mdi-basket-fill</v-icon>
                                    Product
                                </v-card-title>
                                <v-select v-if="!$configuration.get_config_param('singleOperator')" class="selectors" v-model="operator" :items="availableOperators" @change="verifyConfigurationChange($event,'Operator')"></v-select>
                                <v-select class="selectors" v-model="market" :items="availableMarkets" @change="updateMarket($event);"></v-select>
                                <v-select class="selectors" :value="product" :items="availableProducts" @change="updateProductType($event)"></v-select>
                                <v-text-field class="selectors" type="number" min="0" max="100" label="% Discount" :value="discount" @change="updateDiscount($event)"></v-text-field>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col cols="12">
                            <v-card class="ma-0 pa-2">
                                <v-row style="position:absolute; z-index: 4; width: 100%;">
                                    <v-col sm="12" class="py-0">
                                        <transition name="fade">
                                            <div class="successAlertBox" v-if="date_success">
                                                <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                                                <span class="alertTitle">Updated!</span>
                                                <v-icon class="alertIcon" @click="date_success = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                        <transition name="fade">
                                            <div class="errorAlertBox" v-if="date_error">
                                                <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                                                <span class="alertTitle">Error!</span>
                                                <v-icon class="alertIcon" @click="invalidPathAtProduct = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                                <v-card-title class="ma-0 pa-0 selectorTitle">
                                    <v-icon color=primary class="mr-2" style="max-width:10%">mdi-calendar-range-outline</v-icon>
                                    Start Date
                                </v-card-title>
                                <v-menu
                                        ref="startDatePicker"
                                        v-model="startDatePicker"
                                        :close-on-content-click="false"
                                        :return-value.sync="selectedStartDate"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="selectedStartDate"
                                                label="Start Date"
                                                prepend-icon="mdi-calendar-search"
                                                readonly
                                                v-bind="attrs"
                                                class="selectors"
                                                v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker class='selectorCalendar' v-model="selectedStartDate" no-title scrollable :min="minStartDate" :max="maxEndDate">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="startDatePicker = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.startDatePicker.save(selectedStartDate); updateStartDate(selectedStartDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <!--<v-row class="ma-0 pa-0">-->
                                    <!--<v-col class="ma-0 pa-0" cols="4">-->
                                        <!--<v-select class="ma-0 pa-0" label="month" :value="month" :items="availableMonths" :menu-props="{top:true, height: 250, maxHeight: 250}" @change="updateDateScrolls('month',$event)"></v-select>-->
                                    <!--</v-col>-->
                                    <!--<v-col class="ma-0 pa-0" cols="4">-->
                                        <!--<v-select class="ma-0 pa-0" label="day" :value="day" :items="availableDays" :menu-props="{top:true, height: 250, maxHeight: 250}" @change="updateDateScrolls('day',$event)"></v-select>-->
                                    <!--</v-col>-->
                                    <!--<v-col class="ma-0 pa-0" cols="4">-->
                                        <!--<v-select class="ma-0 pa-0" label="year" :value="year" :items="availableYears" :menu-props="{top:true, height: 250, maxHeight: 250}" @change="updateDateScrolls('year',$event)"></v-select>-->
                                    <!--</v-col>-->
                                <!--</v-row>-->
                                <v-select class="selectors" :value="timeRange" :items="availableTimeRange" @change="updateTimeRange($event)"></v-select>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" class="my-0 py-0" style="max-height: 100%;">
                    <v-row class="ma-0 pa-0" style=" width: 100%;">
                        <v-col cols="12">
                            <v-card class="ma-0 pa-2">
                                <v-row style="position:absolute; z-index: 4; width: 100%;">
                                    <v-col sm="12" class="py-0">
                                        <transition name="fade">
                                            <div class="successAlertBox" v-if="gender_success">
                                                <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                                                <span class="alertTitle">Updated!</span>
                                                <v-icon class="alertIcon" @click="gender_success = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                        <transition name="fade">
                                            <div class="errorAlertBox" v-if="gender_error">
                                                <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                                                <span class="alertTitle">Error!</span>
                                                <v-icon class="alertIcon" @click="invalidPathAtProduct = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                                <v-card-title class="ma-0 pa-0 selectorTitle">
                                    <v-icon color=primary class="mr-2" style="max-width:10%">mdi-gender-male-female</v-icon>
                                    Gender
                                </v-card-title>
                                <v-checkbox class="selectors" v-model="gender" label="Male" value="Male" color=primary :disabled="!availableGenders.includes('Male') || true" @change="verifyConfigurationChange($event,'Gender')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="gender" label="Female" value="Female" color=primary :disabled="!availableGenders.includes('Female') || true" @change="verifyConfigurationChange($event,'Gender')"></v-checkbox>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col cols="12" style="max-height: 100%;">
                            <v-card class="ma-0 pa-2" style="max-height: 100%; overflow-y: scroll">
                                <v-row style="position:absolute; z-index: 4; width: 100%;">
                                    <v-col sm="12" class="py-0">
                                        <transition name="fade">
                                            <div class="successAlertBox" v-if="agerange_success">
                                                <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                                                <span class="alertTitle">Updated!</span>
                                                <v-icon class="alertIcon" @click="agerange_success = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                        <transition name="fade">
                                            <div class="errorAlertBox" v-if="agerange_error">
                                                <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                                                <span class="alertTitle">Error!</span>
                                                <v-icon class="alertIcon" @click="invalidPathAtProduct = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                                <v-card-title class="ma-0 pa-0 selectorTitle">
                                    <v-icon color=primary class="mr-2" style="max-width:10%">mdi-pac-man</v-icon>
                                    Age Range
                                </v-card-title>
                                <v-checkbox class="selectors" v-model="ageRange" label="5-17" value="5-14" color=primary :disabled="!availableAgeRanges.includes('5-14') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="18-24" value="15-24" color=primary :disabled="!availableAgeRanges.includes('15-24') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="25-34" value="25-34" color=primary :disabled="!availableAgeRanges.includes('25-34') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="35-44" value="35-44" color=primary :disabled="!availableAgeRanges.includes('35-44') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="45-54" value="45-54" color=primary :disabled="!availableAgeRanges.includes('45-54') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="55-64" value="55-64" color=primary :disabled="!availableAgeRanges.includes('55-64') || true" @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="65-74" value="65-74" color=primary :disabled="!availableAgeRanges.includes('65-74') || true"   @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                                <v-checkbox class="selectors" v-model="ageRange" label="75-84" value="75-84" color=primary :disabled="!availableAgeRanges.includes('75-84') || true"   @change="verifyConfigurationChange($event,'AgeRange')"></v-checkbox>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;">
                        <v-col cols="12">
                            <v-card class="ma-0 pa-2">
                                <v-row style="position:absolute; z-index: 4; width: 100%;">
                                    <v-col sm="12" class="py-0">
                                        <transition name="fade">
                                            <div class="successAlertBox" v-if="audience_success">
                                                <v-icon class="alertIcon">mdi-check-circle-outline</v-icon>
                                                <span class="alertTitle">Updated!</span>
                                                <v-icon class="alertIcon" @click="audience_success = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                        <transition name="fade">
                                            <div class="errorAlertBox" v-if="audience_error">
                                                <v-icon class="alertIcon">mdi-alert-circle-outline</v-icon>
                                                <span class="alertTitle">Error!</span>
                                                <v-icon class="alertIcon" @click="invalidPathAtProduct = false;">mdi-close</v-icon>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                                <v-card-title class="ma-0 pa-0 selectorTitle">
                                    <v-icon color=primary class="mr-2" style="max-width:10%">mdi-account-group-outline</v-icon>
                                    Behaviour
                                </v-card-title>
                                <v-radio-group v-model="audienceType" @change="updateAudienceToggle($event)">
                                    <v-radio class="selectors" label="No Behaviour" value="None"></v-radio>
                                    <v-radio class="selectors" label="Behavioural Segments" value="Behavior" :disabled="Object.keys(audienceMap).length == 0"></v-radio>
                                    <v-radio class="selectors" label="Prizm Segments" value="Prizm" :disabled="prizmList.length == 0"></v-radio>
                                    <v-radio class="selectors" label="Household Income" value="HHI" :disabled="hhiList.length == 0"></v-radio>
                                </v-radio-group>
                                <!-- old behavioral selction -->
                                <v-select v-if="audienceType == 'Behavior'" class="selectors" v-model="audienceCat" :items="Object.keys(audienceMap)" color=secondary item-color=primary @change="audienceDesc = audienceMap[audienceCat][0]; verifyConfigurationChange($event + '|Docma|' + audienceDesc, 'Audience')"></v-select>
                                <v-select v-if="audienceType == 'Behavior'" class="selectors" v-model="audienceDesc" :items="audienceMap[audienceCat]" color="secondary" item-color="primary" @change="verifyConfigurationChange(audienceCat + '|Docma|' + $event, 'Audience')"></v-select>
                                <v-select v-if="audienceType == 'Prizm'" class="selectors" v-model="audiencePrizm" :items="prizmList" color="secondary" item-color="primary" multiple @change="audiencePrizmSelect($event)">
                                    <template v-slot:selection="{item,index}">
                                        <v-chip v-if="index === 0"><span>{{item}}</span></v-chip>
                                        <span v-if="index === 1">(+{{audiencePrizm.length - 1}} other Segments)</span>
                                    </template>
                                </v-select>
                                <v-select v-if="audienceType == 'HHI'" class="selectors" v-model="audienceHHI" :items="hhiList" color="secondary" item-color="primary" multiple @change="audienceHHISelect($event)"></v-select>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import SweetSpotConfiguration from '../javascript/sweetSpotConfiguration'
    export default {
        name: "dataSelectorDrawerv3.vue",
        props: [
            'configurationTree',
            "configList",
            "configurationEditedId"
        ],
        data() {
            return {
                config: null,
                //Selected configuration variables
                configuration_name: "Configuration Name",
                operator: 'Pattison',
                market: 'Calgary LRT',
                product: 'Kong Poster',
                timeRange: '1 Week',
                discount: 0,
                selectedStartDate: (new Date(new Date() - 2*24*60*60*1000)).toISOString().substr(0,10),
                month: 0,
                day: 0,
                year: 0,
                gender: ['Male','Female'],
                ageRange: [],
                audienceCat: "",
                audienceDesc: "",
                audiencePrizm: [],
                audienceHHI: [],
                audienceType: "None",
                //Available configuration variables
                availableOperators: ['Pattison','Quebecor'],
                availableMarkets: ['Calgary LRT','Toronto Streetcar'],
                availableProducts: ['Kong Poster','Kong Poster + Headliner','Kong Poster + Extension','Kong Poster + Headliner + Extension','Mural','Train Strip','Super Train'],
                availableTimeRange: ['1 Week','2 Week','3 Week'],
                availableGenders: ['Male','Female'],
                availableAgeRanges: ['5-14','15-24','25-34'],
                audienceMap: {},
                prizmList: [],
                hhiList: [],
                availableYears: [],
                availableMonths: [],
                availableDays: [],
                minStartDate: this.selectedStartDate,
                maxEndDate: this.selectedStartDate,
                //alert variables
                num_product_success_updates: 0,
                product_success: false,
                product_error: false,
                num_date_success_updates: 0,
                date_success: false,
                date_error: false,
                num_gender_success_updates: 0,
                gender_success: false,
                gender_error: false,
                num_agerange_success_updates: 0,
                agerange_success: false,
                agerange_error: false,
                num_audience_success_updates: 0,
                audience_success: false,
                audience_error: false,
                //helper variables
                startDatePicker: false,
                market_products_dict: {
                    'Toronto Streetcar': ['Full Wrap','Half Wrap','Strips (Driver side)', 'Strips (Curbside)','Strips (Driver + Curbside)','Single Murals (Driver side)','Single Murals (Curbside)','Triple Murals','Checkerboard Murals'],
                    'Calgary LRT': ['Super Train','Strip','Strip + Headliner','Mural','Kong Poster + Extension + Headliner','Kong Poster + Extension','Kong Poster + Headliner','Kong Poster','King Poster'],
                    'Edmonton LRT': ['Super Train','Strip','Strip + Headliner','Kong Poster + Headliner','Kong Poster'],
                    'Laval Buses': ['Super Bus','King Poster', 'Side Mini-Mural', 'Side Mural', 'Rear Mural','Seventy Poster'],
                    'New York City': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Los Angeles': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Chicago': ['All','Billboard','Urban Panel'],
                    'Miami': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Tampa': ['All','Urban Panel','Bar / Liquor'],
                    'Dallas': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Atlanta': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Las Vegas': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Philidelphia': ['All','Billboard','Urban Panel','Bar / Liquor'],
                    'Indianapolis': ['All','Billboard','Urban Panel','Bar / Liquor']
                },
                market_to_market_text: {'Toronto': 'Toronto Streetcar', 'Calgary': 'Calgary LRT', 'Edmonton': 'Edmonton LRT', 'Laval': 'Laval Buses',
                                        'NYC': 'New York City', 'LA': 'Los Angeles', 'Chicago': 'Chicago', 'Miami':'Miami', 'Tampa':'Tampa', 'Dallas':'Dallas', 'Atlanta': 'Atlanta', 'Las Vegas': 'Las Vegas', 'Philly': 'Philidelphia', 'Indianapolis':'Indianapolis'},
                nameDeB: this.updateConfigurationNameDebounce(this.updateConfigurationName, 500),
                dateDeB: this.updateConfiguration()
            }
        },
        watch: {
            configList: {
                immediate: true,
                handler: function() {
                    this.configs = this.configList;
                    this.u_selected_config(this.configurationEditedId);
                },
                deep: false
            },
            configurationEditedId: {
                immediate: true,
                handler: function() {
                    this.u_selected_config(this.configurationEditedId);
                }
            }
        },
        methods: {
            u_selected_config: function(id){
                for(let config of this.configs){
                    if(config.id == id){
                        this.config = Object.assign(Object.create(Object.getPrototypeOf(config.config)), config.config);
                        this.clear_values();
                        this.u_available_values(this.config);
                        this.u_configuration(this.config);
                    }
                }
            },
            clear_values: function(){
                this.market = null,
                this.product = null,
                this.timeRange = '1 Week',
                this.discount = 0,
                this.selectedStartDate = (new Date(new Date() - 2*24*60*60*1000)).toISOString().substr(0,10),
                this.gender = [],
                this.ageRange = [],
                this.audienceCat = "",
                this.audienceDesc = "",
                this.audiencePrizm = [],
                this.audienceHHI = [],
                this.audienceType = "None"
            },
            u_configuration: function(configuration){
                let config = configuration.get_configuration();
                //Set Configuration name
                this.configuration_name = configuration.get_configuration_name();
                this.operator = config['Operator'];
                if(!this.availableMarkets.includes(this.market_to_market_text[config['Market']])){
                    this.updateMarket(this.availableMarkets[0]);
                    return 0;
                }
                else{
                    this.market = this.market_to_market_text[config['Market']];
                }
                // let product_checker = (arr,target) => target.every(v => arr.includes(v));
                if(!this.availableProducts.includes(config['Product'])){
                    this.updateProductType(this.availableProducts[0]);
                    return 0;
                }
                else{
                    this.product = config['Product'];
                }
                let oneweek = 1000 * 60 * 60 * 24 * 7;
                let config_start = new Date(config['StartDate']);
                let config_end = new Date(config['EndDate']);
                let config_date_diff = ((config_end.getTime() - config_start.getTime()) / oneweek);
                if(config_start < new Date(this.minStartDate)){
                    this.timeRange = config_date_diff + " Weeks";
                    this.selectedStartDate = new Date(this.minStartDate).toISOString().substr(0,10);
                    this.updateStartDate(new Date(this.minStartDate).toISOString().substr(0,10));
                    return 0;
                }
                // if(config_end > new Date(this.maxEndDate)) {
                //     let full_date_diff = (new Date(this.maxEndDate) - config_start) / oneweek;
                //     this.timeRange = full_date_diff + " Weeks";
                //     this.updateTimeRange(this.timeRange);
                // }

                this.timeRange = config_date_diff + " Weeks";
                this.gender = config['Gender'];
                this.ageRange = config['AgeRange'];
                if(config['Audience'] == 'None'){
                    // this.noAudienceSelected = true;
                    this.audience = 'None';
                    this.audienceType = 'None'
                }
                else {
                    // this.noAudienceSelected = false;
                    if(typeof config['Audience'] == 'string') {
                        this.audience = config['Audience'].split("|Docma|");
                        this.audienceCat = this.audience[0];
                        this.audienceDesc = this.audience[1];
                        this.audienceType = 'Behavior';
                    }
                    else{
                        let type = null;
                        if(config['Audience'].length > 0){
                            type = config['Audience'][0].split('|Docma|')[0];
                        }
                        if(type == 'Prizm') {
                            let prizmSegs = [];
                            for (let aud of config['Audience']) {
                                prizmSegs.push(aud.split("|Docma|")[1]);
                            }
                            this.audience = prizmSegs;
                            this.audiencePrizm = this.audience;
                            this.audienceType = 'Prizm';
                        }
                        else if(type == 'HHI') {
                            let prizmSegs = [];
                            for (let aud of config['Audience']) {
                                prizmSegs.push(aud.split("|Docma|")[1]);
                            }
                            this.audience = prizmSegs;
                            this.audienceHHI = this.audience;
                            this.audienceType = 'HHI';
                        }
                    }
                }
                if(Object.keys(config).includes('Discount')){
                    this.discount = config['Discount'];
                }
                else{
                    this.discount = 0;
                }
            },
            u_available_values: function(configuration){
                let config = configuration.get_configuration();
                this.availableMarkets = [];
                for(let mark of this.configurationTree.getValuesAtLevel('Market',config)){
                    this.availableMarkets.push(this.market_to_market_text[mark]);
                }
                this.availableProducts = this.market_products_dict[this.market_to_market_text[config['Market']]];
                this.availableGenders = this.configurationTree.getValuesAtLevel('Gender',config);
                this.availableAgeRanges = this.configurationTree.getValuesAtLevel('AgeRange',config);
                //Set Available Audiences
                let availAudiences = this.configurationTree.getValuesAtLevel('Audience',config);
                let noneFilter = (element) => element == 'None';
                let noneIdx = availAudiences.findIndex(noneFilter);
                availAudiences.splice(noneIdx,1);
                this.availableAudiences = availAudiences;
                this.audienceMap = this.buildAudienceMap(this.availableAudiences);
                this.prizmList = this.buildPrizmList(this.availableAudiences);
                this.hhiList = this.buildHHIList(this.availableAudiences);
                //Set Available Dates
                var dateRange = this.configurationTree.getDateRangeFromConfig(config);
                let startDate = new Date(dateRange[0]);
                let endDate = new Date(dateRange[1]);
                this.minStartDate = startDate.toISOString().substr(0,10);
                let oneweek = 1000 * 60 * 60 * 24 * 7;
                let config_start = new Date(config['StartDate']);
                this.selectedStartDate = config_start.toISOString().substr(0,10);
                let config_end = new Date(config['EndDate']);
                let config_date_diff = ((config_end.getTime() - config_start.getTime()) / oneweek);
                let full_date_diff = ((endDate.getTime() - config_start.getTime()) / oneweek);
                endDate.setDate(endDate.getDate() - (config_date_diff*7));
                this.maxEndDate = endDate.toISOString().substr(0,10);
                let startYear = startDate.getFullYear();
                let startMonth = startDate.getUTCMonth();
                let startDay = startDate.getUTCDate();
                let endYear = endDate.getFullYear();
                let endMonth = endDate.getUTCMonth();
                let endDay = endDate.getUTCDate();
                let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
                this.month = months[config_start.getUTCMonth()];
                this.day = config_start.getUTCDate();
                this.year = config_start.getFullYear();
                this.availableYears = [];
                this.availableMonths = [];
                this.availableDays = [];
                for (let y = startYear; y <= endYear; y ++){
                    this.availableYears.push(y);
                }
                let sm = 0;
                let em = 11;
                if(this.year == startYear){
                    sm = startMonth;
                }
                if(this.year == endYear){
                    em = endMonth;
                }
                for(let m = sm; m <= em; m++){
                    this.availableMonths.push(months[m]);
                }
                let sd = 1;
                let ed = new Date(this.year,config_start.getUTCMonth(),0).getUTCDate();
                if(this.year == startYear && this.month == startMonth){
                    sd = startDay;
                }
                if(this.year == endYear && this.month == endMonth){
                    ed = endDay;
                }
                for(let d = sd; d <= ed; d++){
                    this.availableDays.push(d);
                }
                this.availableTimeRange = [];
                for(let i = 1; i <= full_date_diff && i < 26; i++){
                    this.availableTimeRange.push(i + " Weeks")
                }
            },
            verifyConfigurationChange(value,field){
                var valid_value_update = this.configurationTree.verifyValuesAtTreeLevel(field, value, this.config.get_configuration());
                var curConfig = this.config.get_configuration();
                var updatedConfig = new SweetSpotConfiguration(curConfig['Market'],curConfig['Audience'],curConfig['Gender'],curConfig['AgeRange'],curConfig['StartDate'],curConfig['EndDate']);
                updatedConfig.updateField(field,value);
                var valid_path_update = this.configurationTree.checkPathFromConfig(updatedConfig.get_configuration());
                var failed_at = valid_path_update[1];
                let path_valid = valid_path_update[0];
                console.log(valid_value_update);
                console.log(path_valid);
                if(valid_value_update && path_valid){
                    this.updateConfiguration(field,value);
                }
                else if(failed_at == 'StartDate' || failed_at == 'EndDate') {
                    this.updateConfiguration(field,value);
                }
                else if(failed_at == 'Market'){
                    this.updateConfiguration(field,value);
                }
                else{
                    this.throwConfigErrorAt(field);
                    return;
                }
            },
            updateConfiguration(field,value){
                this.toggleSuccessNotification(true,field);
                var data = {'Id':this.configurationEditedId,'Field':field,'Value':value};
                this.$emit('updateConfiguration',data);
            },
            updateProductType(product){
                if(product == null){
                    this.throwConfigErrorAt("Product")
                }
                else {
                    this.product = product;
                    this.updateConfiguration('Product', this.product);
                }
            },
            updateMarket(market){
                if(market == null){
                    this.throwConfigErrorAt("Market");
                }
                else{
                    let updated_market = market;
                    updated_market = market.replace('Streetcar', '');
                    updated_market = market.replace('LRT', '');
                    if(updated_market == 'New York City'){
                        updated_market = 'NYC';
                    }
                    else if(updated_market == 'Los Angeles'){
                        updated_market = 'LA';
                    }
                    else if(updated_market == 'Philidelphia'){
                        updated_market = 'Philly';
                    }
                    this.verifyConfigurationChange(updated_market,'Market');
                }
            },
            updateStartDate(date){
                this.verifyConfigurationChange(date,'StartDate');
                this.updateTimeRange(this.timeRange);
            },
            updateTimeRange(timeRange){
                let numWeeks = parseInt(timeRange.replace(" Weeks", ""));
                let date = new Date(this.selectedStartDate);
                let oneweek = 1000 * 60 * 60 * 24 * 7;
                date.setTime(date.getTime() + (oneweek * numWeeks));
                this.verifyConfigurationChange(date.toISOString().substr(0,10), 'EndDate')
            },
            updateDiscount(discount){
                if(discount >= 0 && discount <= 100){
                    this.discount = discount;
                    this.updateConfiguration('Discount', discount);
                }
                else if(discount > 100){
                    this.discount = 100;
                    this.updateConfiguration('Discount', 100);
                }
                else{
                    this.discount = 0;
                    this.updateConfiguration('Discount', 0);
                }
            },
            updateAudienceToggle(toggle){
                if(toggle == 'None'){
                    this.verifyConfigurationChange('None', 'Audience');
                }
                else if(toggle == 'Behavior'){
                    this.audienceCat = Object.keys(this.audienceMap)[0];
                    this.audienceDesc = this.audienceMap[this.audienceCat][0];
                    this.verifyConfigurationChange(this.audienceCat + '|Docma|' + this.audienceDesc, 'Audience');
                }
                else if(toggle == 'Prizm'){
                    this.audiencePrizm = [this.prizmList[0]];
                    let prizm = [];
                    for(let val of this.audiencePrizm){
                        prizm.push('Prizm|Docma|'+val);
                    }
                    this.verifyConfigurationChange(prizm, 'Audience');
                }
                else if(toggle == 'HHI'){
                    this.audienceHHI = [this.hhiList[0]];
                    let hhi = [];
                    for(let val of this.audienceHHI){
                        hhi.push('HHI|Docma|'+val);
                    }
                    this.verifyConfigurationChange(hhi,'Audience');
                }
            },
            audiencePrizmSelect(selectedPrizmSegments){
                let prizm = [];
                for(let val of selectedPrizmSegments){
                    prizm.push('Prizm|Docma|'+val);
                }
                this.verifyConfigurationChange(prizm, 'Audience');
            },
            audienceHHISelect(selectedHHISegments){
                let hhi = [];
                for(let val of selectedHHISegments){
                    hhi.push('HHI|Docma|'+val);
                }
                this.verifyConfigurationChange(hhi,'Audience');
            },
            toggleSuccessNotification(toggle,field){
                if(field == 'Market' || field == 'Product' || field == 'Discount'){
                    this.product_error = false;
                    this.toggleProductSuccess(true);
                }
                else if(field == 'StartDate' || field == 'TimeRange' || field == 'EndDate'){
                    this.date_error = false;
                    this.toggleDateSuccess(true);
                }
                else if(field == 'Gender'){
                    this.gender_error = false;
                    this.toggleGenderSuccess(true);
                }
                else if(field == 'AgeRange'){
                    this.agerange_error = false;
                    this.toggleAgeRangeSuccess(true);
                }
                else if(field == 'Audience'){
                    this.audience_error = false;
                    this.toggleAudienceSuccess(true);
                }
            },
            toggleProductSuccess(toggle){
                if(this.product_success == false && toggle == true){
                    this.num_product_success_updates += 1;
                    this.product_success = true;
                    let vm = this;
                    setTimeout(function(){ vm.toggleProductSuccess(false)}, 1500);
                }
                else if(this.product_success == true && toggle == true){
                    this.num_product_success_updates += 1;
                }
                else if(toggle == false && this.num_product_success_updates > 1) {
                    this.num_product_success_updates -= 1;
                    let vm = this;
                    setTimeout(function(){ vm.toggleProductSuccess(false)}, 500);
                }
                else if(toggle == false && this.num_product_success_updates== 1){
                    this.num_product_success_updates = 0;
                    this.product_success = false;
                }
            },
            toggleDateSuccess(toggle){
                if(this.date_success == false && toggle == true){
                    this.num_date_success_updates += 1;
                    this.date_success = true;
                    let vm = this;
                    setTimeout(function(){ vm.toggleDateSuccess(false)}, 1500);
                }
                else if(this.date_success == true && toggle == true){
                    this.num_date_success_updates += 1;
                }
                else if(toggle == false && this.num_date_success_updates > 1) {
                    this.num_date_success_updates -= 1;
                    let vm = this;
                    setTimeout(function(){ vm.toggleDateSuccess(false)}, 500);
                }
                else if(toggle == false && this.num_date_success_updates== 1){
                    this.num_date_success_updates = 0;
                    this.date_success = false;
                }
            },
            toggleGenderSuccess(toggle){
                if(this.gender_success == false && toggle == true){
                    this.num_gender_success_updates += 1;
                    this.gender_success = true;
                    let vm = this;
                    setTimeout(function(){ vm.toggleGenderSuccess(false)}, 1500);
                }
                else if(this.date_success == true && toggle == true){
                    this.num_gender_success_updates += 1;
                }
                else if(toggle == false && this.num_gender_success_updates > 1) {
                    this.num_gender_success_updates -= 1;
                    let vm = this;
                    setTimeout(function(){ vm.toggleGenderSuccess(false)}, 500);
                }
                else if(toggle == false && this.num_gender_success_updates== 1){
                    this.num_gender_success_updates = 0;
                    this.gender_success = false;
                }
            },
            toggleAgeRangeSuccess(toggle){
                if(this.agerange_success == false && toggle == true){
                    this.num_agerange_success_updates += 1;
                    this.agerange_success = true;
                    let vm = this;
                    setTimeout(function(){ vm.toggleAgeRangeSuccess(false)}, 1500);
                }
                else if(this.agerange_success == true && toggle == true){
                    this.num_agerange_success_updates += 1;
                }
                else if(toggle == false && this.num_agerange_success_updates > 1) {
                    this.num_agerange_success_updates -= 1;
                    let vm = this;
                    setTimeout(function(){ vm.toggleAgeRangeSuccess(false)}, 500);
                }
                else if(toggle == false && this.num_agerange_success_updates== 1){
                    this.num_agerange_success_updates = 0;
                    this.agerange_success = false;
                }
            },
            toggleAudienceSuccess(toggle){
                if(this.audience_success == false && toggle == true){
                    this.num_audience_success_updates += 1;
                    this.audience_success = true;
                    let vm = this;
                    setTimeout(function(){ vm.toggleAudienceSuccess(false)}, 1500);
                }
                else if(this.audience_success == true && toggle == true){
                    this.num_audience_success_updates += 1;
                }
                else if(toggle == false && this.num_audience_success_updates > 1) {
                    this.num_audience_success_updates -= 1;
                    let vm = this;
                    setTimeout(function(){ vm.toggleAudienceSuccess(false)}, 500);
                }
                else if(toggle == false && this.num_audience_success_updates== 1){
                    this.num_audience_success_updates = 0;
                    this.audience_success = false;
                }
            },
            throwConfigErrorAt(field){
                let value = null;
                let configuration = this.config.get_configuration();
                if(field == 'Market'){
                    this.product_error = true;
                    value = configuration['Market'];
                }
                else if(field == 'Product'){
                    this.product_error = true;
                    value = configuration['Product'];
                }
                else if(field == 'EndDate'){
                    this.product_error = true;
                    value = configuration['EndDate'];
                }
                else if(field == 'TimeRange'){
                    this.product_error = true;
                    value = configuration['TimeRange'];
                }
                else if(field == 'StartDate'){
                    this.date_error = true;
                    value = configuration['StartDate'];
                }
                else if(field == 'Gender'){
                    this.gender_error = true;
                    value = configuration['Gender'];
                }
                else if(field == 'AgeRange'){
                    this.agerange_error = true;
                    value = configuration['AgeRange'];
                }
                else if(field == 'Audience'){
                    this.audience_error = true;
                    value = configuration['Audience'];
                }
                let vm = this;
                if(value != null){
                    setTimeout(function(){
                        vm.verifyConfigurationChange(value,field);
                    }, 750);
                }
            },
            buildAudienceMap(audArray){
                let audMap = {};
                for(let audience of audArray){
                    let sp = audience.split("|Docma|");
                    let cat = sp[0];
                    let desc = sp[1];
                    if(cat == 'Prizm' || cat == 'HHI'){
                        continue;
                    }
                    if(Object.keys((audMap)).includes(cat)){
                        audMap[cat].push(desc);
                    }
                    else{
                        audMap[cat] = [desc];
                    }
                }
                audMap = Object.keys(audMap).sort().reduce(
                    (obj, key) => {
                        obj[key] = audMap[key];
                        return obj;
                    },
                    {}
                );
                for(let key of Object.keys(audMap)){
                    audMap[key].sort();
                }
                return audMap;
            },
            buildPrizmList(audArray){
                let prizmList = [];
                for(let audience of audArray) {
                    let sp = audience.split("|Docma|");
                    let cat = sp[0];
                    let desc = sp[1];
                    if (cat == 'Prizm') {
                        prizmList.push(this.check_prizm_accents(desc));
                    }
                }
                prizmList.sort();
                return prizmList;
            },
            check_prizm_accents(prizm_seg){
                let seg_num = prizm_seg.split(' ')[0];
                if(seg_num == 65){
                    prizm_seg = "65 Âgés & Traditionnels";
                }
                else if(seg_num == 40){
                    prizm_seg = "40 Les Énerjeunes";
                }
                else if(seg_num == 39){
                    prizm_seg = "39 Évolution Urbaine";
                }
                else if(seg_num == 29){
                    prizm_seg = "29 C'est Tiguidou";
                }
                else if(seg_num == 27){
                    prizm_seg = "27 Diversité Nouvelle";
                }
                return prizm_seg
            },
            sortHHIList(a,b){
                a = a.replace('$','').replace('<','').replace('>','').replace(',','');
                b = b.replace('$','').replace('<','').replace('>','').replace(',','');
                if (a.split('-').length == 1 && parseInt(a) == 35000){
                    return -1;
                }
                else if (a.split('-').length == 1 && parseInt(a) == 500000){
                    return 1;
                }
                if (b.split('-').length == 1 && parseInt(b) == 35000){
                    return 1;
                }
                else if (b.split('-').length == 1 && parseInt(b) == 500000){
                    return -1;
                }
                let a_greater = parseInt(a.split('-')[0]) < parseInt(b.split('-')[0]);
                let b_greater = parseInt(a.split('-')[0]) > parseInt(b.split('-')[0]);
                if(a_greater){
                    return -1;
                }
                if(b_greater){
                    return 1;
                }
                return 0;
            },
            buildHHIList(audArray){
                let hhiList = [];
                for(let audience of audArray) {
                    let sp = audience.split("|Docma|");
                    let cat = sp[0];
                    let desc = sp[1];
                    if (cat == 'HHI') {
                        hhiList.push(desc);
                    }
                }
                hhiList.sort(this.sortHHIList);
                return hhiList;
            },
            updateConfigurationNameDebounce(callback,delay){
                let timeout;
                return (...args) => {
                    const context = this;
                    clearTimeout(timeout);
                    timeout = setTimeout(() => callback.apply(context,args),delay);
                };
            },
            updateConfigurationName(configuration_name){
                // this.config.set_configuration_name(configuration_name);
                this.updateConfiguration('Name',configuration_name)
            },
            // updateConfigurationNameDebounce(callback,delay){
            //     let timeout;
            //     return (...args) => {
            //         const context = this;
            //         clearTimeout(timeout);
            //         timeout = setTimeout(() => callback.apply(context,args),delay);
            //     };
            // },
            // updateConfigurationName(configuration_name){
            //     // this.config.set_configuration_name(configuration_name);
            //     this.updateConfiguration('Name',configuration_name)
            // }
        }
    }
</script>

<style scoped>
    .successAlertBox{
        background-color: #00c851;
        height: 100%
    }
    .errorAlertBox{
        background-color: #ff4444;
        height: 100%;
    }
    .warningAlertBox{
        background-color: #ffbb33;
        height: 100%;
    }
    .alertTitle{
        font-family: 'FuturaTMedium' !important;
        /*font-weight: 400 !important;*/
        color: white;
        width: 60%;
        float: left;
    }
    .alertIcon {
        color: white;
        width: 20%;
        float: left;
    }
</style>