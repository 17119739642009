function build_config_list(export_configurations,names,export_statistics){
    let stats_rows = Object.entries(export_statistics)
    let config_data = [];
    let i = 0;
    for (let element of export_configurations){
        if(element['id'] == 'incr'){
            continue;
        }
        let row = [];
        row.push(names[i]);
        // row.push(element['config']['_configuration']['NumberOf'] + " " + element['config']['_configuration']['Product']);
        row.push(stats_rows[i][1]['NumberOf'] + " " + element['config']['_configuration']['Product']);
        row.push(element['config']['_configuration']['StartDate'] + " - " + element['config']['_configuration']['EndDate']);
        let behavior = [];
        if(typeof element['config']['_configuration']['Audience'] === 'object'){
            let beh = "";
            for(let b = 0; b < element['config']['_configuration']['Audience'].length; b++){
                if(b + 1 == element['config']['_configuration']['Audience'].length) {
                    beh += element['config']['_configuration']['Audience'][b].split('|Docma|')[1];
                }
                else{
                    beh += element['config']['_configuration']['Audience'][b].split('|Docma|')[1] + '\n';
                }
            }
            row.push(beh);
        }
        else if(element['config']['_configuration']['Audience'] == 'None'){
            behavior = 'None';
            row.push(behavior);
        }
        else {
            behavior = element['config']['_configuration']['Audience'].split('|Docma|');
            row.push(behavior[0] + " - " + behavior[1]);
        }
        let gender = element['config']['_configuration']['Gender'];
        let ageRange = element['config']['_configuration']['AgeRange'];
        let shortenedGender = get_shortened_gender(gender);
        let shortenedAgeRange = get_shortened_age_range(ageRange);
        row.push(shortenedGender);
        row.push(shortenedAgeRange);
        config_data.push(row);
        i+=1;
    }
    return config_data
}
function build_stats_list(export_statisitcs,export_configurations){
    let statistics_data = [];
    let i = 0;
    for(let raw_data_row of Object.entries(export_statisitcs)){
        let row = [];
        let num = export_configurations[i]['config']['_configuration']['NumberOf'];
        console.log(num);
        i ++;
        row.push(raw_data_row[1]['Name']);
        row.push(formatNumber(raw_data_row[1]['TargetPop']));
        row.push(raw_data_row[1]['NumberOf']);
        row.push(formatNumber(raw_data_row[1]['OnTargetReach']));
        row.push(formatNumber((raw_data_row[1]['OnTargetReach']/raw_data_row[1]['TargetPop'])* 100) + '%');
        row.push(formatNumber(raw_data_row[1]['AvgFrequency']));
        row.push(formatNumber(raw_data_row[1]['Impressions']));
        row.push(formatNumber('$' + raw_data_row[1]['Budget']));
        row.push(formatNumber(raw_data_row[1]['GRPS']));
        row.push(raw_data_row[1]['CPM']);
        statistics_data.push(row);
    }
    return statistics_data
}
function get_shortened_gender(strGenderArray){
    if(strGenderArray.length > 1){
        return 'M & F';
    }
    else{
        return strGenderArray[0];
    }
}
function get_shortened_age_range(strAgeRangeArray){
    let youngest = 85;
    let oldest = 5;
    for(let ageRange of strAgeRangeArray){
        let ageRangeSplit = ageRange.split('-');
        let youngerSplit = parseInt(ageRangeSplit[0]);
        let olderSplit = parseInt(ageRangeSplit[1]);
        if(youngerSplit < youngest){
            youngest = youngerSplit;
        }
        if(olderSplit > oldest){
            oldest = olderSplit;
        }
    }
    if(youngest == 15){
        youngest = 18;
    }
    if(oldest == 14){
        oldest = 17;
    }
    let newRange = youngest + '-' + oldest;
    return newRange;
}
function formatNumber(number){
    return number.toLocaleString();
}
export {build_config_list, build_stats_list}