import axios from 'axios'

const api_dev = 'http://127.0.0.1:5000/flighting'
const api_base = api_dev

function build_flight(req,token){
    let api_call = api_base + '';
    let body = {
        'configs': req
    }
    let flight = axios_post_call(api_call,body,token);
    return flight;
}   
/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {'x-access-tokens': token}}).then((res)=>{
            console.log(res)
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((err)=>{
            console.log(err.response)
            if(err.response == null){
                err.response = {'err': err, 'status': 500}
            }
            reject(err.response);
        })
    })
}
// function axios_get_call(api_url,token){
//     return new Promise(function(resolve,reject){
//         axios.get(api_url, {headers: {'x-access-tokens': token}}).then((res)=>{
//             if(res.status == 200){
//                 resolve(res.data);
//             }
//             else{
//                 reject();
//             }
//         }).catch((e)=>{
//             reject(api_url+e);
//         })
//     })
// }
// function axios_get_call_with_body(api_url,data,token){
//     return new Promise(function(resolve,reject){
//         axios({url: api_url, method: 'get', params: data, headers: {'x-access-tokens': token, 'Content-Type': 'application/json'}}).then((res)=>{
//             if(res.status == 200){
//                 resolve(res.data);
//             }
//             else{
//                 reject();
//             }
//         }).catch((e)=>{
//             reject(api_url+e);
//         })
//     })
// }

export default{build_flight}