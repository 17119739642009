<template>
    <v-container style="width: 256px; max-height: 92%; overflow-y: hidden !important;" class="pt-1 mt-3">
        <v-layout text-center column>
            <v-card class="pa-1">
                <v-expansion-panels class="py-1" :popout=true :flat=true :hover=true style="max-height:85%; overflow-y: scroll;">
                    <v-expansion-panel class="ma-1">
                        <v-expansion-panel-header class="px-1 profileUpdateTitles"><v-icon color=primary class="mx-1" style="max-width:10%">mdi-lead-pencil</v-icon>Name</v-expansion-panel-header>
                        <v-row>
                                <v-col class="py-0" sm="12">
                                    <transition name="fade">
                                        <div v-if="successfulNameUpdate" style="background-color: #1F9F48; height: 100%; max-height: 100%;">
                                            <v-icon style="float: left; width: 20%;" color="white">mdi-check-circle-outline</v-icon>
                                            <span class="alertTitles" style="float: left; width:60%">Updated!</span>
                                            <v-icon style="float: left; width: 20%" color="white" @click="successfulNameUpdate = false;">mdi-close</v-icon>
                                        </div>
                                    </transition>
                                </v-col>
                            <v-col class="py-0" sm="12">
                                <v-expansion-panel-content class="px-1 py-3">
                                    <v-text-field class="pa-0 ma-0 updateSelectors" v-model="updatedNameOfUser" :placeholder="nameOfUser"></v-text-field>
                                </v-expansion-panel-content>
                            </v-col>
                        </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel class="ma-1">
                        <v-expansion-panel-header class="px-1 profileUpdateTitles"><v-icon color=primary class="mx-1" style="max-width:10%">mdi-palette</v-icon>Color</v-expansion-panel-header>
                        <v-row>
                            <v-col class="py-0" sm="12">
                                <transition name="fade">
                                    <div v-if="successfulColorUpdate" style="background-color: #1F9F48; height: 100%; max-height: 100%;">
                                        <v-icon style="float: left; width: 20%;" color="white">mdi-check-circle-outline</v-icon>
                                        <span class="alertTitles" style="float: left; width:60%">Updated!</span>
                                        <v-icon style="float: left; width: 20%" color="white" @click="successfulColorUpdate = false;">mdi-close</v-icon>
                                    </div>
                                </transition>
                            </v-col>
                            <v-col class="py-0" sm="12">
                                <v-expansion-panel-content class="pa-1">
                                    <v-color-picker style="max-width:100%;" v-model="updatedColor" mode="hexa" hide-mode-switch hide-inputs></v-color-picker>
                                </v-expansion-panel-content>
                            </v-col>
                        </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel class="ma-1">
                        <v-expansion-panel-header class="px-1 profileUpdateTitles"><v-icon color=primary class="mx-1" style="max-width:10%">mdi-account</v-icon>Icon</v-expansion-panel-header>
                        <v-row>
                            <v-col class="py-0" sm="12">
                                <transition name="fade">
                                    <div v-if="successfulIconUpdate" style="background-color: #1F9F48; height: 100%; max-height: 100%;">
                                        <v-icon style="float: left; width: 20%;" color="white">mdi-check-circle-outline</v-icon>
                                        <span class="alertTitles" style="float: left; width:60%;">Updated!</span>
                                        <v-icon style="float: left; width: 20%" color="white" @click="successfulIconUpdate = false;">mdi-close</v-icon>
                                    </div>
                                </transition>
                            </v-col>
                            <v-col class="py-0" sm="12">
                                <v-expansion-panel-content class="pa-1">
                                    <v-switch color=primary inset v-model="iconSelectAccount" append-icon="mdi-account" @change="updateIconSelectors('mdi-account')"></v-switch>
                                    <v-switch color=primary inset v-model="iconSelectCool" append-icon="mdi-emoticon-cool-outline" @change="updateIconSelectors('mdi-emoticon-cool-outline')"></v-switch>
                                    <v-switch color=primary inset v-model="iconSelectAlien" append-icon="mdi-alien-outline" @change="updateIconSelectors('mdi-alien')"></v-switch>
                                    <v-switch color=primary inset v-model="iconSelectCowboy" append-icon="mdi-cowboy" @change="updateIconSelectors('mdi-cowboy')"></v-switch>
                                    <v-switch color=primary inset v-model="iconSelectSmile" append-icon="mdi-emoticon-excited-outline" @change="updateIconSelectors('mdi-emoticon-excited-outline')"></v-switch>
                                </v-expansion-panel-content>
                            </v-col>
                        </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel class="ma-1">
                        <v-expansion-panel-header class="px-1 profileUpdateTitles"><v-icon color=primary class="mx-1" style="max-width:10%">mdi-lock-reset</v-icon>Password</v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-1">
                            <span class="passwordExplination">Click the button to send you a link to update your password</span>
                            <v-divider class="my-2"></v-divider>
                            <v-btn outlined x-large rounded dark color="primary" @click="updatePassword()"><v-icon color=priamry class="mx-1">mdi-email-send-outline</v-icon> Send Email</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row style="max-height:15%;">
                    <v-col sm="12">
                        <v-btn outlined x-large rounded dark color="primary" @click="update" style="width:70%;"><v-icon dark>mdi-lead-pencil</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
    import firebase from 'firebase';
    export default {
        name: "userProfileEdit",
        props: [
          'nameOfUser',
          'color',
          'icon'
        ],
        data () {
            return {
                updatedNameOfUser: '',
                updatedColor: '',
                updatedIcon: '',
                successfulNameUpdate: false,
                successfulColorUpdate: false,
                successfulIconUpdate: false,
                nameUpdateError: false,
                colorUpdateError: false,
                iconUpdateError: false,
                iconSelectAccount: false,
                iconSelectCowboy: false,
                iconSelectAlien:false,
                iconSelectCool: false,
                iconSelectSmile: false,
            }
        },
        mounted () {
            this.clearResults()
        },
        updated (){
        },
        computed: {
        },
        methods: {
            clearResults: function () {
                this.updatedNameOfUser = this.nameOfUser;
                this.updatedColor = this.color;
                this.updateIconSelectors(this.icon);
            },
            update: function () {
                var updatedName = this.updatedNameOfUser;
                var updatedColor = this.updatedColor;
                var updatedIcon  = this.getIconSelection();
                if(updatedName != this.nameOfUser){
                    this.updateName(updatedName);
                }
                if(updatedColor != this.color) {
                    this.updateColor(updatedColor);
                }
                if(updatedIcon != this.icon){
                    this.updateIcon(updatedIcon);
                }
            },
            updateName(updatedName){
                const currentUser = firebase.auth().currentUser;
                if (currentUser) {
                    this.loggedIn = true;
                    var user = firebase.auth().currentUser.uid;
                    var vm = this;
                    firebase.firestore().collection('users').doc(user).update({
                        'name': updatedName
                    }).then(function () {
                        vm.nameOfUser = updatedName;
                        vm.updatedNameOfUser = updatedName;
                        vm.successfulNameUpdate = true;
                        setTimeout(function(){ vm.successfulNameUpdate = false; }, 2000);
                    }).catch(function(error){
                        this.console.log(error);
                        vm.nameUpdateError = true;
                    });
                }
            },
            updateColor(updatedColor){
                const currentUser = firebase.auth().currentUser;
                if (currentUser) {
                    this.loggedIn = true;
                    var user = firebase.auth().currentUser.uid;
                    var vm = this;
                    firebase.firestore().collection('users').doc(user).update({
                        'profileColor': updatedColor
                    }).then(function () {
                        vm.color = updatedColor;
                        vm.updatedColor = updatedColor;
                        vm.successfulColorUpdate = true;
                        setTimeout(function(){ vm.successfulColorUpdate = false; }, 2000);
                    }).catch(function(error){
                        this.console.log(error);
                        vm.colorUpdateError = true;
                    });
                }
            },
            updateIcon(updatedIcon){
                const currentUser = firebase.auth().currentUser;
                if (currentUser) {
                    this.loggedIn = true;
                    var user = firebase.auth().currentUser.uid;
                    var vm = this;
                    firebase.firestore().collection('users').doc(user).update({
                        'profileIcon': updatedIcon
                    }).then(function () {
                        vm.icon = updatedIcon;
                        vm.updatedIcon = updatedIcon;
                        vm.updateIconSelectors(updatedIcon);
                        vm.successfulIconUpdate = true;
                        setTimeout(function(){ vm.successfulIconUpdate = false; }, 2000);
                    }).catch(function(error){
                        this.console.log(error);
                        vm.iconUpdateError = true;
                    });
                }
            },
            updateIconSelectors(icon){
                switch(icon){
                    case 'mdi-account':
                        this.iconSelectAccount = true;
                        this.iconSelectAlien = false;
                        this.iconSelectCool = false;
                        this.iconSelectCowboy = false;
                        this.iconSelectSmile = false;
                        break;
                    case 'mdi-alien':
                        this.iconSelectAccount = false;
                        this.iconSelectAlien = true;
                        this.iconSelectCool = false;
                        this.iconSelectCowboy = false;
                        this.iconSelectSmile = false;
                        break;
                    case 'mdi-emoticon-cool-outline':
                        this.iconSelectAccount = false;
                        this.iconSelectAlien = false;
                        this.iconSelectCool = true;
                        this.iconSelectCowboy = false;
                        this.iconSelectSmile = false;
                        break;
                    case 'mdi-cowboy':
                        this.iconSelectAccount = false;
                        this.iconSelectAlien = false;
                        this.iconSelectCool = false;
                        this.iconSelectCowboy = true;
                        this.iconSelectSmile = false;
                        break;
                    case 'mdi-emoticon-excited-outline':
                        this.iconSelectAccount = false;
                        this.iconSelectAlien = false;
                        this.iconSelectCool = false;
                        this.iconSelectCowboy = false;
                        this.iconSelectSmile = true;
                        break;
                    default:
                        this.iconSelectAccount = false;
                        this.iconSelectAlien = false;
                        this.iconSelectCool = false;
                        this.iconSelectCowboy = false;
                        this.iconSelectSmile = true;
                        break;
                }
            },
            getIconSelection(){
                if(this.iconSelectAccount){
                    return 'mdi-account';
                }
                else if(this.iconSelectAlien){
                    return 'mdi-alien';
                }
                else if(this.iconSelectCool){
                    return 'mdi-emoticon-cool-outline';
                }
                else if(this.iconSelectCowboy){
                    return 'mdi-cowboy';
                }
                else if(this.iconSelectSmile){
                    return 'mdi-emoticon-excited-outline';
                }
            },
            updatePassword(){
                const currentUser = firebase.auth();
                if(currentUser){
                    const email = currentUser.currentUser.email;
                    currentUser.sendPasswordResetEmail(email).then(function() {
                        // Email sent.
                    }).catch(function(error) {
                        this.console.log(error);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700,800,900&display=swap');
    .alertTitles{
        font-family: 'FuturaTMedium' !important;
        color: white;
    }
    .fade-enter-active{
        animation: fade-in .5s;
    }
    .fade-leave-active{
        animation: fade-in .5s reverse;
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
</style>