<template>
    <v-card class="chart-wrapper pa-3" style="margin: 1%;" v-if="loading">
        <v-row class="pa-0 ma-0" justify="center" align="center" style="height: 100%; width: 100%;">
            <v-col>
                <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
            </v-col>
        </v-row>
    </v-card>
    <v-card id="frequencyDistributionGraph" class="chart-wrapper pa-3" style="margin: 1%;" v-else>
        <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
                <v-icon style="position: absolute; right: 12px; top: 12px; z-index: 2;" color="primary" @click="view_distribution=!view_distribution" v-bind="attrs" v-on="on">mdi-rotate-3d-variant</v-icon>
            </template>
            <span class="help-tooltip" v-if="view_distribution">View Average Frequency</span>
            <span class="help-tooltip" v-if="!view_distribution">View Frequency Distribution</span>
        </v-tooltip>
        <GChart
                :settings="{ packages: ['corechart', 'table'] }"
                style="width: 100%; height: 100%; margin: 0"
                type="AreaChart"
                :data="chartData"
                :options="chartOptions"
                ref="frequencyDistrubution"
                v-if="view_distribution"/>
        <GChart 
                :settings="{ packages: ['corechart', 'table'] }"
                style="width: 100%; height: 100%; margin: 0"
                type="ColumnChart"
                :data="avgData"
                :options="columnChartOptions"
                ref="frequencyDistrubution"
                v-if="!view_distribution"/>
    </v-card>
</template>
<script>
    export default {
        name: 'frequencyDistrubution',
        props: [
            'selected_budget',
            'data',
            'loading',
            'display',
            'dataManager',
            'get_pdf',
            'get_pptx'
        ],
        data () {
            return {
                chartDataHeaders: ['frequency', 'number of occurrences'],
                chartData: [],
                chartOptions: {
                    title: 'Frequency Distribution',
                    titleTextStyle: {fontName: this.$configuration.get_config_param('graph_title_font'), fontSize: 16, bold: false},
                    subtitle: 'in millions of dollars (USD)',
                    colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
                    vAxes: { 0:{title: 'Number of Occurrences', titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 14, italic: false}, textStyle:{fontName: this.$configuration.get_config_param('graph_text_font'), fontSize: 12}}},
                    vAxis: {minValue: 0, maxValue: 0, viewWindowMode: 'explicit', viewWindow:{min:0,max:0}, gridlines:{}, minorGridlines:{count: 0}},
                    hAxes: { 0:{title: 'Frequency', titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 14, italic: false}, textStyle:{fontName: this.$configuration.get_config_param('graph_text_font'), fontSize: 12}}},
                    hAxis: {minValue: 0, maxValue: 40, viewWindow:{min:0, max:40}, gridlines:{count: 0}, minorGridlines:{count: 0}},
                    chartArea: {left: '12.5%', top: '10%', width: '80%', height: '80%'},
                    curveType: 'function',
                    pointSize: 0,
                    legend: {position: 'none'},
                    backgroundColor: 'none',
                    tooltip: {isHtml: true},
                },                
                columnChartOptions: {
                    title: 'Average Frequency',
                    titleTextStyle: {fontName: this.$configuration.get_config_param('graph_title_font'), fontSize: 16, bold: false},
                    subtitle: 'in millions of dollars (USD)',
                    colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
                    vAxes: { 0:{title: 'Average Frequency', titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 14, italic: false}, textStyle:{fontName: this.$configuration.get_config_param('graph_text_font'), fontSize: 12}}},
                    vAxis: {minValue: 0, maxValue: 0, viewWindowMode: 'explicit', viewWindow:{min:0,max:0}, gridlines:{}, minorGridlines:{count: 0}},
                    hAxes: { 0:{textStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 14}}},
                    hAxis: {gridlines:{count: 0}, minorGridlines:{count: 0}},
                    legend: { position: 'none'},
                    chartArea: {left: '12.5%', top: '10%', width: '80%', height: '80%'},
                    curveType: 'function',
                    pointSize: 0,
                    backgroundColor: 'none',
                    annotations: {
                        textStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 18}
                    },
                    tooltip: {trigger: 'none'}
                },                
                view_distribution: true,
                display_data: false,
                colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
                COUNT_ABBRS: [ '', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y' ]
            }
        },
        watch: {
            selected_budget: {
                handler: function() {
                    // this.chartOptions.title = 'Scaled Frequency of Unique Reach For a Budget of $' + this.selected_budget;
                    if(this.view_distribution){
                        this.compute_data();
                    }
                    else{
                        this.compute_data_avg_freq();
                    }
                }
            },
            loading: {
                handler: function(){
                    if(this.loading == false){
                        if(this.view_distribution){
                            this.compute_data();
                        }
                        else{
                            this.compute_data_avg_freq();
                        }
                    }
                },
                immediate: true
            },
            get_pdf: {
                handler: function () {
                    if (this.get_pdf == true) {
                        this.download('pdf');
                    }
                }
            },
            get_pptx: {
                handler: function () {
                    if (this.get_pptx == true) {
                        this.download('pptx');
                    }
                }
            },
            view_distribution: {
                handler: function () {
                    if(this.view_distribution){
                        this.compute_data();
                    }
                    else{
                        this.compute_data_avg_freq();
                    }
                }
            }
        },
        mounted(){
            if(this.view_distribution){
                this.compute_data();
            }
            else{
                this.compute_data_avg_freq();
            } 
        },
        methods: {
            compute_data: function () {
                let data = [[0,0]];
                let configNames = [];
                let max_num_occ = 0;
                if(this.dataManager != null){
                    data = this.dataManager.getFreqDistDataArrayAtMaxBudget();
                    configNames = this.dataManager.getDataNames(true);
                    max_num_occ = this.dataManager.getMaxNumOccurences();
                }
                let chartHeader = [];
                chartHeader.push('frequency');
                if(configNames.length == 0){
                    chartHeader.push('num Occurrences');
                }
                for(let configName of configNames){
                    chartHeader.push(configName['name'].toString());
                    chartHeader.push({"type": "string","role": "tooltip","p":{"html":true}});
                }
                var drow = [];
                for(var i = 0; i < data.length; i++){
                    var row = [];
                    for(var j = 0; j < (configNames.length + 1); j++){
                        row.push(data[i][j]);
                        if(j > 0 && data[i][j] != null){
                            row.push(this.toolTip(configNames[j-1]['name'],data[i][0],data[i][j]));
                        }
                        else if(j > 0 && data[i][j] == null){
                            row.push(this.emptyTooltip());
                        }
                    }
                    drow.push(row);
                } 
                this.chartOptions.vAxis.maxValue = max_num_occ;
                this.chartOptions.vAxis.viewWindow.max = max_num_occ;
                this.chartData = [chartHeader, ...drow]
                this.$emit('readyToDisplay')
            },
            compute_data_avg_freq: function () {
                let data = [[0,0]];
                let configNames = [];
                if(this.dataManager != null){
                    data = this.dataManager.getAllDataStatisticsAtVisibleBudget();
                    configNames = this.dataManager.getDataNames(true);
                }
                let chartHeader = [];
                chartHeader.push('Scenario');
                chartHeader.push('Avg Frequency');
                chartHeader.push({role: 'style'})
                chartHeader.push({role: 'annotation'})
                var drow = [];
                let max = 0;
                let val = 0;
                for(var i = 0; i < configNames.length; i++){
                    var row = [];
                    row.push(configNames[i]['name'])
                    val = parseFloat(data[configNames[i]['id']]['AvgFrequency'])
                    row.push(val);
                    row.push(this.colors[i])
                    row.push(val);
                    drow.push(row);
                    if(val > max){
                        max = val;
                    }
                }
                this.columnChartOptions.vAxis.maxValue = (2 * max);
                this.columnChartOptions.vAxis.viewWindow.max = (2 * max);
                this.avgData = [chartHeader, ...drow]
            },
            toolTip: function(configurationName,freq,num_occ){
                let tooltip_html = '<div style="padding: 5px">';
                tooltip_html += '<table style="max-width: 15vw; word-wrap: break-word">';
                tooltip_html += '<tr><th class="tooltip_title" style="text-align: center">'+configurationName+'</th></tr>';
                tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">'+freq+'</td></tr>';
                tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">'+num_occ.toLocaleString("en-US")+'</td></tr>';
                tooltip_html += '</table>';
                tooltip_html += '</div>';
                return tooltip_html;
            },
            emptyTooltip: function () {
                let tooltip_html = '<div></div>';
                return tooltip_html;
            },
            download: function(type){
                let chart = this.$refs.frequencyDistrubution.chartObject;
                let chartImage = chart.getImageURI();
                let width = document.getElementById('frequencyDistributionGraph').offsetWidth;
                let height = document.getElementById('frequencyDistributionGraph').offsetHeight;
                let returnedObject = {'name':'frequency_distrubution','data':{'image':chartImage,'width':width,'height':height}};
                if(type == 'pptx') {
                    this.$emit('pptx_downloaded', returnedObject)
                }
                else if(type == 'pdf'){
                    this.$emit('pdf_downloaded',returnedObject);
                }
            }
        }
    }
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:400');
    .chart-wrapper{
        width:98%;
        height:98%;
    }
</style>