const config = {
    'save': false,
    'can_switch': false,
    'ad_recall': false,
    'font_path': 'quebecor',
    'graph_title_font': 'GothamMedium',
    'graph_axis_title_font': 'GothamMedium',
    'graph_text_font': 'GothamBook',
    'primary': '#64a7dc',
    'secondary': '#add4f1',
    'third': '#000000',
    'main_logo_path': './assets/quebecor-logo.svg',
    'singleOperator': true
}
export default {config};