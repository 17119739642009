<template>
    <v-container style="min-width: calc(50vw - 86px); max-width: calc(100vw - 86px); width: auto; height: 100%;" class="py-1">
        <v-layout column style="width:100%; height: 100%;">
            <v-row>
                <v-col>
                    <v-card style="width: calc(50vw - 110px); height:100%; overflow-y: scroll">
                        <SweetSpotError :errorTrigger="add_new_project_error" errorMsg="There was an error adding your new project. Please try again." v-on:close="add_new_project_error=false"></SweetSpotError>
                        <v-list one-line style="max-height: 80%; min-height: 80%; width:90%; margin-left: 5%" class="py-3">
                            <v-row class="ma-0 pa-0" v-if="save_access">
                                <v-col  class="ma-0 pa-0" cols="auto">
                                    <v-subheader class="save_project_name" v-if="projectName == null">Untitled Project</v-subheader>
                                    <v-subheader class="save_project_name" v-if="projectName != null">{{projectName}}</v-subheader>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col  class="ma-0 pa-0" cols="auto">
                                    <v-tooltip bottom open-delay="500">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn @click="saveNewProject" rounded color=primary v-bind="attrs" v-on="on" :disabled="!unsavedChanges"><v-icon>mdi-content-save-all</v-icon></v-btn>
                                        </template>
                                        <span class="help-tooltip">Save Project</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
             
                            <v-list-item v-for="config in configs" :key="config.id">
                                <v-list-item-avatar>
                                    <v-icon v-if="!checkIfSelected(config.id)" @click="toggleSelectedConfigs(config.id)" color="primary">mdi-circle-outline</v-icon>
                                    <v-icon v-else @click="toggleSelectedConfigs(config.id)" color="primary">mdi-circle-slice-8</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="config_display_name">{{config.name}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-tooltip bottom open-delay="500">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon @click="viewSelectedConfig(config.id)" color="primary" v-bind="attrs" v-on="on" v-if="selectedConfigInfoId != config.id || !viewConfigInfo">mdi-book-information-variant</v-icon>
                                            <v-icon @click="viewSelectedConfig(config.id)" color="primary" v-bind="attrs" v-on="on" v-else>$book_open_outline</v-icon>
                                        </template>
                                        <span class="help-tooltip">View Configuration</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-tooltip bottom open-delay="500">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon @click="editSelectedConfig(config.id)" color="primary" v-bind="attrs" v-on="on">$edit_outline</v-icon>
                                        </template>
                                        <span class="help-tooltip">Edit Configuration</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                        <v-row style="min-height: 10%; max-height: 10%">
                            <v-col cols="12" align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="runSelectedConfigs" rounded color=primary :disabled="!runConfigEnabled" v-bind="attrs" v-on="on"><v-icon>mdi-play-outline</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Run Scenario(s)</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-left:10%; margin-right:10%;" color="secondary"></v-divider>
                        <!--<v-row style="max-height: 15%" no-gutters class="ma-3">
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="saveSelectedFiles" rounded color=primary v-bind="attrs" v-on="on"><v-icon>mdi-content-save</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Save Selected Scenario(s)</span>
                                </v-tooltip>
                            </v-col>
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="saveProject" rounded color=primary v-bind="attrs" v-on="on"><v-icon>mdi-content-save-all</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Save Project</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-left:10%; margin-right:10%;" color="secondary"></v-divider>-->
                        <v-row style="max-height: 10%" no-gutters class="ma-3">
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn rounded @click="addNewConfig" color=primary :disabled="!addConfigEnabled" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Add Scenario</span>
                                </v-tooltip>
                            </v-col>
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn rounded @click="duplicateConfig" color=primary :disabled="!duplicateConfigEnabled" v-bind="attrs" v-on="on"><v-icon>mdi-content-duplicate</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Duplicate Scenario</span>
                                </v-tooltip>
                            </v-col>
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="deleteSelectedConfigs" rounded color=primary :disabled="!deleteConfigEnabled" v-bind="attrs" v-on="on"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Delete Scenario(s)</span>
                                </v-tooltip>
                            </v-col>
                            <v-col align-self="center">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn rounded @click="undoDelete" color=primary :disabled="!undoDeleteEnabled" v-bind="attrs" v-on="on"><v-icon>mdi-undo</v-icon></v-btn>
                                    </template>
                                    <span class="help-tooltip">Undo Delete</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-left:10%; margin-right:10%;" color="third"></v-divider>
                    </v-card>
                </v-col>
                <v-col v-if="viewConfigInfo">
                    <v-card style="width: calc(50vw - 24px); height:100%">
                        <v-simple-table class="pa-1" style="min-width: 100%; width: 100%; height: 100%;" dense>
                            <thead>
                                <tr>
                                    <th class="text-left">{{selectedConfigName}}</th>
                                    <th class="text-right"><v-icon @click="viewSelectedConfig(selectedConfigInfoId)">mdi-close</v-icon></th>
                                </tr>
                                <tr>
                                    <th class="text-left configTitle">Scenario</th>
                                    <th class="text-left configTitle">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left configTitle">Market</td>
                                    <td class="text-left configData">{{selectedConfigInfo['Market']}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left configTitle">Product</td>
                                    <td class="text-left configData">{{selectedConfigInfo['Product']}}</td>
                                </tr>
                                <tr v-if="Object.keys(selectedConfigInfo).includes('Discount') && selectedConfigInfo['Discount'] != 0">
                                <td class="text-left configTitle">Discount</td>
                                <td class="text-left configData">{{selectedConfigInfo['Discount']}}</td>
                                </tr>
                                <tr>
                                <td class="text-left configTitle">Time In Market</td>
                                <td class="text-left configData">{{getDifference(selectedConfigInfo['StartDate'],selectedConfigInfo['EndDate'])}} Weeks</td>
                                </tr>
                                <tr>
                                <td class="text-left configTitle">Start Date</td>
                                <td class="text-left configData">{{selectedConfigInfo['StartDate']}}</td>
                                </tr>
                                <tr>
                                <td class="text-left configTitle">End Date</td>
                                <td class="text-left configData">{{selectedConfigInfo['EndDate']}}</td>
                                </tr>
                                <!--<tr v-if="selectedConfigInfo['Location'].length < 18">-->
                                <!--<td class="text-left">Location</td>-->
                                <!--<td class="text-left" v-for="loc in getFirstValue(selectedConfigInfo['Location'])" :key="loc.key">{{loc}}</td>-->
                                <!--</tr>-->
                                <!--<tr v-if="selectedConfigInfo['Location'].length == 18">-->
                                <!--<td class="text-left">Location</td>-->
                                <!--<td class="text-left">All Locations Selected</td>-->
                                <!--</tr>-->
                                <!--<tr v-for="loc in getAllButFirst(selectedConfigInfo['Location'])" :key="loc.key">-->
                                <!--<td v-if="selectedConfigInfo['Location'].length < 18"></td>-->
                                <!--<td class="text-left" v-if="selectedConfigInfo['Location'].length < 18">{{loc}}</td>-->
                                <!--</tr>-->
                                <tr>
                                <td class="text-left configTitle">Gender</td>
                                <td class="text-left configData" v-for="gender in getFirstValue(selectedConfigInfo['Gender'])" :key="gender.key">{{gender}}</td>
                                </tr>
                                <tr v-for="gender in getAllButFirst(selectedConfigInfo['Gender'])" :key="gender.key">
                                <td></td>
                                <td class="text-left configData">{{gender}}</td>
                                </tr>
                                <tr>
                                <td class="text-left configTitle">Age Range</td>
                                <td class="text-left configData" v-for="ageRange in getYoungestAge(selectedConfigInfo['AgeRange'])" :key="ageRange.key">{{ageRange}}</td>
                                </tr>
                                <tr v-for="ageRange in getSortedAgeRemaining(selectedConfigInfo['AgeRange'])" :key="ageRange.key">
                                <td class="text-left"></td>
                                <td class="text-left configData">{{ageRange}}</td>
                                </tr>
                                <tr v-if="typeof selectedConfigInfo['Audience'] == 'string'">
                                <td class="text-left configTitle">Behaviour</td>
                                <td class="text-left configData">{{getAudienceCategory(selectedConfigInfo['Audience'])}}</td>
                                </tr>
                                <tr v-if="typeof selectedConfigInfo['Audience'] == 'string' && selectedConfigInfo['Audience'] != 'None'">
                                <td class="text-left"></td>
                                <td class="text-left configData">{{getAudienceDescription(selectedConfigInfo['Audience'])}}</td>
                                </tr>
                                <tr v-if="typeof selectedConfigInfo['Audience'] == 'object'">
                                <td class="text-left configTitle">Behaviour</td>
                                <td class="text-left configData" v-for="prizm in getFirstValue(selectedConfigInfo['Audience'])" :key="prizm.key">{{prizm.split('|Docma|')[1]}}</td>
                                </tr>
                                <tr v-for="prizm in getAllButFirst(selectedConfigInfo['Audience'])" :key="prizm.key">
                                <td></td>
                                <td class="text-left configData">{{prizm.split('|Docma|')[1]}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-overlay v-model="save_new_project_overlay">
                <v-card class="pa-3">
                    <SweetSpotError :errorTrigger="add_new_project_error" errorMsg="There was an error adding your new project. Please try again." v-on:close="add_new_project_error=false"></SweetSpotError>
                    <SweetSpotWarning :warningTrigger="add_new_project_bad_name" warningMsg="Project Exists. Please add a project with a new name." v-on:close="add_new_project_bad_name=false"></SweetSpotWarning>
                    <v-row class="ma-0 pa-0" style="width: 100%">
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="ma-0 pa-0">
                            <v-icon @click="save_new_project_overlay = false">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" style="width: 100%">
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="ma-0 pa-0">
                            <v-card-title class="save_overlay_title">Save New Project</v-card-title>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-text-field class="save_overlay_input" v-model="save_new_project_name" label="Project Name"></v-text-field>
                    <v-select class="save_overlay_input" v-model="save_new_project_directory" :items="save_new_project_directories" label="directory" item-value="0" item-text="1"></v-select>
                    <v-btn class="save_overlay_action" @click="confirmSaveNewProject" :disabled="save_new_project_directory == null || save_new_project_name == ''">Save</v-btn>
                </v-card>
            </v-overlay>
        </v-layout>
    </v-container>
</template>

<script>
    import SweetSpotConfiguration from "../javascript/sweetSpotConfiguration";
    import api_caller from "../javascript/save_api_caller";
    import SweetSpotError from "./alerts/SweetSpotError";
    import SweetSpotWarning from "./alerts/SweetSpotWarning";
    export default {
        name: "configurationView",
        props: [
            "configList",
            "undoAvailable",
            "projectName",
            "unsavedChanges",
            "resetSelectedConfigs"
        ],
        components: {
            SweetSpotError,
            SweetSpotWarning
        },
        data(){
            return {
                configs: [],
                selectedConfigIds: [],
                selectedConfigInfo: new SweetSpotConfiguration(),
                selectedConfigInfoId: 1,
                selectedConfigName: "",
                addConfigEnabled: true,
                duplicateConfigEnabled: true,
                deleteConfigEnabled: false,
                undoDeleteEnabled: false,
                runConfigEnabled: false,
                viewConfigInfo: false,
                options: {
                    handle: '.handle',
                    filter: '.disabled'
                },
                save_access: false,
                save_new_project_overlay: false,
                save_new_project_directories: [],
                save_new_project_name: '',
                save_new_project_directory: null,
                add_new_project_bad_name: false,
                add_new_project_error: false,
                max_configurations_enabled: 10
            }
        },
        mounted(){
            this.toggleSelectedConfigs("1");
            this.save_access = this.$can_save.get_val();
        },
        watch:{
            configList: {
                immediate: true,
                handler: function() {
                    this.configs = this.configList;
                    if(this.viewConfigInfo){
                        this.$emit('ViewInfoConfiguration', 0);
                    }
                    this.viewConfigInfo = false;
                    if(this.resetSelectedConfigs){
                        this.selectedConfigIds = [];
                        for(let config of this.configs){
                            this.toggleSelectedConfigs(config.id);
                        }
                        this.$emit('selectedReset');
                    }
                    this.toggleAvailableActions();
                },
                deep: true
            },
            undoAvailable: {
                immediate: true,
                handler: function(){
                    this.toggleAvailableActions();
                }
            }
        },
        methods: {
            addNewConfig(){
                if(this.configs.length < this.max_configurations_enabled){
                    this.$emit('addConfig');
                }
                this.toggleAvailableActions();
            },
            duplicateConfig(){
                let numSelected  = this.selectedConfigIds.length;
                if(this.configs.length < this.max_configurations_enabled && numSelected == 1){
                    this.$emit('duplicateConfig',this.selectedConfigIds[0])
                }
                this.toggleAvailableActions();
            },
            deleteSelectedConfigs(){
                let numSelected = this.selectedConfigIds.length;
                let numConfigs = this.configs.length;
                if(this.selectedConfigIds.includes(this.selectedConfigInfoId)){
                    this.viewSelectedConfig(this.selectedConfigInfoId);
                }
                if((numConfigs - numSelected) == 1){
                    this.$emit('deleteConfigs', this.selectedConfigIds);
                    this.selectedConfigIds = [];
                }
                else if((numConfigs - numSelected) > 1){
                    this.$emit('deleteConfigs', this.selectedConfigIds);
                    this.selectedConfigIds = [];
                }
                this.toggleAvailableActions();
            },
            toggleSelectedConfigs(id){
                if(this.selectedConfigIds.includes(id)){
                    let idx = this.selectedConfigIds.indexOf(id);
                    this.selectedConfigIds.splice(idx,1);
                }
                else{
                    this.selectedConfigIds.push(id);
                }
                this.toggleAvailableActions();
            },
            runSelectedConfigs(){
                let ids = [...this.selectedConfigIds];
                ids.sort();
                this.$emit('runConfigurations',ids);
            },
            viewSelectedConfig(id){
                let old_id = this.selectedConfigInfoId;
                for(var config of this.configs){
                    if(config.id == id){
                        this.selectedConfigInfo = config.config.get_configuration();
                        this.selectedConfigInfoId = id;
                        this.selectedConfigName = config.name;
                    }
                }
                if(this.viewConfigInfo) {
                    if(id == old_id){
                        this.$emit('ViewInfoConfiguration', id);
                        this.viewConfigInfo = false;
                    }
                }
                else{
                    this.viewConfigInfo = true;
                    this.$emit('ViewInfoConfiguration',id);
                }

            },
            editSelectedConfig(id){
                this.$emit('EditConfiguration',id);
            },
            undoDelete(){
                this.$emit('undoDelete');
            },
            saveNewProject: async function(){
                if(this.projectName != null){
                    this.$emit('saveProject');
                    return;
                }
                let directories = await api_caller.get_directories(this.$token.get_val()).then((res)=>{
                    return res;
                }).catch((err) => {
                    console.log(err)
                    return false;
                });
                if(directories == false){
                    this.add_new_project_error = true;
                    return;
                }
                this.save_new_project_directories = directories;
                this.save_new_project_overlay = true;
            },
            confirmSaveNewProject: async function(){
                let body = {
                    'directory_id': this.save_new_project_directory,
                    'project_name': this.save_new_project_name
                }
                let valid = await api_caller.add_new_project(body,this.$token.get_val()).then((resp)=>{
                    return resp['project_id'];
                }).catch((error)=>{
                    console.error(error)
                    if(error.status == 400){
                        this.add_new_project_bad_name = true;
                    }
                    else{
                        this.add_new_project_erorr = true;
                    }
                    return false;
                });
                if(!valid){
                    return;
                }
                let resp = {
                    'Name': this.save_new_project_name,
                    'Id': valid
                }
                this.add_new_project_erorr = false;
                this.add_new_project_bad_name = false;
                this.save_new_project_name = '';
                this.save_new_project_directory = null;
                this.save_new_project_overlay = false;
                this.$emit('saveNewProject',resp);
            },
            toggleAvailableActions(){
                let numSelected = this.selectedConfigIds.length;
                let numConfigs = this.configs.length;
                let diff = numConfigs - numSelected;
                if(numConfigs < this.max_configurations_enabled && numConfigs >= 0){
                    this.enableAddConfig();
                }
                else if(numConfigs >= this.max_configurations_enabled || numConfigs < 0){
                    this.disableAddConfig();
                }
                if(numSelected == 1 && numConfigs < this.max_configurations_enabled){
                    this.enableDuplicateConfig();
                }
                else{
                    this.disableDuplicateConfig();
                }

                if(numSelected == 0){
                    this.disableDeleteConfig();
                    this.disableRunConfig();
                }
                else if(diff == 0){
                    this.enableRunConfig();
                    this.disableDeleteConfig();
                }
                else if(diff == 1){
                    this.enableDeleteConfig();
                    this.enableRunConfig();
                }
                else{
                    this.enableDeleteConfig();
                    this.enableRunConfig();
                }

                if(this.undoAvailable == true && numConfigs < this.max_configurations_enabled){
                    this.enableUndoConfig();
                }
                else{
                    this.disableUndoConfig();
                }
            },
            disableAddConfig(){
                this.addConfigEnabled = false;
            },
            disableDeleteConfig(){
                this.deleteConfigEnabled = false;
            },
            enableAddConfig(){
                this.addConfigEnabled = true;
            },
            enableDeleteConfig(){
                this.deleteConfigEnabled = true;
            },
            enableRunConfig(){
                this.runConfigEnabled = true;
            },
            disableRunConfig(){
                this.runConfigEnabled = false;
            },
            enableDuplicateConfig(){
                this.duplicateConfigEnabled = true;
            },
            disableDuplicateConfig(){
                this.duplicateConfigEnabled = false;
            },
            enableUndoConfig(){
                this.undoDeleteEnabled = true;
            },
            disableUndoConfig(){
                this.undoDeleteEnabled = false;
            },
            updateEnabledConfigSelection(id){
                this.selectedConfigId = id;
            },
            getFirstValue(arr){
                if(typeof arr == 'object' && arr != null && arr.length > 0) {
                    let tmpArr = [...arr];
                    if(tmpArr[0].split('|Docma|').length > 1 && tmpArr[0].split('|Docma|')[0] == 'HHI'){
                        tmpArr.sort(this.sortHHIList);
                    }
                    var first = tmpArr.slice(0, 1);
                    return first
                }
                return []
            },
            getAllButFirst(arr){
                if(typeof arr == 'object' && arr != null && arr.length > 1) {
                    let tmpArr = [...arr];
                    if(tmpArr[0].split('|Docma|').length > 1 && tmpArr[0].split('|Docma|')[0] == 'HHI'){
                        tmpArr.sort(this.sortHHIList);
                    }
                    var removed = tmpArr.slice(1, tmpArr.length);
                    return removed;
                }
                return []
            },
            sortHHIList(a,b){
                a = a.replaceAll('$','').replace('<','').replace('>','').replace(/,/g,'').replace('HHI|Docma|','');
                b = b.replaceAll('$','').replace('<','').replace('>','').replace(/,/g,'').replace('HHI|Docma|','');
                if (a.split('-').length == 1 && parseInt(a) == 35000){
                    return -1;
                }
                else if (a.split('-').length == 1 && parseInt(a) == 500000){
                    return 1;
                }
                if (b.split('-').length == 1 && parseInt(b) == 35000){
                    return 1;
                }
                else if (b.split('-').length == 1 && parseInt(b) == 500000){
                    return -1;
                }
                let a_greater = parseInt(a.split('-')[0]) < parseInt(b.split('-')[0]);
                let b_greater = parseInt(a.split('-')[0]) > parseInt(b.split('-')[0]);
                if(a_greater){
                    return -1;
                }
                if(b_greater){
                    return 1;
                }
                return 0;
            },
            getAudienceCategory(audience){
                if(audience != null && typeof audience == 'string'){
                    return audience.split('|Docma|')[0]
                }
                return ""
            },
            getAudienceDescription(audience){
                if(audience != null && typeof audience == 'string'){
                    return audience.split('|Docma|')[1]
                }
                return ""
            },
            checkIfSelected(id){
                if(id != null && this.selectedConfigIds.includes(id)){
                    return true
                }
                else{
                    return false;
                }
            },
            getDifference(startDate, endDate) {
                let oneweek = 1000 * 60 * 60 * 24 * 7;
                let start = new Date(startDate);
                let end = new Date(endDate);
                let config_date_diff = ((end.getTime() - start.getTime()) / oneweek);
                return config_date_diff;
            },
            getYoungestAge(ageRange){
                if(ageRange != null && ageRange.length > 0) {
                    let tmp = ageRange.slice();
                    tmp.sort((a, b) => a.split('-')[0] - b.split('-')[0]);
                    if(tmp[0] == "5-14"){
                        return ["5-17"];
                    }
                    else if(tmp[0] == "15-24"){
                        return ["18-24"];
                    }
                    else {
                        return [tmp[0]];
                    }
                }
                return [];
            },
            getSortedAgeRemaining(ageRange){
                if(ageRange != null && ageRange.length > 1) {
                    let tmp = ageRange.slice();
                    tmp.sort((a, b) => a.split('-')[0] - b.split('-')[0]);
                    if(tmp[1] == "15-24"){
                        tmp[1] = "18-24";
                    }
                    return tmp.slice(1);
                }
                return [];
            }
        }
    }
</script>

<style scoped>

</style>