import pptxgen from 'pptxgenjs'
import pattisonLogo from '../assets/PattisonLogo.png'
import * as export_helper from './exportHelper'
import background from '../assets/pattison_pptx_background.jpeg';
// import futuraB from '../fonts/futura-t-bold.ttf';
class PPTXBuilder{
    constructor(){
        this.pptx_elements = {
            'logo': pattisonLogo
        };
        this.pptx = new pptxgen();
        this.pptx_name = 'PattisonSweetSpot';
    }
    set_slide(){
        this.pptx.defineSlideMaster({
            title: "pattison_slide",
            background: {color: "#FFFFFF"},
            objects: [
                {image: {x: 0,y: 0,w: 10,h: 5.625, path: background}},
                {image: {x: 8.4, y: 5.225,w: 1.4,h: 0.35, path: pattisonLogo}}
            ]
        })
    }
    get_name(){
        return this.pptx_name;
    }
    set_name(name){
        if(name != '' && name != null) {
            this.pptx_name = name;
        }
    }
    add_element(elementName, element){
        this.pptx_elements[elementName] = element;
    }
    build(){
        this.set_slide();
        this.add_title_slide();
        this.add_curves();
        // this.add_unique_reach_curve();
        // this.add_frequency_distrubution();
        this.add_statistics_score_card();
        this.add_configs();
        // this.add_source();
    }
    add_title_slide(){
        let title_slide = this.pptx.addSlide({masterName: "pattison_slide"});
        title_slide.addText('SweetSpot Analytics Report',{x:1.5,y: 1.8125,w:7,h:2, align: 'center', fontFace: 'Courier', fontSize: 30})
    }
    add_curves(){
        let slide = this.pptx.addSlide({masterName: "pattison_slide"});
        let height = this.pptx_elements['unique_reach_curve']['height'];
        let width = this.pptx_elements['unique_reach_curve']['width'];
        let porportion = height / width;
        width = 4.8;
        height = width * porportion;
        if(height > 4.425){
            height = 4.425;
            width = height / porportion;
        }
        let xloc = (5 - width) / 2;
        let yloc = (4.625 - height) / 2;
        slide.addImage({data: this.pptx_elements['unique_reach_curve']['image'], x: xloc, y: yloc, h: height, w: width});
        // slide.addText("8 Weeks: Jan 1, 2020 - Mar 30, 2021", {x:(xloc+(width*0.15)),y:(yloc+(height*0.15)),w:(width*0.8),h:(height * 0.1), align: 'center', fontFace: 'Courier', fit: 'shrink'})
        height = this.pptx_elements['frequency_distrubution']['height'];
        width = this.pptx_elements['frequency_distrubution']['width'];
        porportion = height / width;
        width = 3.8;
        height = width * porportion;
        if(height > 4.425){
            height = 4.425;
            width = height / porportion;
        }
        xloc = 5 + ((4 - width) / 2);
        yloc = (4.625 - height) / 2;
        slide.addImage({data: this.pptx_elements['frequency_distrubution']['image'], x: xloc, y: yloc, h: height, w: width});
        this.add_source(slide);
    }
    add_unique_reach_curve(){
        let slide = this.pptx.addSlide({masterName: "pattison_slide"});
        let height = this.pptx_elements['unique_reach_curve']['height'];
        let width = this.pptx_elements['unique_reach_curve']['width'];
        let porportion = height / width;
        height = 4.625;
        width = height / porportion;
        let xloc = (10 - width) / 2;
        slide.addImage({data: this.pptx_elements['unique_reach_curve']['image'], x: xloc, y: .5, h: height, w: width})
    }
    add_frequency_distrubution(){
        let frequency_distrubution_slide = this.pptx.addSlide({masterName: "pattison_slide"});
        let height = this.pptx_elements['frequency_distrubution']['height'];
        let width = this.pptx_elements['frequency_distrubution']['width'];
        let porportion = height / width;
        height = 3.625;
        width = height / porportion;
        if(width > 5.5){
            width = 5.5;
            height = width * porportion;
        }
        let xloc = (10 - width) / 2;
        let yloc = (5.625 - height) / 2;
        frequency_distrubution_slide.addImage({data: this.pptx_elements['frequency_distrubution']['image'], x: xloc, y: yloc, h: height, w: width})
    }
    add_statistics_score_card(){
        let statistics_score_card_slide = this.pptx.addSlide({masterName: "pattison_slide"});
        let data_body = export_helper.build_stats_list(this.pptx_elements['statistics'],this.pptx_elements['configurations']);
        data_body.unshift(['Curve Name','Target Population','Product Quantity','Unique Reach','Unique Reach %','Avg Frequency','Total Impressions','Total Budget','GRPs','CPM']);
        statistics_score_card_slide.addText("Statistics Score Card", {x:3,y:0.25,w:4,h:0.5, align: 'center', fontFace: 'Courier', fontSize: 15});
        statistics_score_card_slide.addTable(data_body,{x:0.1,y:1,w:8.65, fontFace: 'Courier', fontSize: 12, border:{pt: "1", color: '#000000'}});
        this.add_source(statistics_score_card_slide);
    }
    add_configs(){
        let configs_slide = this.pptx.addSlide({masterName: "pattison_slide"});
        let data_body = export_helper.build_config_list(this.pptx_elements['configurations'],this.pptx_elements['names'],this.pptx_elements['statistics']);
        data_body.unshift(['Curve Name', 'Product', 'Date', 'Behavior', 'Gender', 'AgeRange']);
        configs_slide.addText("Configurations", {x:2.75,y:0.25,w:4,h:0.5, align: 'center', fontFace: 'Courier', fontSize: 15});
        configs_slide.addTable(data_body,{x:0.1,y:1,w:8.65, fontFace: 'Courier', fontSize: 12, border:{pt: "1", color: '#000000'}});
        this.add_source(configs_slide);
    }
    add_source(source_slide){
        let source_text = '*Applied Post, DeviceIDs in Specified Geofences, Jan 1, 2020 - March 30, 2021.' + '\n' + '**Environics was a collection of different reports including: FoodSpend_2019_CSV, CannabisInsights_2018_PSV, MoneyMatters_2020_CSV, Opticks_Vividata_2020_CSV, PRIZM_2020_v1_CSV_GeoProfiles, and HouseholdSpend_2020_CSV' + '\n' + '***Statcan, Annual demographic estimates, economic regions, \'Toronto\', 2018/2019; https://www150.statcan.gc.ca/n1/pub/71-607-x/71-607-x2020020-eng.html';
        source_slide.addText(source_text, {x:0.1,y:4.625,w:8,h:1, align: 'left', fontFace: 'Courier', fontSize: 8});
    }
    save(){
        this.pptx.writeFile(this.pptx_name);
    }
    formatNumber(number){
        return number.toLocaleString();
    }
}
export default PPTXBuilder