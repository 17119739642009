<template>
    <v-container style=" height: 100%; max-height: 100%; overflow-y: scroll">
        <v-row align="start">
            <v-col cols="12">
                <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <v-tooltip bottom open-delay="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" color="primary" @click="reset_flight">mdi-reload</v-icon>
                            </template>
                            <span class="help-tooltip">Reload Flight</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="tbl_header" :items="tbl_data" hide-default-footer :calculate-widths="true">
                    <template v-slot:item="{ item, headers }">
                        <tr>
                            <td v-for="h in headers" :key="h.idx" class="ma-0 pa-0">
                                <v-col v-if="info_values.includes(h['value'])" style="height: 100%; width: 100%;">
                                    <span>{{item[h['value']]}}</span>
                                </v-col>
                                <!-- <v-icon v-else-if="item[h['value']] == 1" color="green">mdi-card</v-icon> -->
                                <v-col v-else-if="item[h['value']] == 1" class="ma-0 pa-0" style="height: 100%; width: 100%; background-color: #00FF00;"></v-col>
                                <v-col v-else-if="item[h['value']] == 0.5" class="ma-0 pa-0" style="height: 100%; margin-left: 50% !important; width: 50%; background-color: #00FF00;"></v-col>
                                <v-col v-else-if="item[h['value']] == 1.5" class="ma-0 pa-0" style="height: 100%; margin-right: 50% !important; width: 50%; background-color: #00FF00;"></v-col>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api_caller from '../javascript/api_callers/flighting_api_caller.js'
    export default {
        name: "flighting",
        data(){
            return{
                tbl_header_base: [
                    {
                        text: 'Market',
                        align: 'start',
                        sortable: false,
                        value: 'market'
                    },
                    {
                        text: 'Product',
                        align: 'start',
                        sortable: false,
                        value: 'product'
                    },
                    {
                        text: 'Target Population',
                        align: 'start',
                        sortable: false,
                        value: 'targetPop'
                    },
                    {
                        text: 'R/F',
                        align: 'start',
                        sortable: false,
                        value: 'rf'
                    },
                    {
                        text: 'GRP',
                        align: 'start',
                        sortable: false,
                        value: 'grp'
                    },
                    {
                        text: 'Impressions',
                        align: 'start',
                        sortable: false,
                        value: 'impressions'
                    },
                    {
                        text: 'CPM',
                        align: 'start',
                        sortable: false,
                        value: 'cpm'
                    }
                ],
                tbl_header: [

                ],
                tbl_data: [
                    {
                        market: 'Calgary',
                        product: 'Super Train'
                    },
                    {
                        market: '',
                        product: 'Kong Poster'
                    }
                ],
                info_values: [
                    'market',
                    'product',
                    'targetPop',
                    'rf',
                    'grp',
                    'impressions',
                    'cpm'
                ]
            }
        },
        props: [
            'configManager',
            'dataManager'
        ],
        async mounted() {
            this.tbl_header = [...this.tbl_header_base];
        },
        methods: {
            reset_flight: async function(){
                let config_list = this.configManager.getConfigurationList();
                let data_stats = this.dataManager.getAllDataStatisticsAtVisibleBudget();
                let request_configs = [];
                let min_start = null;
                let max_end = null;
                for(let config of config_list){
                    let request_config = config['config'].get_configuration()
                    if(min_start == null || request_config['StartDate'] < min_start){
                        min_start = request_config['StartDate'];
                    } 
                    if(max_end == null || request_config['EndDate'] > max_end){
                        max_end = request_config['EndDate'];
                    }
                    let data_stat = data_stats[config['id']]; 
                    request_config['budget'] = data_stat['Budget'];
                    request_config['rf'] = data_stat['OnTargetReach'] + " / " + data_stat['AvgFrequency'];
                    request_config['cpm'] = data_stat['CPM']
                    request_config['grp'] = data_stat['GRPS']
                    request_config['impr'] = data_stat['Impressions']
                    request_configs.push(request_config);
                }
                min_start = new Date(min_start)
                min_start.setMinutes(min_start.getMinutes() + min_start.getTimezoneOffset());
                max_end = new Date(max_end)
                let flight = await api_caller.build_flight(request_configs,this.$token.get_val());
                let d = min_start;
                d.setDate(d.getDate() - d.getDay() + (d.getDay() == 0 ? -6:1))
                let header = [...this.tbl_header_base];
                while(d <= max_end){
                    let date_val = d.getFullYear() + '-' + ((d.getMonth()+1)>9 ? '': '0') + (d.getMonth()+1) + '-' + (d.getDate()>9 ? '': '0') + d.getDate()
                    let date_str = (d.getDate()>9 ? '': '0') + d.getDate() + '/' +((d.getMonth()+1)>9 ? '': '0') + (d.getMonth()+1)
                    header.push({
                        text: date_str,
                        align: 'start',
                        sortable: false,
                        value: date_val
                    })
                    d.setDate(d.getDate() + 7);
                }
                this.tbl_header = [...header];
                this.tbl_data = flight;
            }
        }
    }
</script>

<style scoped>

</style>