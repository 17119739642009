<template>
    <v-container style="width: 256px; max-height: 92%; overflow-y: hidden !important;" class="pt-1 mt-3">
        <v-layout text-center column style="height:100%">
            <v-card class="pa-1">
                <v-row>
                    <v-col sm="12">
                        <div style="margin: auto; height:186px; width: 186px; border-radius: 50%;" :style=profileBackgroundColor>
                            <v-icon x-large style="height:80%; width: 80%; margin:10%;">{{icon}}</v-icon>
                        </div>
                    </v-col>
                    <v-col sm="12">
                        <h1 class="mx-auto profileName">{{nameOfUser}}</h1>
                    </v-col>
                </v-row>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "userProfileInfo",
        props: [
            'nameOfUser',
            'color',
            'icon'
        ],
        data () {
            return {
            }
        },
        mounted () {
        },
        updated (){
        },
        computed: {
            profileBackgroundColor(){
                return {
                    "background-color": this.color
                }
            }
        },
        methods: {

        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700,800,900&display=swap');
    .username{
        font-family: 'Poppins' !important;
        font-weight: 300 !important;
    }
</style>