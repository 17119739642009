<template>
    <v-container style=" height: 100%; max-height: 100%; overflow-y: scroll">
        <v-row class="ma-0 pa-0" style=" height: 100%; max-height: 100%; overflow-y: scroll" v-if="viewable">
            <v-img class="mt-3" :src="require('@/assets/Finals/Recall_By_Category.png')" contain style="height: 80vh"></v-img>
            <v-carousel class="my-5" height="80vh" delimiter-icon="mdi-minus" hide-delimiter-background show-arrows-on-hover light>
                <v-carousel-item v-for="(category, i) in graph_sets" :key="i" >
                    <v-img :src="require(`@/assets/Finals/3_set/${category}_Recall_3_Set.png`)" contain style="height: 75vh"></v-img>
                </v-carousel-item>
            </v-carousel>
            <v-img class="mb-3" :src="require('@/assets/Finals/Single_Multi_Recall.png')" contain style="height: 80vh"></v-img>
        </v-row>
        <v-row style=" height: 100%; max-height: 100%; overflow-y: scroll" v-if="!viewable" justify="center" align="center">
            <v-col>
                <v-progress-circular size="100" color="primary" indeterminate></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // import request from 'request'
    import axios from 'axios'
    import apiCaller from '../javascript/api_caller.js'
    export default {
        name: "data_viz",
        data(){
            return{
                graph_sets : ['Automotive','CPG','Financial','Health & Beauty','Media','Restaurants','Telecom','Utilities','Services','Alcohol','Real State','Home Improvements','Education','Entertainment','Fashion','Retail','Travel'],
                viewing: 'singlevmulti',
                viewable: false
            }
        },
        props: [
            'apiToken'
        ],
        async mounted() {
            let view = await apiCaller.can_view_recall_data(this.$token.get_val()).then((response)=>{
                return response['authenticated'];
            }).catch((err)=>{
                console.error(err);
                return false;
            });
            if(view) {
                axios.post('https://statistics.api.docmasweetspot.ca:443/statistics/addRecall', {}, {headers: {'x-access-tokens': this.$token.get_val()}})
                this.viewable = true
            }
            else{
                this.viewable = false;
            }
        },
        methods: {
            // can_view: async function () {
            //     let t = this;
            //     return new Promise((resolve,reject) => {
            //         axios.get('https://www.api.dev.docmasweetspot.ca/authorization/verify/recall', {headers: {'x-access-tokens': t.apiToken}}).then((res)=>{
            //             if(res.status == 200){
            //                 resolve(res.data);
            //             }
            //             else{
            //                 reject();
            //             }
            //         })
            //     });
            // }
        }
    }
</script>

<style scoped>

</style>