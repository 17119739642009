<template>
    <v-alert :value="warning_active" color="warning" outlined>
        <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col class="ma-0 pa-0" cols="2">
                <v-icon color="orange">mdi-alert</v-icon>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8">
                <span class="system_error_text">{{warningMsg}}</span>
            </v-col>
            <v-col class="ma-0 pa-0" cols="2">
                <v-icon color="orange" @click="close_warning">mdi-close</v-icon>
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
    export default {
        name: "SweetSpotWarning",
        props: [
            'warningTrigger',
            'warningMsg'
        ],
        watch: {
            warningTrigger: {
                handler: function(){
                    if(this.warningTrigger == false){
                        this.warning_active = false;
                    }
                    else{
                        this.warning_active = true;
                    }
                },
                immediate: true
            },
        },
        data() {
            return {
                warning_active: false
            }
        },
        methods: {
            close_warning: function() {
                this.$emit('close');
            }
        }
    }
</script>
<style>
</style>