import * as ao from './arrayOperators.js'
function isValueWithinBudget(budgetArray, value){
    var max = ao.getMaxFromArray(budgetArray);
    var min = ao.getMinFromArray(budgetArray);
    if(value >= min && value <= max){
        return true;
    }
    else{
        return false;
    }
}
function checkIfValueIsInArray(budgetArray, value){
    var inArray = false;
    for(var i = 0; i < budgetArray.length; i++){
        if(budgetArray[i] == value){
            inArray = true;
            break;
        }
    }
    return inArray;
}
function snapToClosestValueInArray(budgetArray, value){
    var indexOfClosesValue = 0;
    var smallestDifference = null;
    for(var i = 0; i < budgetArray.length; i++){
        var currentDifference = value - budgetArray[i];
        if(currentDifference < 0){
            currentDifference = currentDifference * -1;
        }
        if(smallestDifference == null){
            smallestDifference = currentDifference;
            indexOfClosesValue = i;
        }
        else if(currentDifference <= smallestDifference){
            smallestDifference = currentDifference;
            indexOfClosesValue = i;
        }
    }
    return budgetArray[indexOfClosesValue];
}
export {isValueWithinBudget, checkIfValueIsInArray, snapToClosestValueInArray}