const config = {
    'font_path': 'pattison',
    'graph_title_font': 'FuturaTLight',
    'graph_axis_title_font': 'FuturaTMedium',
    'graph_text_font': 'FuturaTLight',
    'primary': '#00285e',
    'secondary': '#0079C1',
    'third': '#00285e',
    'main_logo_path': './assets/PattisonLogo.png',
    'config_end_point': '/sweetspot/pattison/configurations',
    'singleOperator': true
}
export default {config};