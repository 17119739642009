import axios from 'axios'

// const api_dev = 'https://staging.api.docmasweetspot.ca';
const api_live = 'https://www.api.docmasweetspot.ca'
// const api_live = 'https://quebecor.api.dev.docmasweetspot.ca'
const api_stats = 'https://statistics.api.docmasweetspot.ca';
const api_base = api_live;

function can_view_recall_data(token){
    let api_url = api_base+'/permissions/functionality/recall';
    return axios_get_call(api_url,token);
}
function can_switch_pricing(token){
    let api_url = api_base+'/permissions/functionality/switchPricing';
    return axios_get_call(api_url,token);
}
function can_use_save_functionality(token){
    let api_url = api_base+'/permissions/functionality/canSave';
    return axios_get_call(api_url,token);
}
function can_use_export_functionality(token){
    let api_url = api_base+'/permissions/functionality/canExport';
    return axios_get_call(api_url,token); 
}
function can_use_optimizer_functionality(token){
    let api_url = api_base+'/permissions/functionality/optimizer';
    return axios_get_call(api_url,token);
}
function can_use_incremental_reach_functionality(token){
    let api_url = api_base+'/permissions/functionality/incrementalReach';
    return axios_get_call(api_url,token);
}
/*
Scenario Runner Calls
*/
function get_all_configs(token, ep = '/sweetspot/pattison/configurations'){
    let api_url = api_base + ep;
    return axios_get_call(api_url,token)
}
function process_config(token,data){
    let api_url = api_base+'/sweetspot/process/pattisonproduct/configuration';
    return axios_post_call(api_url,data,token);
}
function process_config_national(token,data){
    let api_url = api_base+'/sweetspot/process/pattisonproduct/configuration/national';
    return axios_post_call(api_url,data,token);
}
function process_config_local(token,data){
    let api_url = api_base+'/sweetspot/process/pattisonproduct/configuration/local';
    return axios_post_call(api_url,data,token);
}
function target_population(token,data){
    let api_url = api_base + '/sweetspot/targetPop';
    return axios_post_call(api_url,data,token);
}
/*
Optimize Api Calls
*/
function optimize(token,data){
    let api_url = api_base+'/optimizer';
    return axios_post_call(api_url,data,token);
}
/*
Statistics Api Calls
*/
function add_run(token){
    let api_url = api_stats + '/statistics/addRun';
    return axios_post_call(api_url,{},token);
}
function add_pptx(token){
    let api_url = api_stats + '/statistics/addPPTXExport';
    return axios_post_call(api_url,{},token);
}
function add_pdf(token){
    let api_url = api_stats + '/statistics/addPDFExport';
    return axios_post_call(api_url,{},token);
}
/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}
function axios_get_call(api_url,token){
    return new Promise(function(resolve,reject){
        axios.get(api_url, {headers: {'x-access-tokens': token}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {can_view_recall_data,can_switch_pricing,can_use_export_functionality,can_use_save_functionality,can_use_optimizer_functionality,can_use_incremental_reach_functionality,get_all_configs,process_config,process_config_national,process_config_local,target_population,add_run,add_pptx,add_pdf,optimize}