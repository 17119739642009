import '@mdi/font/css/materialdesignicons.css';
import {mdiNotebookEditOutline, mdiBookOpenPageVariantOutline, mdiNotebookPlusOutline, mdiFolderMultiplePlusOutline} from '@mdi/js';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            edit_outline: mdiNotebookEditOutline,
            book_open_outline: mdiBookOpenPageVariantOutline,
            notebook_plus_outline: mdiNotebookPlusOutline,
            folder_multiple_plus_outline: mdiFolderMultiplePlusOutline
        }
    },
    theme:{
        themes: {
            light: {
                primary: '#EE8500',
                secondary: '#F3A815',
                third: '#000000'
            },
        },
    },
});
