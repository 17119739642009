<template>
  <v-card class="chart-wrapper pa-3" v-if="loading || data_loading">
    <v-row class="pa-0 ma-0" justify="center" align="center" style="height: 100%; width: 100%;">
      <v-col>
        <v-progress-circular indeterminate color="primary" size="50" style="position: relative;">
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-card>
  <v-card id="uniqueReachCurveGraph" class="chart-wrapper pa-0" v-else style="width: 100%; height: 100%; position: relative;">
    <GChart
      :settings="{ packages: ['corechart', 'table'] }"
      style="width: 100%; height: 93%; position: absolute; z-index: 2;"
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
      ref="uniqueReachCurve"/>
    <v-row class="ma-0 pa-0 flex-nowrap control_row" style="height: 5%; max-height: 5%; position: absolute; top: 94%; min-width: 100%; max-width: 100%;">
      <v-col cols="auto" class="my-0 py-0" style="max-height: 100%;">
        <v-switch class="ma-0 pa-0 unique_reach_curve_switch_setting" style="max-height: 100%; height: 100%; font-size: 0.2em !important;" label="Absolute Reach" v-model="absoluteReach" @change="compute_data"></v-switch>
      </v-col>
      <v-col cols="auto" class="my-0 py-0" style="max-height: 100%;">
        <v-switch v-if="$can_find_incremental_reach.get_val()" class="ma-0 pa-0 unique_reach_curve_switch_setting" style="max-height: 100%; height: 100%; font-size: 0.2em !important;" :disabled="!omni_channel" label="Build Incremental Reach Curve" v-model="omniChannelEnable" @change="buildOmniChannelCurve"></v-switch>
      </v-col>
      <v-col cols="auto" class="my-0 py-0" style="max-height: 100%;">
        <v-menu top :close-on-content-click="false">
          <template v-slot:activator="{on: mon}">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{on}">
                <v-btn v-if="$can_optimize.get_val()" class="unique_reach_curve_switch_setting" v-on="{...on,...mon}" plain elevation='0' color="#00000000">
                  <v-icon color="primary" class="mr-2">mdi-target</v-icon>  
                  Optimizer
                </v-btn>
              </template>
              <span class="help-tooltip">Scenario Optimizer</span>
            </v-tooltip>
          </template>
          
          <v-card class="pa-3">
            <v-row justify="center">
              <v-col cols="12" class="pb-0" v-if="optimized_budg == 0 || !optimized">
                <span class="unique_reach_curve_optimizer_title">Currently Not Optimized</span>
              </v-col>
              <v-col cols="12" class="pb-0" v-if="optimized_budg > 0 && optimized">
                <span class="unique_reach_curve_optimizer_title">Currently Optimized for ${{optimized_budg}}</span>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field class="unique_reach_curve_switch_setting" label="Budget" v-model="optimize_budg"></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-slider min="0" :max="optimize_budg_max" v-model="optimize_budg" @change="optimized = false">
                  <template v-slot:prepend>
                    <v-icon color="primary" @click="optimize_budg -= 500">mdi-cash</v-icon>
                  </template>
                  <template v-slot:append>
                    <v-icon color="primary" @click="optimize_budg += 500">mdi-cash-multiple</v-icon>
                  </template>
                </v-slider>
              </v-col>
              <v-col cols="auto">
                <v-btn class="unique_reach_curve_optimizer_title" :disabled="optimize_budg <= 0" @click="optimizeScenario">Optimize</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-menu> 
      </v-col>
      <!--
      <v-col cols="auto" class="my-0 py-0" style="max-height: 100%;">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{on}">
            <v-btn @click="pointOfDiminishingReturns" v-on="on" plain elevation='0' color="#00000000">
              <v-icon color="primary" class="mr-2">mdi-target-variant</v-icon>  
              Find POD
            </v-btn>
          </template>
          <span class="helpTooltip">Find Point of Diminishing Returns</span>
        </v-tooltip>
      </v-col>
      -->
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: "uniqueReachCurvev2",
    props: [
      'data',
      'loading',
      'targetPop',
      'budget',
      'dataManager',
      'get_pdf',
      'get_pptx',
      'omni_channel',
      'omni_enabled'
    ],
    data () {
      return {
        // Array will be automatically processed with visualization.arrayToDataTable function
        chartDataHeader: [
          {"id":"","label":"Budget","pattern":"","type":"number","p":{"role":"domain"}},
          {"id":"","label":"Unique Reach","pattern":"","type":"number","p":{"role":"data"}},
          {"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}},
          {"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}}],
        limitDataHeader: [
          {"id":"","label":"Budget","pattern":"","type":"number","p":{"role":"domain"}},
          {"id":"","label":"Unique Reach","pattern":"","type":"number","p":{"role":"data"}}],
        chartData:{"cols": this.chartDataHeader, "rows": [], "p": null},
        limitData:{"cols": this.limitDataHeader, "rows": [], "p": null},
        chartOptions: {
          title: 'Unique Reach as a Function of Ad Spend',
          titleTextStyle: {fontName: this.$configuration.get_config_param('graph_title_font'), fontSize: 18, bold: false},
          colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
          vAxes: { 0:{title: 'Unique Reach', titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 16, italic: false}, textStyle:{fontName: this.$configuration.get_config_param('graph_text_font'), fontSize: 12}}},
          vAxis: {minValue: 0, maxValue: 1, format: 'percent', viewWindowMode: 'explicit', viewWindow:{min:0, max: 1}, baselineColor: '#000000', gridlines:{count: 0}, minorGridlines:{count: 0}},
          hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, title: 'Budget in $', minValue: 0, format:'currency', slantedText: true, slantedTextAngle: 15, baselineColor: '#000000', textStyle:{fontName: this.$configuration.get_config_param('graph_text_font'), fontSize: 12}, titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 16, italic: false}, gridlines:{count: 0}, minorGridlines:{count: 0}},
          curveType: 'function',
          legend: { position: 'top', maxLines: 4},
          chartArea: {left: '15%', top: '10%', width: '80%', height: '80%'},
          pointSize: 5,
          tooltip: {isHtml: true},
          backgroundColor: 'none'
        },
        LimitChartOptions: {
          title: '',
          colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
          vAxis: {minValue: 0, maxValue: 1, viewWindowMode: 'explicit', viewWindow:{min:0, max: 1}, baselineColor: '#000000', gridlines:{count: 0}, minorGridlines:{count: 0}, textPosition: 'none'},
          hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, minValue: 0, format:'currency', slantedText: true, slantedTextAngle: 15, baselineColor: '#000000', textPosition: 'none', gridlines:{count: 0}, minorGridlines:{count: 0}},
          curveType: 'function',
          legend: { position: 'none'},
          chartArea: {left: '15%', top: '10%', width: '80%', height: '80%'},
          pointSize: 0,
          backgroundColor: 'none'
        },
        chartEvents: {
          select: () => {
              const table = this.$refs.uniqueReachCurve.chartObject;
              const data  = this.$refs.uniqueReachCurve.data;
              const selection = table.getSelection()[0];
              if(selection != null) {
                  this.optimized_budg = 0;
                  this.optimized = false;
                  const row = selection.row;
                  const column = selection.column;
                  const selectedBudget = data['rows'][row]['c'][0]['v'];
                  // var idx_of_id = (column - 1) / 4;
                  var idx_of_id = (column - 1) / 2;
                  var configuration_id = this.dataManager.getDataIds()[idx_of_id];
                  this.dataManager.setVisibleBudget(configuration_id,selectedBudget);
                  this.$emit('updatedBudg',configuration_id);
              }
          }
        },
        omniChannelEnable: false,
        absoluteReach: false,
        data_loading: true,
        optimize_budg: 0,
        optimize_budg_max: 0,
        optimized: false,
        optimized_budg: 0
      }
    },
    mounted(){
      this.chartData = {"cols": this.chartDataHeader, "rows": [], "p": null};
      this.compute_data();
    },
    watch: {
      loading: {
        handler: function(){
          if(this.loading == false){
            this.chartData = {"cols": this.chartDataHeader, "rows": [], "p": null};
            this.compute_data()
          }
        },
        immediate: true
      },
      get_pdf: {
        handler: function(){
          if(this.get_pdf == true){
            this.download('pdf');
          }
        }
      },
      get_pptx: {
        handler: function(){
            if(this.get_pptx == true){
                this.download('pptx');
            }
        }
      },
      omni_enabled: {
        handler: function(){
            this.omniChannelEnable = this.omni_enabled;
        }
      }
    },
    methods: {
      compute_data: function(){
        this.data_loading = true;
        let data = [];
        let configNames = [];
        let limit = [];
        let num_products = {};
        let max = 0;
        let ymax = 0;
        let xmax = 0;
        if(this.dataManager != null && !this.absoluteReach){
          data = this.dataManager.getUniqueReachPercentArray();
          limit = this.dataManager.getUniqueReachVisibleBudgPercentArray();
          configNames = this.dataManager.getDataNames(true);
          num_products = this.dataManager.getNumProducts(true);
        }
        else if(this.dataManager != null && this.absoluteReach){
            data = this.dataManager.getUniqueReachDataArray();
            limit = this.dataManager.getUniqueReachVisibleBudgDataArray();
            configNames = this.dataManager.getDataNames(true);
            num_products = this.dataManager.getNumProducts(true);
        }
        let chartHeader = [];
        chartHeader.push({"id":"","label":"Budget","pattern":"","type":"number","p":{"role":"domain"}});
        if(configNames.length == 0){
            chartHeader.push({"id":"","label":"Configuration 0","pattern":"","type":"number","p":{"role":"data"}});
            // chartHeader.push({"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}});
            // chartHeader.push({"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}});
        }
        for(let configName of configNames){
            chartHeader.push({"id":"","label":configName['name'],"pattern":"","type":"number","p":{"role":"data"}});
            chartHeader.push({"type": "string","role": "tooltip","p":{"html":true}});
            // chartHeader.push({"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}});
            // chartHeader.push({"id":"","label":"","pattern":"","type":"number","p":{"role":"interval"}});
        }
        let data_rows = [];
        let limit_dict = {};
        if(limit.length > 0){
            for(let l = 0; l < limit.length; l ++){
                if(l % 2 == 0) {
                    let val_idx = parseInt((l / 2) + 1);
                    if(!Object.keys(limit_dict).includes(limit[l][0])){
                        limit_dict[limit[l][0]] = {};
                    }
                    limit_dict[limit[l][0]][val_idx] = {'s': limit[l][val_idx], 'e': 0};
                }
            }
        }
        for(let i = 0; i < data.length; i++){
            let data_row = [{"v":data[i][0], "f": null}];
            if(parseInt(data[i][0]) > xmax){
              xmax = data[i][0];
            }
            if(Object.keys(limit_dict).includes(data[i][0])){
                // let num_functions = (data[i].length - 1) / 3;
                let num_functions = (data[i].length - 1)
                let n = 0;
                for(n; n < num_functions; n++){
                    // if(data[i][(n*3)+1] != null){
                    if(data[i][n+1] != null){
                        break;
                    }
                }
                let keyd = (n+1).toString();
                if(Object.keys(limit_dict[data[i][0]]).includes(keyd)) {
                    let limit_vals = limit_dict[data[i][0]][n + 1];
                    if (limit_vals['s'] > ymax) {
                        ymax = limit_vals['s']
                    }
                    let limit_row = [{"v": data[i][0], "f": null}];
                    let limit_row_0 = [{"v": data[i][0], "f": null}];
                    for (let b = 0; b < n; b++) {
                        limit_row.push({"v": null, "f": null});
                        limit_row.push({"v": null, "f": null});
                        // limit_row.push({"v": null, "f": null});
                        // limit_row.push({"v": null, "f": null});
                        limit_row_0.push({"v": null, "f": null});
                        limit_row_0.push({"v": null, "f": null});
                        // limit_row_0.push({"v": null, "f": null});
                        // limit_row_0.push({"v": null, "f": null});
                    }
                    limit_row.push({"v": limit_vals['s'], "f": null});
                    limit_row.push({"v": this.emptyTooltip(), "f": null});
                    // limit_row.push({"v": 0, "f": null});
                    // limit_row.push({"v": 0, "f": null});
                    limit_row_0.push({"v": limit_vals['e'], "f": null});
                    limit_row_0.push({"v": this.emptyTooltip(), "f": null});
                    // limit_row_0.push({"v": 0, "f": null});
                    // limit_row_0.push({"v": 0, "f": null});
                    for (let a = 0; a < ((num_functions - 1) - n); a++) {
                        limit_row.push({"v": null, "f": null});
                        limit_row.push({"v": null, "f": null});
                        // limit_row.push({"v": null, "f": null});
                        // limit_row.push({"v": null, "f": null});
                        limit_row_0.push({"v": null, "f": null});
                        limit_row_0.push({"v": null, "f": null});
                        // limit_row_0.push({"v": null, "f": null});
                        // limit_row_0.push({"v": null, "f": null});
                    }
                    data_rows = data_rows.concat({"c": limit_row});
                    data_rows = data_rows.concat({"c": limit_row});
                    data_rows = data_rows.concat({"c": limit_row_0});
                    data_rows = data_rows.concat({"c": limit_row});
                }
            }
            if(parseInt(data[i][0]) > max){
                max = data[i][0];
            }
            for(let j = 1; j < data[i].length; j++){
                data_row.push({"v": data[i][j], "f": null});
                // if(j%3 == 1){
                //     if(data[i][j] == null){
                //         data_row.push({"v":null, "f": null});
                //     }
                //     else{
                        // let urPos = ((j+2)/3)-1;
                if(data[i][j] == null){
                  data_row.push({"v":null, "f": null});
                }
                else{
                  let urPos = j - 1
                  data_row.push({"v": this.toolTip(configNames[urPos]['name'],data[i][0],data[i][j],num_products[configNames[urPos]['id']][data[i][0]])});
                }
                //     }
                // }
            }
            data_rows = data_rows.concat({"c": data_row});
        }
        if(this.chartData['cols'].length != chartHeader.length){
          if(this.chartData['cols'][this.chartData['cols'].length - 2]['label'] != 'Incremental Reach Curve' && chartHeader[chartHeader.length -2]['label'] === 'Incremental Reach Curve'){
            this.optimized = true;
          }
          else if(this.chartData['cols'][this.chartData['cols'].length - 2]['label'] === 'Incremental Reach Curve' && chartHeader[chartHeader.length -2]['label'] != 'Incremental Reach Curve'){
            this.optimized = true;
          }
          else{
            this.optimized = false;
          }
        }
        else{
          if(this.chartData['cols'][this.chartData['cols'].length - 2]['label'] === 'Incremental Reach Curve' && chartHeader[chartHeader.length -2]['label'] != 'Incremental Reach Curve'){
            this.optimized = false;
          }
        }
        this.chartData = {"cols": chartHeader, "rows": data_rows, "p": null};
        this.chartOptions.hAxis.viewWindow.max = max;
        if(this.absoluteReach){
            ymax = this.dataManager.getDataLimit();
            ymax = ymax * 1.05;
            this.chartOptions.vAxis.format = 'short';
        }
        else {
            ymax = this.dataManager.getPercentageLimit();
            if((ymax + 0.05) > 1){
                ymax = 1;
            }
            else{
                ymax = ymax + 0.05;
            }
            this.chartOptions.vAxis.format = 'percent';
        }
        this.chartOptions.vAxis.maxValue = ymax;
        this.chartOptions.vAxis.viewWindow.max = ymax;
        let colors = ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'];
        let chart_colors = [];
        for(let c = 0; c < ((chartHeader.length - 1) / 2); c++){
            chart_colors.push(colors[c]);
        }
        if(this.omniChannelEnable){
          xmax = xmax * (((chartHeader.length - 1) / 2) - 1);
        }
        else {
          xmax = xmax * ((chartHeader.length - 1) / 2)
        }
        this.optimize_budg_max = xmax;
        this.chartOptions.colors = chart_colors;
        this.data_loading = false;
      },
      download: function(type){
        let chart = this.$refs.uniqueReachCurve.chartObject;
        let chartImage = chart.getImageURI();
        let width = document.getElementById('uniqueReachCurveGraph').offsetWidth;
        let height = document.getElementById('uniqueReachCurveGraph').offsetHeight;
        let returnedObject = {'name':'unique_reach_curve','data':{'image':chartImage,'width':width,'height':height}};
        if(type == 'pdf') {
            this.$emit('pdf_downloaded', returnedObject)
        }
        else{
            this.$emit('pptx_downloaded', returnedObject)
        }
      },
      toolTip: function(configurationName,budget,unique_reach,num_prod){
        let formatter = new Intl.NumberFormat('en-US', {style:'currency', currency: 'USD'});
        budget = formatter.format(budget);
        let tooltip_html = '<div style="padding: 5px">';
        tooltip_html += '<table style="max-width: 15vw; word-wrap: break-word">';
        tooltip_html += '<tr><th class="tooltip_title" style="text-align: center">'+configurationName+'</th></tr>';
        tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">'+num_prod+'</td></tr>';
        tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">'+budget+'</td></tr>';
        if(this.absoluteReach){
            tooltip_html += '<tr><td class="tooltip_value" style="text-align: center">' + unique_reach.toLocaleString("en-US") + '</td></tr>';
        }
        else {
            unique_reach = (unique_reach * 100).toFixed(2);
            tooltip_html += '<tr><td class="tooltip_value" style="text-align: center">' + unique_reach + '%</td></tr>';
        }
        tooltip_html += '</table>';
        tooltip_html += '</div>';
        return tooltip_html;
      },
      emptyTooltip: function () {
          let tooltip_html = '<div></div>';
          return tooltip_html;
      },
      buildOmniChannelCurve: function(){
          this.$emit('build_omni_channel')
      },
      optimizeScenario: function(){
          this.optimized = true;
          this.optimized_budg = this.optimize_budg;
          this.$emit('optimize_scenario',this.optimize_budg)
      },
      // pointOfDiminishingReturns: function(){
      //   let data = this.dataManager.getUniqueReachDataArray();
      //   let x_0 = null;
      //   let y_0 = null;
      //   let x_1 = null;
      //   let y_1 = null;
      //   let x_2 = null;
      //   let y_2 = null;
      //   // let slope = null;
      //   let dd = null;
      //   for(let row of data){
      //     if(x_0 == null){
      //       x_0 = row[0];
      //       y_0 = row[1];
      //       continue;
      //     }
      //     if(x_1 == null){
      //       x_1 = row[0];
      //       y_1 = row[1];
      //       continue;
      //     }
      //     x_2 = row[0];
      //     y_2 = row[1];
      //     // slope = (y_1 - y_0) / (x_1 - x_0);
      //     dd = ((2 / ((x_1 - x_0)-(x_2-x_0))) * y_0) + ((2 / ((x_2 - x_1)-(x_2-x_0))) * y_2)
      //     x_0 = x_1;
      //     y_0 = y_1;
      //     x_1 = x_2;
      //     y_1 = y_2;
      //     // dd.push(slope);
      //   }
      //   // let d_prev = null;
      //   // let double_derivative = null;
      //   // for(let double of dd){
      //   //   if(d_prev == null){
      //   //     d_prev = double;
      //   //     continue;
      //   //   }
      //   //   double_derivative = (double - d_prev) / 1950;
      //   // }
      // }
    }
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Poppins:400');
  .chart-wrapper{
    width:100%;
    height:100%;
  }
  div.google-visualization-tooltip {
    background-color: black;
    padding: 5%;
  }
  .control_row{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .control_row::-webkit-scrollbar{
    display: none;
  }
</style>
