import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import data_viz from '../components/data_viz'
import ServerMaintenance from '../views/ServerMaintenance'

Vue.use(Router)

const router = new Router({
    routes : [
        {
          path: '*',
          redirect: '/login'
        },
        {
          path: '/',
          redirect: '/login'
        },
        {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            title: 'Docma'
        }
        },
        {
          path: '/login',
          name: 'Login',
        //   redirect: '/ServerMaintenance',
          component: Login,
          meta: {
              title: 'Docma'
          }
        },
        {
            path: '/dataviz',
            name: 'DataViz',
            component: data_viz,
            meta: {
                requiresAuth: true,
                title: 'Docma'
            }
        },
        {
            path: '/ServerMaintenance',
            name: 'ServerMaintenance',
            component: ServerMaintenance,
            meta: {
                title: 'Docma'
            }
        },
    ]
});

router.beforeEach((to,from,next) => {
    // const currentUser = firebase.auth().currentUser;
    const currentUser = Vue.prototype.$token.get_val();
    const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);

    document.title = to.meta.title;

    if(requiresAuth && !currentUser){
        next('Login');
    }
    else{
        next();
    }
});

export default router;
