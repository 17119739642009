import pptxgen from 'pptxgenjs'
import pattisonLogo from '../assets/PattisonLogo.png'
import * as export_helper from './exportHelper'
class PPTXBuilder{
    constructor(){
        this.pptx_elements = {
            'logo': pattisonLogo
        };
        this.pptx = new pptxgen();
        this.pptx_name = 'PattisonSweetSpot';
    }
    get_name(){
        return this.pptx_name;
    }
    set_name(name){
        if(name != '' && name != null) {
            this.pptx_name = name;
        }
    }
    add_element(elementName, element){
        this.pptx_elements[elementName] = element;
    }
    build(){
        this.add_main_logo();
        this.add_unique_reach_curve();
        this.add_frequency_distrubution();
        // this.add_statistics_score_card();
        // this.add_configs();
        this.add_source();
    }
    add_main_logo(){
        let title_slide = this.pptx.addSlide();
        title_slide.addImage({path: pattisonLogo, x:2.5,y:2.1875,w:5,h:1.25});
        title_slide.addText('SweetSpot Analytics Report',{x:3,y:3.5375,w:4,h:1, align: 'center'})
    }
    add_unique_reach_curve(){
        let slide = this.pptx.addSlide();
        slide.addImage({data: this.pptx_elements['unique_reach_curve']['image'], x: '15%', y: '15%', sizing:{type:'contain', h: '70%', w: '70%'}})
        // let data = [];
        // for(let id in this.pptx_elements['unique_reach_curve']){
        //     let name = id;
        //     let unique_reach_data = this.pptx_elements['unique_reach_curve'][id];
        //     let labels = [];
        //     let values = [];
        //     for(let uniqueReach of unique_reach_data){
        //         let budg = uniqueReach[0];
        //         let reach = uniqueReach[1];
        //         if(budg != 1) {
        //             labels.push(budg);
        //             values.push(reach);
        //         }
        //     }
        //     data.push({'name':name, 'labels':labels,'values':values});
        // }
        // let target_pop = this.pptx_elements['unique_reach_curve_max'];
        // slide.addChart(this.pptx.ChartType.line,data,{x:1,y:1,w:8,h:3.625,chartColors:['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8'], valAxisMaxVal: target_pop, valAxisMinVal: 0, showTitle: true, title: 'Unique Reach as a Function of Ad Spend', titleAlign: 'left', titleFontFace: 'FuturaTLight', catAxisTitle: 'Budget', catAxisTitleFontFace: 'FurutaTMediuml', catAxisTitleFontSize: 14, catAxisLabelRotate: 45, valAxisTitle: 'Unique Reach', valAxisTitleFontFace: 'FurutaTMedium', valAxisTitleFontSize: 14, showCatAxisTitle: true, showValAxisTitle: true, valAxisLabelFormatCode: '%', showLabel: true, dataLabelPosition: 't'})
    }
    add_frequency_distrubution(){
        let frequency_distrubution_slide = this.pptx.addSlide();
        let data = [];
        for(let id in this.pptx_elements['frequency_distribution']){
            let name = id;
            let frequency_distribution = this.pptx_elements['frequency_distribution'][id];
            let labels = [];
            let values = [];
            for(let freq_dist of frequency_distribution){
                let budg = freq_dist[0];
                let Impr = freq_dist[1];
                labels.push(budg);
                values.push(Impr);
            }
            data.push({'name':name,'labels':labels,'values':values});
        }
        let max_freq = this.pptx_elements['frequency_distribution_max'];
        frequency_distrubution_slide.addChart(this.pptx.ChartType.line,data,{x:1,y:1,w:8,h:3.625,chartColors:['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8'], valAxisMaxVal:max_freq, valAxisMinVal:0, showTitle: true, title: 'Scaled Frequency of Unique Reach', titleFontSize: 18, titleAlign: 'left', titleFontFace: 'FuturaTLight' , catAxisTitle: 'Frequency', catAxisTitleFontFace: 'FurutaTMedium', catAxisTitleFontSize: 14, valAxisTitle: 'Number of Occurrences', valAxisTitleFontFace: 'FuturaTMedium', valAxisTitleFontSize: 14, showCatAxisTitle: true, showValAxisTitle: true, lineDataSymbol: 'none', catAxisMinVal: 0})
    }
    add_statistics_score_card(){
        let statistics_score_card_slide = this.pptx.addSlide();
        let data_body = export_helper.build_stats_list(this.pptx_elements['statistics']);
        data_body.unshift(['Curve Name', 'CPM', 'Unique Reach', 'Target Pop', 'GRPs', 'Avg Frequency']);
        statistics_score_card_slide.addText("Statistics Score Card", {x:3,y:0.25,w:4,h:0.5, align: 'center'});
        statistics_score_card_slide.addTable(data_body,{x:1,y:1,w:8})
    }
    add_configs(){
        let configs_slide = this.pptx.addSlide();
        let data_body = export_helper.build_config_list(this.pptx_elements['configurations'],this.pptx_elements['names']);
        data_body.unshift(['Curve Name', 'Product', 'Date', 'Behavior', 'Gender', 'AgeRange']);
        configs_slide.addText("Configurations", {x:3,y:0.25,w:4,h:0.5, align: 'center'});
        configs_slide.addTable(data_body,{x:1,y:1,w:8})
    }
    add_source(){
        let source_slide = this.pptx.addSlide();
        source_slide.addText("Source", {x:3,y:0.25,w:4,h:0.5, align: 'center'});
        let source_text = '*Connected Interactive, DeviceIDs in Specified Geofences, Aug 1, 2019 - Feb 28, 2021.' + '\n' + '**Environics was a collection of different reports including: FoodSpend_2019_CSV, CannabisInsights_2018_PSV, MoneyMatters_2020_CSV, Opticks_Vividata_2020_CSV, PRIZM_2020_v1_CSV_GeoProfiles, and HouseholdSpend_2020_CSV' + '\n' + '***Statcan, Annual demographic estimates, economic regions, \'Toronto\', 2018/2019; https://www150.statcan.gc.ca/n1/pub/71-607-x/71-607-x2020020-eng.html';
        source_slide.addText(source_text, {x:1,y:1,w:8,h:4, align: 'left', fontSize: 10});
    }
    save(){
        this.pptx.writeFile(this.pptx_name);
    }
    formatNumber(number){
        return number.toLocaleString();
    }
}
export default PPTXBuilder