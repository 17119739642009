<template>
    <v-container style="width: calc(75vw - 56px); height: 92%; overflow-y: hidden !important;" class="pa-0 my-3">
        <v-row style="width: 100%;" class="my-3 mx-0 pa-0" align="center" justify="center">
            <v-col cols="auto">
                <div style="width: 200px; height: 200px; border-radius:100px; background-color: #00285e"></div>
            </v-col>
        </v-row>
        <v-row style="width: 100%; max-height: 60vh; overflow-y: scroll" class="my-3 mx-0 pa-0">
            <v-col cols="6" class="my-3 mx-0 pa-0">
                <v-card style="width: 85%; margin-left: 10%; margin-right: 5%;">
                    <span class="leaderboard_title">All Time Leader Board</span>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left"></th>
                                    <th class="text-left leaderboard_section_title">User</th>
                                    <th class="text-left leaderboard_section_title">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="leader of allTimeLeaders" :key="leader['pos']">
                                    <td v-if="leader['pos'] == 0" class="pa-1 ma-0" align="center"><v-icon size="48px" color="#D4AF37">mdi-trophy-outline</v-icon></td>
                                    <td v-if="leader['pos'] == 1" class="pa-1 ma-0" align="center"><v-icon size="40px" color="#C4CACE">mdi-trophy-outline</v-icon></td>
                                    <td v-if="leader['pos'] == 2" class="pa-1 ma-0" align="center"><v-icon size="32px" color="#B08D57">mdi-trophy-outline</v-icon></td>
                                    <td v-if="leader['pos'] > 2" class="pa-1 ma-0" align="center"></td>
                                    <td class="text-left leaderboard_value">{{leader['name']}}</td>
                                    <td class="text-left leaderboard_value">{{leader['score']}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="6" class="my-3 mx-0 pa-0">
                <v-card style="width: 85%; margin-left: 5%; margin-right: 10%;">
                    <span class="leaderboard_title">This Week's Leaders</span>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left"></th>
                                <th class="text-left leaderboard_section_title">User</th>
                                <th class="text-left leaderboard_section_title">Points</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="leader in weekLeaders" :key="leader['pos']">
                                <td v-if="leader['pos'] == 0" class="pa-1 ma-0" align="center"><v-icon size="48px" color="#D4AF37">mdi-medal-outline</v-icon></td>
                                <td v-if="leader['pos'] == 1" class="pa-1 ma-0" align="center"><v-icon size="40px" color="#C4CACE">mdi-medal-outline</v-icon></td>
                                <td v-if="leader['pos'] == 2" class="pa-1 ma-0" align="center"><v-icon size="32px" color="#B08D57">mdi-medal-outline</v-icon></td>
                                <td v-if="leader['pos'] > 2" class="pa-1 ma-0" align="center"></td>
                                <td class="text-left leaderboard_value">{{leader['name']}}</td>
                                <td class="text-left leaderboard_value">{{leader['score']}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="6" class="my-3 mx-0 pa-0">
                <v-card style="width: 85%; margin-left: 10%; margin-right: 5%;">
                    <span class="leaderboard_title">Team Leaders</span>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left"></th>
                                <th class="text-left leaderboard_section_title">Team</th>
                                <th class="text-left leaderboard_section_title">Points</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="leader of teamLeaders" :key="leader['pos']">
                                <td v-if="leader['pos'] == 0" class="pa-1 ma-0" align="center"><v-icon size="48px" color="#D4AF37">mdi-podium-gold</v-icon></td>
                                <td v-if="leader['pos'] == 1" class="pa-1 ma-0" align="center"><v-icon size="40px" color="#C4CACE">mdi-podium-silver</v-icon></td>
                                <td v-if="leader['pos'] == 2" class="pa-1 ma-0" align="center"><v-icon size="32px" color="#B08D57">mdi-podium-bronze</v-icon></td>
                                <td v-if="leader['pos'] > 2" class="pa-1 ma-0" align="center"></td>
                                <td class="text-left leaderboard_value">{{leader['name']}}</td>
                                <td class="text-left leaderboard_value">{{leader['score']}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="6" class="my-3 mx-0 pa-0">
                <v-card style="width: 85%; margin-left: 5%; margin-right: 10%;">
                    <span class="leaderboard_title">Inner Team Leader Board</span>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left"></th>
                                <th class="text-left leaderboard_section_title">User</th>
                                <th class="text-left leaderboard_section_title">Points</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="pa-1 ma-0" align="center"><v-icon size="48px" color="#D4AF37">mdi-seal</v-icon></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="pa-1 ma-0" align="center"><v-icon size="40px" color="#C4CACE">mdi-seal</v-icon></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="pa-1 ma-0" align="center"><v-icon size="32px" color="#B08D57">mdi-seal</v-icon></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import request from 'request'
    export default {
        name: "userGamification",
        props: [
          'apiToken'
        ],
        data(){
          return{
              teamLeaders: [],
              weekLeaders: [],
              allTimeLeaders: []
          }
        },
        async mounted(){
            this.setTeamLeaderBoard();
            this.setWeekLeaderBoard();
            this.setTotalLeaderBoard();
        },
        methods: {
            getTeamLeaders: function(){
                var path = 'https://api.docmasweetspot.ca:443/gamification/total/pattison/team/leaderBoard';
                path = path + '?token=' + this.apiToken;
                let vm = this;
                return new Promise((resolve) => {
                    request.get(path,function(error,res,body){
                        if(error) {
                            vm.logout();
                        }
                        resolve(JSON.parse(body));
                    })
                });
            },
            getWeekLeaders: function(){
                var path = 'https://api.docmasweetspot.ca:443/gamification/week/pattison/leaderBoard';
                path = path + '?token=' + this.apiToken;
                let vm = this;
                return new Promise((resolve) => {
                    request.get(path,function(error,res,body){
                        if(error) {
                            vm.logout();
                        }
                        resolve(JSON.parse(body));
                    })
                });
            },
            getAllTimeLeaders: function(){
                var path = 'https://api.docmasweetspot.ca:443/gamification/total/pattison/leaderBoard';
                path = path + '?token=' + this.apiToken;
                let vm = this;
                return new Promise((resolve) => {
                    request.get(path,function(error,res,body){
                        if(error) {
                            vm.logout();
                        }
                        resolve(JSON.parse(body));
                    })
                });
            },
            setTeamLeaderBoard: async function(){
                let leaders = await this.getTeamLeaders();
                for(let idx = 0; idx < leaders.length; idx ++){
                    if(idx > 9){
                        continue;
                    }
                    else{
                        let leader = leaders[idx];
                        leader['pos'] = idx;
                        this.teamLeaders.push(leader);
                    }
                }
            },
            setWeekLeaderBoard: async function(){
                let leaders = await this.getWeekLeaders();
                for(let idx = 0; idx < leaders.length; idx ++){
                    if(idx > 9){
                        continue;
                    }
                    else{
                        let leader = leaders[idx];
                        leader['pos'] = idx;
                        this.weekLeaders.push(leader);
                    }
                }
            },
            setTotalLeaderBoard: async function(){
                let leaders = await this.getAllTimeLeaders();
                for(let idx = 0; idx < leaders.length; idx ++){
                    if(idx > 9){
                        continue;
                    }
                    else{
                        let leader = leaders[idx];
                        leader['pos'] = idx;
                        this.allTimeLeaders.push(leader);
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>