<template>
        <v-row class="fill-height">
            <v-col class="py-0 pr-0" cols="auto">
                <!--<profile-drawer></profile-drawer>-->
                <keep-alive>
                    <component :is="dynamicComponent" v-bind:nameOfUser="nameOfUser" v-bind:color="color" v-bind:icon="icon" v-bind:apiToken="apiToken"></component>
                </keep-alive>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="py-0 px-0" cols="auto" style="width:56px">
                <v-list class="py-0">
                    <v-list-item class="pa-0" :style="[dynamicComponent == PROFILE_INFO ? {background: '#00295e'} : {background: '#FFFFFF'}]">
                        <v-icon large @click="updateDrawerView('PROFILE_INFO')" style="width: 100%;" :style="[dynamicComponent == PROFILE_INFO ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-account-circle-outline</v-icon>
                    </v-list-item>
                    <v-divider class="mx-1"></v-divider>
                    <v-list-item class="pa-0" :style="[dynamicComponent == PROFILE_EDIT ? {background: '#00295e'} : {background: '#FFFFFF'}]">
                        <v-icon large @click="updateDrawerView('PROFILE_EDIT')" style="width: 100%;" :style="[dynamicComponent == PROFILE_EDIT ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-account-edit-outline</v-icon>
                    </v-list-item>
                    <v-divider class="mx-1"></v-divider>
                    <v-list-item class="pa-0" :style="[dynamicComponent == PROFILE_GAMIFICATION ? {background: '#00295e'} : {background: '#FFFFFF'}]">
                        <v-icon large @click="updateDrawerView('PROFILE_GAMIFICATION')" style="width: 100%;" :style="[dynamicComponent == PROFILE_GAMIFICATION ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-trophy-outline</v-icon>
                    </v-list-item>
                    <v-divider class="mx-1"></v-divider>
                    <!--<v-list-item class="pa-0" :style="[dynamicComponent == PROFILE_OLD ? {background: '#606060'} : {background: '#FFFFFF'}]">-->
                        <!--<v-icon large style="width: 100%;">mdi-content-save-outline</v-icon>-->
                    <!--</v-list-item>-->
                    <!--<v-divider class="mx-1"></v-divider>-->
                </v-list>
            </v-col>
        </v-row>
</template>

<script>
    import profileDrawer from './profileDrawer'
    import userProfileInfo from './userProfileInfo'
    import userProfileEdit from './userProfileEdit'
    import userGamification from './userGamification'
    import firebase from 'firebase';
    export default {
        name: "userProfileDrawer",
        components: {
            profileDrawer
        },
        props: [
          'open',
          'apiToken'
        ],
        mounted() {
            this.dynamicComponent = this.PROFILE_INFO;
            this.isUserLoggedIn();
        },
        watch: {
            open: function () {
                this.navDrawer = this.open;
            }
        },
        computed: {

        },
        updated() {
            this.isUserLoggedIn();
        },
        data() {
            return{
                navDrawer: false,
                nav: true,
                items: [
                    { title: 'Home', icon: 'mdi-play' },
                    { title: 'About', icon: 'mdi-play' },
                ],
                PROFILE_INFO: userProfileInfo,
                PROFILE_EDIT: userProfileEdit,
                PROFILE_GAMIFICATION: userGamification,
                PROFILE_OLD: profileDrawer,
                dynamicComponent: null,
                loggedIn: false,
                nameOfUser: '',
                color: '',
                icon: ''
            }
        },
        methods: {
            updateDrawerView: function(drawerView){
                switch(drawerView){
                    case 'PROFILE_INFO':
                        this.dynamicComponent = this.PROFILE_INFO;
                        this.$emit('updateDrawerWidth',312);
                        break;
                    case 'PROFILE_EDIT':
                        this.dynamicComponent = this.PROFILE_EDIT;
                        this.$emit('updateDrawerWidth',312);
                        break;
                    case 'PROFILE_GAMIFICATION':
                        this.dynamicComponent = this.PROFILE_GAMIFICATION;
                        this.$emit('updateDrawerWidth',window.innerWidth * 0.75);
                        break;
                    case 'PROFILE_OLD':
                        this.dynamicComponent = this.PROFILE_OLD;
                        break;
                }
            },
            isUserLoggedIn: function () {
                const currentUser = firebase.auth().currentUser;
                if (currentUser) {
                    this.loggedIn = true;
                    var user = firebase.auth().currentUser.uid;
                    var vm = this;
                    firebase.firestore().collection('users').doc(user).get().then(function (doc) {
                        if (doc.exists) {
                            var data = doc.data();
                            var name = data['name'];
                            var color = data['profileColor'];
                            var icon = data['profileIcon'];
                            vm.nameOfUser = name;
                            vm.color = color;
                            vm.icon = icon;
                        } else {
                            // doc.data() will be undefined in this case
                            this.console.log("No such document!");
                        }
                    }).catch(function (error) {
                        this.console.log("Error getting document:", error);
                    });
                }
                else {
                    this.loggedIn = false
                }
            }
        }
    }
</script>

<style scoped>


</style>